import * as yup from "yup";

const doubleRegex = /^\d+(\.\d+)?$/;
const phoneRegExp = /^[1-9][0-9]*$/;
const numberRegex = /^\d+$/;

export const validateCreatePost = yup.object({
  title: yup.string().required("Vendosni titullin!").max(60, 'Maksimumi i karaktereve eshte 60!'),
  description: yup.string().required("Vendosni pershkrimin!"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Formati i numrit nuk eshte i sakte! Duhet te filloje pa 0 shembull: 69XXX")
    .required("Vendosni numrin e telefonit!"),
  prefix: yup.string().required("Prefiksi eshte i detyrueshem"),
  cover_image: yup.object({
    filename: yup.string().required("Foto Cover eshte e detyrueshme!"),
    content: yup.string().required("Foto Cover e detyrueshme!"),
    fullContent: yup.string().nullable()
  }),
  email: yup
    .string()
    .required("Vendosni emailin")
    .email("Vendosni nje adrese emaili te sakte!"),
  post_category_id: yup
    .string()
    .required("Ju lutem zgjidhni kategorine!")
    .matches(numberRegex, "Ju lutem zgjidhni kategorine!"),
  city_id: yup
    .string()
    .required("Ju lutem zgjidhni qytetin!")
    .matches(numberRegex, "Ju lutem zgjidhni qytetin!"),
  price_currency: yup
    .string()
    .when("price_on_request_title", ([price_on_request_title], schema) =>
      price_on_request_title === ""
        ? schema.required("Ju lutem zgjidhni valuten!")
        : schema
    ),
  price: yup
    .string()
    .when("price_on_request_title", ([price_on_request_title], schema) =>
      price_on_request_title === ""
        ? schema.required("Vendosni Cmimin").matches(/^\d*$/, "Cmimi duhet të përmbajë vetëm numra")
        : schema
    ),
  price_on_request_title: yup.string().nullable(),
  vehicle_post: yup.object().nullable(),
  job_post: yup.object().nullable(),
  real_estate_post: yup.object().nullable(),
  gallery: yup.array().of(
    yup.object({
      filename: yup.string().required("Emri i fotos i detyrueshem"),
      content: yup.string().required("Foto e detyrueshme!"),
      fullContent: yup.string().nullable()
    })
  ),
});


export const vehicleValidation = yup.object({
  vehicle_post_type: yup.string().required('Zgjidhni tipologjine e postimit!'),
  vehicle_type_id: yup.string().required('Zgjidhni tipologjine e mjetit!'),
  auto_brand_id: yup.string().nullable(),
  auto_model_id: yup.string().nullable().test(
    'required-if-auto-brand',
    'Zgjidhni modelin e automjetit!',
    function (value) {
      const { auto_brand_id } = this.parent; // Access other fields within the same object
      return !auto_brand_id || !!value; // If auto_brand_id exists, auto_model_id should be filled
    }
  ),
  moto_brand_id: yup.string().nullable(),
  moto_model_id: yup.string().nullable().test(
    'required-if-moto-brand',
    'Zgjidhni modelin e motorit!',
    function (value) {
      const { moto_brand_id } = this.parent;
      return !moto_brand_id || !!value; // If moto_brand_id exists, moto_model_id should be filled
    }
  ),
  vehicle_status: yup.string().required('Zgjidhni gjendjent e mjetit!'),
  transmission_type: yup.string().required('Zgjidhni llojin e kambios!'),
  engine_id: yup.string().required('Zgjidhni lenden djegese!'),
  vehicle_year: yup.string().required('Zgjidhni vitin e prodhimit!'),
  km_done: yup.string().required('Zgjidhni kilometrat e bera!'),
  color_id: yup.string().required('Zgjidhni ngjyren!'),
  plate_types: yup.string().required('Zgjidhni targat!'),
});


export const jobsValidation = yup.object({
  job_position: yup.string().required("Ju lutem zgjidhni pozicionin e punes!"),
  job_post_type_id: yup.string().required('Ju lutem zgjidhni llojin e postimit!'),
  job_working_hours_id: yup.string().required('Ju lutem zgjidhni oret e punes!'),
})

export const realEstateValidation = yup.object({
  square_meters: yup.string().required("Ju lutem vendosni siperfaqen!"),
  nr_bedrooms: yup.string().required("Ju lutem vendosni numrin e dhomave!"),
  nr_bathrooms: yup.string().required("Ju lutem vendosni numrin e tualeteve!"),
  has_garage: yup.string().required("Ju lutem vendosni a ka parkim!"),
  nr_balconies: yup.string().required("Ju lutem vendosni numrin e ballkoneve!"),
  has_documentation: yup.string().required("Ju lutem vendosni a ka dokumentim!"),
  floor_nr: yup.string().required("Ju lutem vendosni katin!"),
  real_estate_type_id: yup.string().required("Ju lutem vendosni llojin per qira apo shitje!"),
})