import CookieBanner from '../components/common/CookieBanner'

function Layout({ children }) {
  return (
    <>
      <CookieBanner />
      <div className='container-fluid px-0'>{children}</div>
    </>
  )
}

export default Layout
