import styled from "styled-components";

export const UserButtons = styled.div`
  button {
    font-size: 16px;
  }
`;
export const CardsSection = styled.div``;
export const PostCardStyles = styled.div`
  &.premium-post {
    background-color: #AAC480;
  }
  
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 16px;
    }
  }
`;
export const PostImg = styled.div`
  img {
    width: 200px;
    height: 200px;
  }

  @media (max-width: 768px) {
    img {
      width: 160px;
      height: 160px;
    }
  }
`;

export const CardImg = styled.img`
  @media screen and (max-width: 768px) {
    width: 160px;
    height: 160px;
  }
`;
export const PostButtons = styled.div``;
export const BadgeCategory = styled.span`
  border: 1px solid rgba(58, 58, 60, 0.3);
  color: #3a3a3c;
  font-family: "Inter";
  font-size: 11px;
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
export const PostLocation = styled.div`
  p {
    font-family: "Inter";
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    p {
      font-size: 12px;
    }
  }
`;
export const PostPrice = styled.div`
  @media screen and (max-width: 768px) {
    img {
      width: 26px;
    }
  }
`;
export const PostIcons = styled.div`
  @media screen and (max-width: 768px) {
    img {
      width: 16px;
    }
    p {
      font-size: 12px;
    }
  }
`;
export const BalanceCardButton = styled.div``;
export const MessagesContainer = styled.div``;
export const SingleMessage = styled.div`
  border: 1px solid rgba(58, 58, 60, 0.3);
  p {
    width: 75%;
  }
  @media screen and (max-width: 768px) {
    p {
      width: 100%;
    }
  }
`;

// Business Single Page

export const BusinessInfoImg = styled.div`
  img {
    width: 160px;
    height: 160px;
  }
  @media screen and (max-width: 768px) {
    img {
      width: 130px;
      height: 130px;
    }
  }
`;
