import React from 'react'
import { BadgeCategory, CardImg, PostCardStyles, PostIcons, PostImg, PostLocation, PostPrice } from '../../screens/UserProfile/UserProfile.styles'
import money from '../../assets/icons/money.svg'
import area from '../../assets/icons/area.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBath, faBed, faBriefcase, faCalendar, faClock, faGasPump, faLocationDot, faMapPin } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { currencies, jobPositions, jobWorkingHours, noPriceTitle, transmissionTypes, vehicleYears } from '../../Utilities/constants'
import { BACKEND_STORAGE_URL } from '../../config/urls'

function PostCard({ post }) {
  const navigate = useNavigate()

  const showPrice = () => {
    if (post.vehicle_details !== null) {
      if (post.vehicle_details.price_on_request === 1) {
        return noPriceTitle.find(el => el.id == post.vehicle_details.price_on_request_title).name
      } else {
        return post.vehicle_details.price.toLocaleString() + ' ' + currencies.find(cur => cur.id == post.vehicle_details.price_currency).name
      }
    }

    if (post.real_estate !== null) {
      if (post.real_estate.price_on_request === 1) {
        return noPriceTitle.find(el => el.id == post.real_estate.price_on_request_title).name
      } else {
        return post.real_estate.price.toLocaleString() + ' ' + currencies.find(cur => cur.id == post.real_estate.price_currency).name
      }
    }
    if (post.work_details !== null) {
      if (post.work_details.price_on_request === 1) {
        return noPriceTitle.find(el => el.id == post.work_details.price_on_request_title).name
      } else {
        return post.work_details.salary.toLocaleString() + ' ' + currencies.find(cur => cur.id == post.work_details.salary_currency).name

      }
    }

    if (post.general_details !== null) {
      if (post.general_details.price_on_request === 1) {
        return noPriceTitle.find(el => el.id == post.general_details.price_on_request_title).name
      } else {
        return post.general_details.price.toLocaleString() + ' ' + currencies.find(cur => cur.id == post.general_details.price_currency).name
      }
    }
  }

  return (
    <PostCardStyles className={`card mt-3 ${post.is_premium === 1 ? 'premium-post' : ''}`}>
      <div className="card-body d-flex justify-content-start align-items-start p-0 gap-0 gap-2">
        <PostImg className='p-2'>
          <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/njoftime/${post.slug}`)}>
            <CardImg src={BACKEND_STORAGE_URL + post.cover_image} alt={post.title} className='rounded-3' />
            {/* {post.is_premium === 1 && <span className='d-flex align-items-center justify-content-center bg-primary position-absolute start-0 top-0 mt-3 ms-3 rounded-2 text-white px-2 py-1 gap-1'>
              <FontAwesomeIcon icon={faStar} />
              <p className='mb-0'>Premium</p>
            </span>} */}
          </div>
        </PostImg>
        <div className='mt-2 mt-xxl-3'>
          <BadgeCategory className='bg-white rounded-2 py-1 px-2 me-1 fw-semibold text-center'>
            {post.category.parent.name}
          </BadgeCategory>
          <BadgeCategory className='bg-white rounded-2 py-1 px-2 ms-1 fw-semibold text-center'>
            {post.category.name}
          </BadgeCategory>
          <PostLocation className='mt-1 mt-md-2 mt-xl-3 d-flex justify-content-start align-items-center'>
            <FontAwesomeIcon icon={faLocationDot} className='text-primary' />
            <p className='fw-semibold m-0 ms-1 text-dark'>
              {post.city.name}, {post.country.name}
            </p>
          </PostLocation>
          <h4 className='mt-2 fs-5 me-2'>{post.title}</h4>
          <PostPrice className='d-flex justify-content-start align-items-center'>
            <img src={money} alt="" width={40} />
            <h4 className='m-0 mt-1 ms-2'>
              {showPrice()}
            </h4>
          </PostPrice>
          {post.vehicle_details !== null &&
            <div className='mt-1 mt-md-2 d-flex align-items-center gap-2 gap-md-4 pb-2'>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faMapPin} className='text-primary' />
                <p className='ms-1 m-0'>
                  <small>
                    {transmissionTypes.find(el => el.id == post.vehicle_details.transmission_type).name}
                  </small>
                </p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faCalendar} className='text-primary' />
                <p className='ms-2 m-0'>
                  <small>
                    {vehicleYears.find(el => el.id == post.vehicle_details.vehicle_year).name}
                  </small>
                </p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faGasPump} className='text-primary' />
                <p className='ms-1 m-0'>
                  <small>
                    {post.vehicle_details.engine.name}
                  </small>
                </p>
              </PostIcons>
            </div>
          }
          {post.real_estate !== null &&
            <div className='mt-1 mt-md-2 d-flex align-items-center gap-2 gap-md-4 pb-2'>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <img src={area} alt="" width={25} />
                <p className='ms-1 m-0'>
                  <small>
                    {parseFloat(post.real_estate.square_meters)} m<sup>2</sup>
                  </small>
                </p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faBed} className='text-primary' />
                <p className='ms-2 m-0'>
                  <small>
                    {post.real_estate.nr_bedrooms}
                  </small>
                </p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faBath} className='text-primary' />
                <p className='ms-1 m-0'>
                  <small>
                    {post.real_estate.nr_bathroom}
                  </small>
                </p>
              </PostIcons>
            </div>
          }
          {post.work_details !== null &&
            <div className='mt-1 mt-md-2 d-flex align-items-center gap-2 gap-md-4 pb-2'>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faBriefcase} className='text-primary' />
                <p className='ms-2 m-0'>
                  <small>{jobPositions.find(el => el.id === post.work_details.job_position).name}</small>
                </p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faClock} className='text-primary' />
                <p className='ms-1 m-0'>
                  <small>{jobWorkingHours.find(el => el.id === post.work_details.job_working_hours_type_id).name}</small>
                </p>
              </PostIcons>
            </div>
          }
        </div>
      </div>
    </PostCardStyles>
  )
}

export default PostCard