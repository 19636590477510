import React, { useEffect } from 'react'
import phone from '../assets/icons/telephone.svg'
import email from '../assets/icons/email.svg'
import phoneSmall from '../assets/icons/telephone-small.svg'
import emailSmall from '../assets/icons/email-small.svg'
import InputTextGroup from '../components/forms/InputTextGroup'
import InputTextArea from '../components/forms/InputTextArea'
import Button from '../components/common/Button'
import { ContactRow } from './ContactUs.styles'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { storeNewContact } from '../feature/contact/contactActions'
import { useSelector } from 'react-redux'
import Spinner from '../components/common/Spinner'
import { useLocation } from 'react-router'
import SeoContact from './Seo/SeoContact'

const phoneRegExp = /^\+?\d+$/

const schema = yup.object({
  full_name: yup.string().required('Ju lutem vendosni emrin dhe mbiemrin!'),
  subject: yup.string().required('Ju lutem vendosni subjektin!'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Formati i numrit nuk eshte i sakte!')
    .required('Vendosni numrin e telefonit!'),
  email: yup
    .string()
    .required('Vendosni emailin')
    .email('Vendosni nje adrese emaili te sakte!'),
  message: yup.string().required('Shkruani mesazhin tuaj!')
})

function ContactUs() {
  const { loading } = useSelector((store) => store.contacts.contacts)
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      full_name: '',
      subject: '',
      phone: '',
      email: '',
      message: ''
    }
  })
  const location = useLocation()

  const formData = watch()

  const updateFormData = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
  }

  const submitContactForm = () => {
    storeNewContact(formData, reset)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <SeoContact />
      <div className=' container my-5'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='fw-medium text-center text-dark'>Na Kontaktoni</h3>
          </div>
          <div className='col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
            <p className='text-center text-dark px-4 '>
              Jemi në dispozicion t'ju përgjigjemi pyetjeve përmes e-mailit ose
              telefonit çdo ditë nga ora 07:00 deri në orën 21:00
            </p>
          </div>
        </div>

        <div className='px-0 px-xl-5'>
          <ContactRow className='row my-5 mx-lg-5'>
            <div className='col-12 col-lg-6 px-5 px-xl-0 d-flex flex-column justify-content-center align-items-start'>
              <div className='d-flex justify-content-start align-items-start gap-3 gap-lg-5'>
                <div>
                  <img src={phone} alt='' className='d-none d-lg-block' />
                  <img src={phoneSmall} alt='' className='d-block d-lg-none' />
                </div>
                <div>
                  <h3>Suport: +355 69 785 0900</h3>
                  <h3>Marketing: +355 69 785 0900</h3>
                </div>
              </div>
              <div className='d-flex justify-content-start align-items-start gap-3 gap-lg-5 mt-5'>
                <div>
                  <img src={email} alt='' className='d-none d-lg-block' />
                  <img src={emailSmall} alt='' className='d-block d-lg-none' />
                </div>
                <div>
                  <h3>info@okazion.al</h3>
                  <h3>marketing@okazion.al</h3>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 mt-4 mt-lg-0'>
              <form
                onSubmit={handleSubmit(submitContactForm)}
                className='px-3 px-xl-0'
              >
                <InputTextGroup
                  placeholder={'Emri i Plote'}
                  name={'full_name'}
                  type={'text'}
                  inputClass={'py-2'}
                  value={formData.full_name}
                  onChange={(e) =>
                    updateFormData({
                      full_name: e.target.value
                    })
                  }
                  error={errors.full_name?.message}
                />
                <InputTextGroup
                  placeholder={'Subjekti'}
                  name={'subject'}
                  type={'text'}
                  inputGroupClass={'mt-2'}
                  inputClass={'py-2'}
                  value={formData.subject}
                  onChange={(e) =>
                    updateFormData({
                      subject: e.target.value
                    })
                  }
                  error={errors.subject?.message}
                />
                <InputTextGroup
                  placeholder={'Numer Kontakti'}
                  name={'phone'}
                  type={'text'}
                  inputGroupClass={'mt-2'}
                  value={formData.phone}
                  onChange={(e) =>
                    updateFormData({
                      phone: e.target.value
                    })
                  }
                  error={errors.phone?.message}
                />
                <InputTextGroup
                  placeholder={'Email'}
                  name={'email'}
                  type={'email'}
                  inputGroupClass={'mt-2'}
                  inputClass={'py-2'}
                  value={formData.email}
                  onChange={(e) =>
                    updateFormData({
                      email: e.target.value
                    })
                  }
                  error={errors.email?.message}
                />
                <InputTextArea
                  placeholder={'Mesazhi Juaj'}
                  name={'message'}
                  type={'text'}
                  textAreaDivClass={'mt-2'}
                  value={formData.message}
                  onChange={(e) =>
                    updateFormData({
                      message: e.target.value
                    })
                  }
                  error={errors.message?.message}
                />
                <Button
                  buttonText={'Dergo Mesazh'}
                  type={'submit'}
                  buttonClass={'w-100 mt-2 btn-primary'}
                />
              </form>
            </div>
          </ContactRow>
        </div>
      </div>
    </>
  )
}

export default ContactUs
