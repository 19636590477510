import React from 'react'
import ReactSelectCustom from '../../../components/forms/ReactSelectCustom'
import {
  kilometersDone,
  plateTypes,
  transmissionTypes,
  vehiclePostTypes,
  vehicleStatuses,
  vehicleTypes,
  vehicleYears
} from '../../../Utilities/constants'

function VehiclePostEditComponent({
  updateFormData,
  formData,
  errors,
  brandsLoading,
  brands,
  modelsAutoLoading,
  modelsMotoLoading,
  modelsAuto,
  modelsMoto,
  engines,
  colors,
  category_id
}) {
  return (
    <>
      <ReactSelectCustom
        placeholder={'Automjeti eshte per'}
        name='vehicle_post.vehicle_post_type'
        options={vehiclePostTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={vehiclePostTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post.vehicle_post_type)}
        onChange={(val) =>
          updateFormData({
            'vehicle_post.vehicle_post_type': val ? val.value : ''
          })
        }
      />
      <div className='invalid-feedback d-block'>
        {errors.vehicle_post && errors.vehicle_post.vehicle_post_type?.message}
      </div>
      {category_id == 9 && (
        <>
          {brandsLoading ? (
            <div className='d-flex justify-content-center my-2'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <ReactSelectCustom
                placeholder={'Prodhuesi'}
                name='vehicle_post.auto_brand_id'
                options={brands.map((c) => ({ value: c.id, label: c.name }))}
                brCustom={'0.375rem'}
                className={'mb-2'}
                values={brands
                  .map((c) => ({ value: c.id, label: c.name }))
                  .find(
                    (el) => el.value == formData.vehicle_post.auto_brand_id
                  )}
                onChange={(val) =>
                  updateFormData({
                    'vehicle_post.auto_brand_id': val ? val.value : ''
                  })
                }
              />
              <div className='invalid-feedback d-block'>
                {errors.vehicle_post &&
                  errors.vehicle_post.auto_brand_id?.message}
              </div>
            </>
          )}
        </>
      )}

      {category_id == 9 && (
        <>
          {modelsAutoLoading ? (
            <div className='d-flex justify-content-center my-2'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <ReactSelectCustom
                placeholder={'Modeli'}
                name='vehicle_post.auto_model_id'
                options={modelsAuto.map((c) => ({
                  value: c.id,
                  label: c.name
                }))}
                brCustom={'0.375rem'}
                className={'mb-2'}
                values={modelsAuto
                  .map((c) => ({ value: c.id, label: c.name }))
                  .find(
                    (el) => el.value == formData.vehicle_post.auto_model_id
                  )}
                onChange={(val) =>
                  updateFormData({
                    'vehicle_post.auto_model_id': val ? val.value : ''
                  })
                }
              />
              <div className='invalid-feedback d-block'>
                {errors.vehicle_post &&
                  errors.vehicle_post.auto_model_id?.message}
              </div>
            </>
          )}
        </>
      )}

      {category_id == 10 && (
        <>
          {brandsLoading ? (
            <div className='d-flex justify-content-center my-2'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <ReactSelectCustom
                placeholder={'Prodhuesi'}
                name='vehicle_post.moto_brand_id'
                options={brands.map((c) => ({ value: c.id, label: c.name }))}
                brCustom={'0.375rem'}
                className={'mb-2'}
                values={brands
                  .map((c) => ({ value: c.id, label: c.name }))
                  .find(
                    (el) => el.value == formData.vehicle_post.moto_brand_id
                  )}
                onChange={(val) =>
                  updateFormData({
                    'vehicle_post.moto_brand_id': val ? val.value : ''
                  })
                }
              />
              <div className='invalid-feedback d-block'>
                {errors.vehicle_post &&
                  errors.vehicle_post.moto_brand_id?.message}
              </div>
            </>
          )}
        </>
      )}

      {category_id == 10 && (
        <>
          {modelsMotoLoading ? (
            <div className='d-flex justify-content-center my-2'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <ReactSelectCustom
                placeholder={'Modeli'}
                name='vehicle_post.moto_model_id'
                options={modelsMoto.map((c) => ({
                  value: c.id,
                  label: c.name
                }))}
                brCustom={'0.375rem'}
                className={'mb-2'}
                values={modelsMoto
                  .map((c) => ({ value: c.id, label: c.name }))
                  .find(
                    (el) => el.value == formData.vehicle_post.moto_model_id
                  )}
                onChange={(val) =>
                  updateFormData({
                    'vehicle_post.moto_model_id': val ? val.value : ''
                  })
                }
              />
              <div className='invalid-feedback d-block'>
                {errors.vehicle_post &&
                  errors.vehicle_post.moto_model_id?.message}
              </div>
            </>
          )}
        </>
      )}

      <ReactSelectCustom
        placeholder={'Transmetuesi'}
        name='vehicle_post.transmission_type'
        options={transmissionTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={transmissionTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post.transmission_type)}
        onChange={(val) =>
          updateFormData({
            'vehicle_post.transmission_type': val ? val.value : ''
          })
        }
      />
      <div className='invalid-feedback d-block'>
        {errors.vehicle_post && errors.vehicle_post.transmission_type?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Targat'}
        name='vehicle_post.plate_types'
        options={plateTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={plateTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post.plate_types)}
        onChange={(val) =>
          updateFormData({
            'vehicle_post.plate_types': val ? val.value : ''
          })
        }
      />
      <div className='invalid-feedback d-block'>
        {errors.vehicle_post && errors.vehicle_post.plate_types?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Gjendja e mjetit'}
        name='vehicle_post.vehicle_status'
        options={vehicleStatuses.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={vehicleStatuses
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post.vehicle_status)}
        onChange={(val) =>
          updateFormData({
            'vehicle_post.vehicle_status': val ? val.value : ''
          })
        }
      />
      <div className='invalid-feedback d-block'>
        {errors.vehicle_post && errors.vehicle_post.vehicle_status?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Tipologjia e Mjetit'}
        name='vehicle_post.vehicle_type_id'
        options={vehicleTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={vehicleTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post.vehicle_type_id)}
        onChange={(val) =>
          updateFormData({
            'vehicle_post.vehicle_type_id': val ? val.value : ''
          })
        }
      />
      <div className='invalid-feedback d-block'>
        {errors.vehicle_post && errors.vehicle_post.vehicle_type_id?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Karburanti'}
        name='vehicle_post.engine_id'
        options={engines.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={engines
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post.engine_id)}
        onChange={(val) =>
          updateFormData({
            'vehicle_post.engine_id': val ? val.value : ''
          })
        }
      />
      <div className='invalid-feedback d-block'>
        {errors.vehicle_post && errors.vehicle_post.engine_id?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Viti i Prodhimit'}
        name='vehicle_post.vehicle_year'
        options={vehicleYears.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={vehicleYears
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post.vehicle_year)}
        onChange={(val) =>
          updateFormData({
            'vehicle_post.vehicle_year': val ? val.value : ''
          })
        }
      />
      <div className='invalid-feedback d-block'>
        {errors.vehicle_post && errors.vehicle_post.vehicle_year?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Kilometra'}
        name='vehicle_post.km_done'
        options={kilometersDone.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={kilometersDone
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post.km_done)}
        onChange={(val) =>
          updateFormData({
            'vehicle_post.km_done': val ? val.value : ''
          })
        }
      />
      <div className='invalid-feedback d-block'>
        {errors.vehicle_post && errors.vehicle_post.km_done?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Ngjyra'}
        name='vehicle_post.color_id'
        options={colors.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={colors
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post.color_id)}
        onChange={(val) =>
          updateFormData({
            'vehicle_post.color_id': val ? val.value : ''
          })
        }
      />
      <div className='invalid-feedback d-block'>
        {errors.vehicle_post && errors.vehicle_post.color_id?.message}
      </div>
    </>
  )
}

export default VehiclePostEditComponent
