import React from 'react'
import {
  AuthLogo,
  ForgotParagraph,
  RegisterBody,
  RegisterCard
} from './Register.styles'
import InputTextGroup from '../../components/forms/InputTextGroup'
import reset from '../../assets/icons/reset-password.svg'
import Button from '../../components/common/Button'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useResetPasswordMutation } from '../../feature/auth/authSliceApi'
import {
  generalAlertError,
  generalAlertSuccessToast
} from '../../helpers/alert'
import StorageService from '../../services/StorageService'

const schema = yup.object({
  token: yup
    .string()
    .matches(/^[0-9]{6}$/, 'Kodi nuk eshte i sakte duhen 6 shifra!')
    .required('Ju lutem vendosni kodin 6 shifror nga emaili!'),
  password: yup
    .string()
    .required('Vendosni fjalekalimin!')
    .min(8, 'Minimumi i karaktereve duhet te jete 8!'),
  password_confirmation: yup
    .string()
    .required('Vendosni fjalekalimin')
    .oneOf([yup.ref('password')], 'Fjalekalimet nuk jane njelloj!'),
  email: yup.string().required('Ju lutem vendosni emailin tuaj!')
})

const Storage = new StorageService()

function ResetPassword() {
  const navigate = useNavigate()
  const [resetPassword, { isLoading }] = useResetPasswordMutation()

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      token: '',
      password: '',
      password_confirmation: '',
      email: ''
    }
  })

  const formData = watch()

  const updateFormData = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
  }

  const handlePasswordReset = async () => {
    const res = await resetPassword({ data: formData })

    if (res.error) {
      generalAlertError('Dicka shkoi gabim. Provoje Perseri!')
    } else {
      Storage.setToken(res.data.data.access_token)
      Storage.setRefreshToken(res.data.data.refresh_token)
      Storage.setUser(res.data.data.user)
      generalAlertSuccessToast('Fjalekalimi u ndryshua me sukses!')
      navigate('/')
    }
  }

  return (
    <div className='px-md-5'>
      <div className='container row mx-auto'>
        <div className='col-12 col-lg-8 offset-lg-2 my-5'>
          <RegisterCard className='card py-md-3 px-0 px-xl-5'>
            <RegisterBody className='card-body d-flex justify-content-center align-items-center px-0 px-md-4 flex-column'>
              <AuthLogo>
                <img src={reset} alt='' />
              </AuthLogo>
              <h3 className='mt-2 w-75 text-center'>Rivendos Fjalekalimin</h3>
              <ForgotParagraph className='w-75'>
                <p className='text-center'>
                  Fusni kodin 6 shifror qe moret ne emailin tuaj me instruksione
                  dhe me pas vendosni fjalkalimin e ri.
                </p>
              </ForgotParagraph>
              <form
                className='w-100 mx-auto'
                onSubmit={handleSubmit(handlePasswordReset)}
              >
                <InputTextGroup
                  placeholder={'Kodi 6 Shifror'}
                  name='token'
                  type='text'
                  id='token'
                  inputGroupClass={'w-75 mx-auto mb-3'}
                  inputClass={'py-2'}
                  error={errors.token?.message}
                  onChange={(e) =>
                    updateFormData({
                      token: e.target.value
                    })
                  }
                  value={formData.token}
                />
                <InputTextGroup
                  placeholder={'Emaili juaj'}
                  name='email'
                  type='email'
                  id='email'
                  inputGroupClass={'w-75 mx-auto mb-3'}
                  inputClass={'py-2'}
                  error={errors.email?.message}
                  onChange={(e) =>
                    updateFormData({
                      email: e.target.value
                    })
                  }
                  value={formData.email}
                />
                <InputTextGroup
                  placeholder={'Fjalekalimi'}
                  name='password'
                  type='password'
                  id='password'
                  inputGroupClass={'w-75 mx-auto mb-3'}
                  inputClass={'py-2'}
                  error={errors.password?.message}
                  onChange={(e) =>
                    updateFormData({
                      password: e.target.value
                    })
                  }
                  value={formData.password}
                />
                <InputTextGroup
                  placeholder={'Riperserit Fjalekalimin'}
                  name='password_confirmation'
                  type='password'
                  id='password_confirmation'
                  inputGroupClass={'w-75 mx-auto mb-3'}
                  inputClass={'py-2'}
                  error={errors.password_confirmation?.message}
                  onChange={(e) =>
                    updateFormData({
                      password_confirmation: e.target.value
                    })
                  }
                  value={formData.password_confirmation}
                />
                <Button
                  type={'submit'}
                  buttonClass={'w-75 mx-auto d-table btn-primary'}
                  buttonText='Ruaj Fjalekalimin'
                />
              </form>
            </RegisterBody>
          </RegisterCard>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
