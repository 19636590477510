import React, { useEffect } from 'react'
import { NotFoundStyles } from './ContactUs.styles'
import notFound from '../assets/images/not-found.svg'
import { Link, useLocation } from 'react-router-dom'

function NotFound() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  return (
    <NotFoundStyles className='container my-5 py-5 d-flex justify-content-center align-items-center'>
      <div>
        <h1 className='fw-medium text-center text-dark text-uppercase'>
          OOPS!
        </h1>
        <h5 className='mt-3 mb-5 text-center'>Faqja Nuk u Gjet</h5>

        <div className='mb-5 d-flex align-items-center justify-content-center'>
          <img src={notFound} alt='Nuk u gjet' />
        </div>

        <div className='d-flex align-items-center justify-content-center pt-3'>
          <Link
            className='text-decoration-none text-white px-3 py-3 px-xl-4 rounded-3 btn btn-primary fw-medium me-2'
            to='/'
          >
            <h5 className='text-white m-0'>Kthehu ne faqen kryesore</h5>
          </Link>
        </div>
      </div>
    </NotFoundStyles>
  )
}

export default NotFound
