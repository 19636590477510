import styled from "styled-components";

export const Navigation = styled.nav`
  form {
    width: 60%;
  }

  form input {
    border-radius: 0;
  }

  form button {
    border-radius: 0;
  }
`;
export const LogoImg = styled.div``;

export const NavbarIcon = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  padding: 3px 5px;
  display: flex;
  align-items: center;
`;
