import React from 'react'
import {
  BadgeCategory,
  CardImg,
  PostCardStyles,
  PostImg,
  PostPrice
} from '../../screens/UserProfile/UserProfile.styles'
import money from '../../assets/icons/money.svg'
import { BACKEND_STORAGE_URL } from '../../config/urls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMobile,
  faMobileScreen,
  faPhone,
  faPhoneSquare,
  faThumbTack
} from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

function PinPostCard({ post }) {
  return (
    <PostCardStyles className={`card mt-3 premium-post`}>
      <div className='thumb-position'>
        <FontAwesomeIcon icon={faThumbTack} className='bg-okazion' size='1x' />
      </div>
      <div className='card-body d-flex justify-content-start p-0 gap-0 gap-2'>
        <PostImg className='p-2'>
          <CardImg
            src={BACKEND_STORAGE_URL + post.cover_image}
            alt={post.title}
            className='rounded-3'
          />
        </PostImg>
        <div className='mt-2 mt-xxl-3 d-flex align-items-start justify-content-between flex-column'>
          <div>
            <h4 className='mt-2 fs-5 me-2'>{post.title}</h4>
            <p className='m-0'>{post.description}</p>
            <PostPrice className='d-flex justify-content-start align-items-center'>
              <img src={money} alt='' width={40} />
              <h4 className='m-0 mt-1 ms-2'>{post.price} Euro</h4>
            </PostPrice>
          </div>
          <div className='d-flex align-items-center justify-content-start gap-2 mt-2 mb-2 w-100'>
            {post.whatsapp && (
              <a
                href={post.whatsapp}
                className='btn btn-light py-1 px-2 d-flex justify-content-center btn-sm'
              >
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className='icon-contact'
                  size='xl'
                />
              </a>
            )}
            <a
              href={`tel:${post.phone}`}
              className='btn btn-light py-1 px-2 d-flex justify-content-center btn-sm'
            >
              <FontAwesomeIcon
                icon={faPhoneSquare}
                className='icon-contact'
                size='xl'
              />
            </a>
          </div>
        </div>
      </div>
    </PostCardStyles>
  )
}

export default PinPostCard
