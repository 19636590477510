import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { BACKEND_STORAGE_URL } from '../../../config/urls'
import { Autoplay, Pagination } from 'swiper/modules'

function HomeSlider({ banners }) {
  return (
    <Swiper
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          // Custom pagination dot markup
          return `<div class="${className} custom-dot"></div>`
        }
      }}
      spaceBetween={10}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
      slidesPerView={1}
      modules={[Autoplay, Pagination]}
    >
      {banners
        .filter((banner) => banner.position === 1)
        .map((ban, index) => (
          <SwiperSlide key={index}>
            <a href={ban.url} target='_blank' rel='noreferrer'>
              <img
                src={BACKEND_STORAGE_URL + ban.image_path}
                alt='Banner'
                className='img-fluid rounded-2'
              />
            </a>
          </SwiperSlide>
        ))}
    </Swiper>
  )
}

export default HomeSlider
