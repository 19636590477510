import React from 'react'
import {
  AuthLogo,
  LineText,
  NotRegistered,
  RegisterBody,
  RegisterCard
} from './Register.styles'
import InputTextGroup from '../../components/forms/InputTextGroup'
import Button from '../../components/common/Button'
import logo from '../../assets/icons/icon-logo.svg'
import googleIcon from '../../assets/icons/google-icon.svg'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Spinner from '../../components/common/Spinner'
import { getGoogleUrl } from '../../helpers/getGoogleUrl'
import { useAttemptLoginMutation } from '../../feature/auth/authSliceApi'
import {
  generalAlertError,
  generalAlertSuccessToast
} from '../../helpers/alert'
import StorageService from '../../services/StorageService'
import { jwtDecode } from 'jwt-decode'

const authSchema = yup.object({
  email: yup.string().required('Ju lutem vendosni emailin ose username tuaj!'),
  password: yup.string().required('Ju lutem vendosni fjalekalimin tuaj!')
})

const Storage = new StorageService()

function Login() {
  const navigate = useNavigate()
  const [attemtLogin, { isLoading }] = useAttemptLoginMutation()
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm({
    mode: 'all',
    resolver: yupResolver(authSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const formData = watch()

  const updateFormData = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
    // trigger(keys);
  }

  const loginUser = async () => {
    const res = await attemtLogin({ data: formData })

    if (res.error && res.error.status === 401) {
      generalAlertError('Email ose Fjalekalimi eshte gabim!')
      setValue('password', '')
    } else if (res.error) {
      generalAlertError('Dicka shkoi gabim! Ju lutem provojeni perseri!')
    } else {
      let user = jwtDecode(res.data.data.access_token)

      if (user.email_verified === 0) {
        generalAlertSuccessToast(
          'Kontrolloni emailin tuaj dhe verifikoni llogarine!'
        )
        navigate('/')
      } else {
        Storage.setToken(res.data.data.access_token)
        Storage.setUser(res.data.data.user)
        Storage.setRefreshToken(res.data.data.refresh_token)
        navigate('/')
        generalAlertSuccessToast('Miresevini ne Okazion.al!')
      }
    }
  }

  if (localStorage.getItem('user')) {
    return (
      <Navigate
        to={`/profili/${JSON.parse(localStorage.getItem('user')).username}`}
      />
    )
  }

  return (
    <div className='px-md-5'>
      <div className='container row mx-auto'>
        <div className='col-12 col-lg-8 offset-lg-2 my-5'>
          <RegisterCard className='card py-md-3 px-0 px-xl-5'>
            <RegisterBody className='card-body d-flex justify-content-center align-items-center px-0 px-md-4 flex-column'>
              <AuthLogo>
                <img src={logo} alt='' />
              </AuthLogo>
              <h3 className='mt-2 w-75 text-center'>
                Kyçuni ne Llogarine Tuaj
              </h3>
              {/* <a href={getGoogleUrl()} className='text-decoration-none border d-flex justify-content-center align-items-center mt-5 py-2 gap-2 w-75 mx-auto bg-transparent rounded-2'>
                                <img src={googleIcon} alt="" />
                                <p className="m-0 text-dark fw-semibold">Identifikohuni Me Google</p>
                            </a>
                            <LineText className='d-flex justify-content-center align-items-center my-3 w-75 mx-auto position-relative'>
                                <p className='fw-bold fs-6 m-0 me-3 ms-3'>ose</p>
                            </LineText> */}
              <form
                className='w-100 mx-auto mt-4'
                onSubmit={handleSubmit(loginUser)}
              >
                <InputTextGroup
                  placeholder={'Email'}
                  name='email'
                  error={errors.email?.message}
                  type='email'
                  value={formData.email}
                  id='email'
                  inputGroupClass={'w-75 mx-auto mb-3 '}
                  inputClass={'py-2'}
                  onChange={(e) =>
                    updateFormData({
                      email: e.target.value
                    })
                  }
                />
                <InputTextGroup
                  placeholder={'Fjalekalimi'}
                  name='password'
                  error={errors.password?.message}
                  type='password'
                  value={formData.password}
                  id='password'
                  inputGroupClass={'w-75 mx-auto mb-3 '}
                  inputClass={'py-2'}
                  onChange={(e) =>
                    updateFormData({
                      password: e.target.value
                    })
                  }
                />
                <NotRegistered className='w-75 mx-auto mb-2'>
                  <Link
                    to='/forgot-password'
                    className='w-75 ms-auto fw-light d-table text-end text-primary'
                  >
                    Harruat Fjalekalimin?
                  </Link>
                </NotRegistered>
                {isLoading ? (
                  <button
                    type={'button'}
                    className={`mx-auto py-2 btn rounded-2 w-75 mx-auto d-table btn-primary d-flex justify-content-center align-items-center`}
                    disabled
                  >
                    <div
                      className='spinner-border text-light spinner-border-sm'
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                    <div className='ms-2'>Duke u loguar...</div>
                  </button>
                ) : (
                  <Button
                    type={'submit'}
                    buttonClass={'w-75 mx-auto d-table btn-primary'}
                    buttonText='Identifikohu'
                  />
                )}
              </form>
              <NotRegistered className='w-75 mx-auto'>
                <p className='mt-3 mb-0'>
                  Nuk Keni Nje llogari ne Okazion.al?{' '}
                  <Link to='/regjistrohu' className='fw-light text-primary'>
                    Regjistrohuni Ketu
                  </Link>
                </p>
              </NotRegistered>
            </RegisterBody>
          </RegisterCard>
        </div>
      </div>
    </div>
  )
}

export default Login
