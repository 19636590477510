import styled from "styled-components";

export const RegisterContainer = styled.div``;

export const RegisterCard = styled.div``;

export const RegisterBody = styled.div`
  button {
    border: 1px solid rgba(58, 58, 60, 0.3);
  }
`;

export const AuthLogo = styled.div`
  width: 65px;

  img {
    width: 65px;
  }
`;

export const LineText = styled.div`
  p {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: calc(50% - 0.5px);
      height: 1px;
      width: 45%;
      background-color: #333;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: calc(50% - 0.5px);
      height: 1px;
      width: 45%;
      background-color: #333;
    }
  }

  @media screen and (max-width: 576px) {
    p {
      &::before {
        width: 40%;
      }

      &::after {
        width: 40%;
      }
    }
  }
`;
export const SelectGender = styled.div``;

export const AcceptTerms = styled.div`
  p {
    font-size: 12px;
  }
`;

export const NotRegistered = styled.div`
  p {
    font-size: 16px;
  }

  @media screen and (max-width: 576px) {
    p {
      font-size: 12px;
    }
  }
`;

export const ForgotParagraph = styled.div`
  @media screen and (max-width: 576px) {
    p {
      font-size: 15px;
    }
  }
`;
