import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

function PrivacyPolicy() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  return (
    <div className='container my-5'>
      <h2 className='text-center mt-5'>Politikat e Privatësisë</h2>
      <div className='container'>
        <p>
          Ne në Okazion.al jemi të angazhuar për të mbrojtur privatësinë tuaj.
          Kjo Politikë e Privatësisë përshkruan se si mbledhim, përdorim, ruajmë
          dhe ndajmë informacionin tuaj kur përdorni platformën tonë.
        </p>
        <ol>
          <li>
            <div>Informacioni që mbledhim</div>
            <p>
              Ne mund të mbledhim informacionin tuaj personal në disa mënyra,
              përfshirë:
              <br />
              - Informacionin e regjistrimit: Kur krijoni një llogari, ne
              mbledhim emrin tuaj, adresën e emailit, numrin e telefonit dhe
              informacionin e kontaktit.
              <br />
              - Informacioni i transaksioneve: Kur bëni blerje, mund të mbledhim
              informacionin e pagesës (p.sh., numrat e kartave të kreditit) dhe
              adresën tuaj të dërgimit.
              <br />- Informacioni i përdorimit: Ne mbledhim informacion rreth
              aktivitetit tuaj në platformë, përfshirë të dhënat mbi produktet
              që shihni, blerjet që bëni dhe preferencat tuaja.
            </p>
          </li>
          <li>
            <div>Si e përdorim informacionin tuaj</div>
            <p>
              Ne përdorim informacionin tuaj për qëllime të ndryshme, përfshirë:
              <br />
              - Për të ofruar dhe menaxhuar shërbimet tona.
              <br />
              - Për të përmirësuar platformën tonë dhe për të ofruar një përvojë
              më të mirë përdoruesi.
              <br />
              - Për të komunikuar me ju, përfshirë për të dërguar konfirmime,
              faturat, dhe informacione të tjera në lidhje me llogarinë tuaj.
              <br />- Për të analizuar përdorimin e shërbimeve dhe për të
              zhvilluar produkte dhe shërbime të reja.
            </p>
          </li>
          <li>
            <div>Ndaje informacionin tuaj</div>
            <p>
              Ne nuk do të ndajmë informacionin tuaj personal me palë të treta
              pa pëlqimin tuaj, përveç në rastet kur:
              <br />
              - Kemi obligim ligjor për ta bërë këtë.
              <br />- Duhet të ndajmë informacionin me ofruesit e shërbimeve që
              ndihmojnë në funksionimin e platformës sonë (p.sh., procesorët e
              pagesave).
            </p>
          </li>
          <li>
            <div>Siguria e informacionit</div>
            <p>
              Ne marrim masa të përshtatshme teknike dhe organizative për të
              mbrojtur informacionin tuaj personal nga humbja, abuzimi dhe
              aksesimi i paautorizuar.
            </p>
          </li>
          <li>
            <div>Të drejtat tuaja</div>
            <p>
              Ju keni të drejtat për të aksesuar, korrigjuar ose fshirë
              informacionin tuaj personal që ne mbajmë. Ju gjithashtu keni të
              drejtën të kundërshtoni përpunimin e informacionit tuaj nën kushte
              të caktuara.
            </p>
          </li>
          <li>
            <div>Ndryshimet në këtë politikë</div>
            <p>
              Ne mund të përditësojmë këtë Politikë të Privatësisë herë pas
              here. Do t'ju njoftojmë për çdo ndryshim duke postuar politikën e
              re në këtë faqe.
            </p>
          </li>
          <li>
            <div>Kontakti</div>
            <p>
              Nëse keni ndonjë pyetje në lidhje me këtë Politikë të Privatësisë,
              ju lutemi na kontaktoni në:
              <br />
              Email: info@okazion.al
              <br />
              Telefon: +355 69 785 0900
              <br />
              Adresa: Rruga Muhamet Gjollesha, Tirane 1001
              <br />
              NIPT: <strong>M42417031I</strong>
            </p>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default PrivacyPolicy
