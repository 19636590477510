import React from 'react'
import { Helmet } from 'react-helmet'

function SeoSinglePost({ post }) {
  return (
    <Helmet>
      <title data-n-head='true'>
        {`${post.title} | Okazion.al | Platformë për Blerje dhe Shitje Online`}
      </title>
      <meta name='description' content={post.description} />
      <meta
        name='apple-mobile-web-app-title'
        content='Okazion.al | Bli dhe Shit Online | Platformë e Sigurt dhe e Besueshme'
      />
      <meta
        name='keywords'
        content='Okazion, bli, shit, treg online, platformë, produkte, shërbime, Shqipëri, siguri, cilësi, blerje, shitje, ofertë'
      />
      <meta name='robots' content='index, follow' />
      <meta
        name='googlebot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta
        name='bingbot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta name='twitter:card' content='https://okazion.al/images/logo.png' />
      <meta name='twitter:description' content={post.description} />
      <meta name='twitter:title' content={post.description} />
      <meta name='twitter:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:locale' content='sq_AL' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={post.description} />
      <meta
        property='og:description'
        content='Për një eksperiencë të lehtë dhe të sigurt për blerje dhe shitje online, zgjidh Okazion.al.'
      />
      <meta property='og:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:url' content='https://okazion.al/' />
      <meta
        property='og:image:secure_url'
        content='https://okazion.al/images/logo.png'
      />
      <meta property='og:image:type' content='image/png' />
      <meta property='og:image:alt' content='Okazion.al Logo'></meta>
    </Helmet>
  )
}

export default SeoSinglePost
