import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contacts: {
    loading: false,
    error: null,
  },
};

export const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    createContactPending: (state) => {
      state.contacts.loading = true
      state.contacts.error = null
    },
    createContactRejected: (state, { payload }) => {
      state.contacts.loading = false
      state.contacts.error = payload
    },
    createContactSuccess: (state) => {
      state.contacts.loading = false
    }
  },
});
