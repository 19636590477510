import React, { useEffect } from 'react'
import facebook from '../assets/images/facebook-img.svg'
import tikTok from '../assets/images/tik-tok.svg'
import instagram from '../assets/images/instagram-img.svg'
import webisite from '../assets/images/website.svg'
import { BusinessInfoImg } from './UserProfile/UserProfile.styles'
import { useLocation, useParams } from 'react-router'
import Spinner from '../components/common/Spinner'
import PostCard from '../components/common/PostCard'
import moment from 'moment'
import { BACKEND_STORAGE_URL } from '../config/urls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import PaginationBusiness from '../components/common/PaginationBusiness'
import { useLazyGetBusinessInfoQuery } from '../feature/auth/authSliceApi'
import { Helmet } from 'react-helmet'

function BusinessPage() {
  const { username } = useParams()
  const [triggerBusinessProfileInfo, { data, isLoading }] =
    useLazyGetBusinessInfoQuery()
  const location = useLocation()

  const business = data?.data?.customer || null
  const posts = data?.data?.posts || []
  const last_page = data?.data?.posts?.last_page || 0
  const page = data?.data?.posts?.current_page || 0

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [posts])

  useEffect(() => {
    triggerBusinessProfileInfo({ username, page: null })
  }, [username])

  if (isLoading || business === null) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>
        <title>
          {business.business_name} | Okazion.al | Platformë për Blerje dhe
          Shitje Online
        </title>
        <meta
          name='description'
          content=' Okazion.al është një platformë e dedikuar për blerjen dhe shitjen e produkteve të ndryshme, që ofron një hapësirë të sigurt dhe të lehtë për individë dhe biznese që duan të shkëmbejnë artikuj. Ne besojmë se çdo produkt ka vlerë dhe ne jemi këtu për ta ndihmuar ju të gjeni blerjet më të mira ose të shisni artikuj që nuk i nevojiten më. '
        />
        <meta
          name='apple-mobile-web-app-title'
          content='Okazion.al | Bli dhe Shit Online | Platformë e Sigurt dhe e Besueshme'
        />
        <meta
          name='keywords'
          content='Okazion, bli, shit, treg online, platformë, produkte, shërbime, Shqipëri, siguri, cilësi, blerje, shitje, ofertë'
        />
      </Helmet>
      <div className='border-bottom'>
        <div className='py-4 px-5'>
          <p className='fw-medium m-0'>
            Fillimi / Bizneset /{' '}
            <span className='text-primary'>{business.business_name}</span>
          </p>
        </div>
      </div>

      <div className='mt-5 px-4 px-md-5'>
        <div className='row mb-3'>
          <div className='col-12 col-lg-5 mb-3'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-start align-items-start gap-4'>
                  <BusinessInfoImg>
                    {business.profile_image ? (
                      <img
                        src={BACKEND_STORAGE_URL + business.profile_image}
                        alt={business.business_name}
                      />
                    ) : (
                      <div className='d-flex align-items-center justify-content-center p-4'>
                        <FontAwesomeIcon
                          icon={faUser}
                          size='5x'
                          className='text-primary'
                        />
                      </div>
                    )}
                  </BusinessInfoImg>

                  <div className='py-1'>
                    <div>
                      <h3 className='m-0 fw-medium text-dark'>
                        {business.business_name}
                      </h3>
                      <p className='fw-light text-dark mb-1 mb-lg-2 mb-xl-3'>
                        Anetar qe nga {moment(business.created_at).format('LL')}
                      </p>
                    </div>
                    <div>
                      <h4 className='fw-medium text-dark'>Kontakt</h4>
                      <a
                        href={`tel:${business.phone}`}
                        className='px-3 px-lg-2 px-xl-4 btn btn-primary'
                      >
                        Telefono Biznesin
                      </a>
                    </div>
                  </div>
                </div>
                <div className='mt-4'>
                  <h3 className='fw-medium text-dark'>Rrjetet Sociale</h3>
                </div>
                <div className='d-flex gap-3 mt-2'>
                  {business.website_url !== null && (
                    <a
                      href={business.website_url}
                      rel='noreferrer'
                      target='_blank'
                    >
                      <img src={webisite} alt='' />
                    </a>
                  )}
                  {business.facebook_url !== null && (
                    <a
                      href={business.facebook_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src={facebook} alt='' />
                    </a>
                  )}
                  {business.instagram_url !== null && (
                    <a
                      href={business.instagram_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src={instagram} alt='' />
                    </a>
                  )}
                  {business.tiktok_url !== null && (
                    <a
                      href={business.tiktok_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src={tikTok} alt='' />
                    </a>
                  )}

                  {business.website_url === null &&
                    business.facebook_url === null &&
                    business.instagram_url === null &&
                    business.tiktok_url === null &&
                    'Biznesi nuk ka vendosur rrjetet sociale!'}
                </div>
                <div className='mt-5 pe-4'>
                  <h3 className='fw-medium text-dark mb-2'>Rreth Nesh</h3>
                  {business.bio !== null ? (
                    <p dangerouslySetInnerHTML={{ __html: business.bio }} />
                  ) : (
                    'Biznesi nuk ka shtuar bio!'
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-7'>
            <h3 className='fw-medium fst-normal text-dark mt-4 mt-lg-0'>
              Totali Njoftimeve:{' '}
              <span className='fw-light fs-4 text-dark opacity-75'>
                {posts.total}{' '}
                {posts.total !== 1 ? 'Postime Aktive' : 'Postim Aktiv'}
              </span>
            </h3>
            <div>
              {posts.data.length > 0 &&
                posts.data.map((post, index) => (
                  <PostCard post={post} key={index} />
                ))}
            </div>
            {posts.last_page !== 1 && (
              <PaginationBusiness
                collectData={triggerBusinessProfileInfo}
                page={page}
                last_page={last_page}
                links={posts}
                username={username}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BusinessPage
