import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function PaginationBusiness({ page, last_page, collectData, links, username }) {
  const pages = []
  for (let i = 1; i <= last_page; i++) {
    pages.push(i)
  }

  const handlePageChange = (newPage) => {
    collectData({ username, page: newPage })
  }

  return (
    <div className='d-flex justify-content-center justify-content-lg-between align-items-center mt-3'>
      <div>
        <small className='fw-lighter text-primary m-0 d-none d-lg-block'>
          Shfaqur {links.from}-{links.to} nga {links.total} rezultate
        </small>
      </div>
      <nav aria-label='Page navigation example'>
        <ul className='pagination m-0 gap-2'>
          <li className='page-item'>
            <div
              className={`page-link rounded-3 border-primary bg-primary text-white px-3 d-flex align-items-center justify-content-center h-100 rounded-1 ${
                page === 1 ? 'text-info' : ''
              }`}
              style={{ cursor: 'pointer' }}
              onClick={page === 1 ? () => {} : () => handlePageChange(page - 1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          </li>
          {pages.map((pageNumber) => (
            <li key={pageNumber} className={`page-item`}>
              <div
                className={`page-link rounded-3 px-3 border-primary ${
                  pageNumber === page ? 'bg-primary text-white' : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={
                  pageNumber === page
                    ? () => {}
                    : () => handlePageChange(pageNumber)
                }
              >
                {pageNumber}
              </div>
            </li>
          ))}
          <li className='page-item'>
            <div
              className={`page-link rounded-3 bg-primary border-primary text-white px-3 d-flex align-items-center justify-content-center h-100 rounded-1 ${
                page === last_page ? 'text-info' : ''
              }`}
              style={{ cursor: 'pointer' }}
              onClick={
                page === last_page ? () => {} : () => handlePageChange(page + 1)
              }
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default PaginationBusiness
