import React from 'react'

function CustomSelectWithGroups({ options, className, placeholder, value, onChange, name }) {

  return (
    <select className={className} onChange={(e) => onChange(e)} value={value} name={name}>
      <option value="" disabled>{placeholder}</option>
      {
        options.map((option, key) => (
          <optgroup key={key} label={option.name}>
            {
              option.children.map((opt, index) => (
                <option key={index} value={opt.id}>{opt.name}</option>
              ))
            }
          </optgroup>
        ))
      }
    </select>
  );
}

export default CustomSelectWithGroups