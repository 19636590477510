import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithAuth from "../baseQuery/baseQueryWithAuth";

export const postApi = createApi({
    reducerPath: 'postApi',
    baseQuery: baseQueryWithAuth,
    endpoints: (builder) => ({
        getAllPosts: builder.query({
            query: ({ page = null, searchParams = {} }) => {
                // Construct query parameters dynamically
                const params = new URLSearchParams();

                if (page !== null) {
                    params.append('page', page);
                }

                Object.keys(searchParams).forEach((key) => {
                    if (searchParams[key] !== undefined && searchParams[key] !== null) {
                        params.append(key, searchParams[key]);
                    }
                });

                return {
                    url: `/get-all-posts${params.toString() ? `?${params.toString()}` : ''}`,
                    authRequired: false
                }
            },
        }),
        getMainCategoryPosts: builder.query({
            query: ({ page = null, categorySlug }) => {
                // Construct query parameters dynamically
                const params = new URLSearchParams();

                if (page !== null) {
                    params.append('page', page);
                }

                return {
                    url: `/posts/main-category/${categorySlug}${params.toString() ? `?${params.toString()}` : ''}`,
                    authRequired: false
                }
            },
        }),
        getSinglePost: builder.query({
            query: ({ slug }) => ({
                url: `/get-post/${slug}`,
                authRequired: false
            }),
        }),
        getPostForEdit: builder.query({
            query: ({ slug }) => ({
                url: `/get-post-info/${slug}`,
                authRequired: true
            }),
        }),
        makePremium: builder.mutation({
            query: ({ postId, data }) => ({
                url: `/post/make-premium/${postId}`,
                method: 'POST',
                body: data,
                authRequired: true
            }),
        }),
        refreshPost: builder.mutation({
            query: ({ postId }) => ({
                url: `/post/refresh/${postId}`,
                method: 'POST',
                authRequired: true
            }),
        }),
        deactivatePost: builder.mutation({
            query: ({ postId }) => ({
                url: `/post/deactivate/${postId}`,
                method: 'POST',
                authRequired: true
            }),
        }),
        storeNewPost: builder.mutation({
            query: ({ data }) => ({
                url: 'store-post',
                method: "POST",
                body: data,
                authRequired: true
            })
        }),
        updatePost: builder.mutation({
            query: ({ postId, data }) => ({
                url: `/post/${postId}/update`,
                method: "PUT",
                body: data,
                authRequired: true
            })
        }),
        deleteGalleryItem: builder.mutation({
            query: ({ mediaId }) => ({
                url: `/delete-gallery-item/${mediaId}`,
                method: "DELETE",
                authRequired: true
            })
        })
    })
})

export const { useLazyGetAllPostsQuery, useLazyGetMainCategoryPostsQuery, useLazyGetSinglePostQuery, useLazyGetPostForEditQuery, useMakePremiumMutation, useRefreshPostMutation, useDeactivatePostMutation, useStoreNewPostMutation, useUpdatePostMutation, useDeleteGalleryItemMutation } = postApi