import styled from "styled-components";

export const ContactRow = styled.div`
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 20px;
    }
  }
`;

export const NotFoundStyles= styled.div`
  
h5{
  font-weight: 400;
  color: #3A3A3C;
  line-height: 21px;
}

@media screen and (max-width: 900px){
  img{
    width: 90%;
    margin: 0 auto;
  }
}
`;