import styled from "styled-components";

export const InputTextAreaStyles = styled.div`
  font-family: "Inter";
  textarea {
    font-size: 15px;
    ::placeholder {
      color: #3a3a3c;
    }
  }
`;
