import React, { useState } from 'react'
import {
  BadgeCategory,
  PostButtons,
  PostCardStyles,
  PostIcons,
  PostImg,
  PostLocation
} from '../UserProfile.styles'
import location from '../../../assets/icons/location.svg'
import money from '../../../assets/icons/money.svg'
import area from '../../../assets/icons/area.svg'
import { BACKEND_STORAGE_URL } from '../../../config/urls'
import {
  currencies,
  jobPositions,
  jobWorkingHours,
  noPriceTitle,
  postStatusBadgeBg,
  transmissionTypes,
  vehicleYears
} from '../../../Utilities/constants'
import { useNavigate } from 'react-router-dom'
import { confirmAlert, generalAlertError } from '../../../helpers/alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBath,
  faBed,
  faBriefcase,
  faCalendar,
  faClock,
  faGasPump,
  faMapPin
} from '@fortawesome/free-solid-svg-icons'
import CustomSelect from '../../../components/forms/CustomSelect'
import {
  useDeactivatePostMutation,
  useMakePremiumMutation,
  useRefreshPostMutation
} from '../../../feature/posts/postSliceApi'

function PostCardComponents({ post, triggerGetAuthInfo }) {
  const navigate = useNavigate()
  const [showPremium, setShowPremium] = useState(false)
  const [days, setDays] = useState('')
  const [makePremium] = useMakePremiumMutation()
  const [refreshPost] = useRefreshPostMutation()
  const [deactivatePost] = useDeactivatePostMutation()

  const premiumDays = [
    { id: '1', name: '1 Ditë' },
    { id: '2', name: '2 Ditë' },
    { id: '3', name: '3 Ditë' },
    { id: '4', name: '4 Ditë' },
    { id: '5', name: '5 Ditë' },
    { id: '6', name: '6 Ditë' },
    { id: '7', name: '7 Ditë' }
  ]

  const submitPremium = async () => {
    if (days === '') {
      generalAlertError('Zgjidh numrin e diteve!')
      return
    }
    const res = await makePremium({ postId: post.id, data: { days: days } })
    if (res.error) {
      generalAlertError(res.error.data.error)
    } else {
      setShowPremium(false)
      triggerGetAuthInfo({ page: 0 })
    }
  }

  const refreshSelectedPost = async () => {
    const res = await refreshPost({ postId: post.id })
    if (res.error) {
      generalAlertError(res.error.data.error)
    } else {
      triggerGetAuthInfo({ page: 0 })
    }
  }

  const showPrice = () => {
    if (post.vehicle_details !== null) {
      if (post.vehicle_details.price_on_request === 1) {
        return noPriceTitle.find(
          (el) => el.id == post.vehicle_details.price_on_request_title
        ).name
      } else {
        return (
          post.vehicle_details.price.toLocaleString() +
          ' ' +
          currencies.find(
            (cur) => cur.id == post.vehicle_details.price_currency
          ).name
        )
      }
    }

    if (post.real_estate !== null) {
      if (post.real_estate.price_on_request === 1) {
        return noPriceTitle.find(
          (el) => el.id == post.real_estate.price_on_request_title
        ).name
      } else {
        return (
          post.real_estate.price.toLocaleString() +
          ' ' +
          currencies.find((cur) => cur.id == post.real_estate.price_currency)
            .name
        )
      }
    }
    if (post.work_details !== null) {
      if (post.work_details.price_on_request === 1) {
        return noPriceTitle.find(
          (el) => el.id == post.work_details.price_on_request_title
        ).name
      } else {
        return (
          post.work_details.salary.toLocaleString() +
          ' ' +
          currencies.find((cur) => cur.id == post.work_details.salary_currency)
            .name
        )
      }
    }
    if (post.general_details !== null) {
      if (post.general_details.price_on_request === 1) {
        return noPriceTitle.find(
          (el) => el.id == post.general_details.price_on_request_title
        ).name
      } else {
        return (
          post.general_details.price.toLocaleString() +
          ' ' +
          currencies.find(
            (cur) => cur.id == post.general_details.price_currency
          ).name
        )
      }
    }
  }

  const handleDelete = async () => {
    confirmAlert(
      'Jeni i sigurte qe doni ta arkivoni postimin? Nuk mund ta riktheni me!',
      async () => {
        const res = await deactivatePost({ postId: post.id })
        if (res.error) {
          generalAlertError(res.error.data.error)
        } else {
          triggerGetAuthInfo({ page: 0 })
        }
      }
    )
  }

  return (
    <PostCardStyles
      className={`card d-flex mt-3 ${
        post.is_premium === 1 ? 'premium-post' : ''
      }`}
    >
      <div className='card-body d-flex justify-content-between align-items-start p-0 position-relative'>
        <span
          className={`badge ${
            postStatusBadgeBg.find((sta) => sta.id === post.post_status_id).name
          } position-absolute top-0 start-0 ms-2 mt-2 z-10 px-3 py-2`}
        >
          {post.post_status.name}
        </span>
        <PostImg className='p-2'>
          <img
            src={BACKEND_STORAGE_URL + post.cover_image}
            alt={post.title}
            className='rounded-3'
          />
        </PostImg>
        <div className='me-auto ms-1 ms-md-2 ms-xl-3 mt-3 card-custom-class'>
          <BadgeCategory className='bg-white rounded-2 py-1 px-2 me-1 fw-semibold text-center'>
            {post.category.parent.name}
          </BadgeCategory>
          <BadgeCategory className='bg-white rounded-2 py-1 px-2 ms-1 fw-semibold text-center'>
            {post.category.name}
          </BadgeCategory>
          <PostLocation className='mt-3 d-flex justify-content-start align-items-center'>
            <img src={location} alt='' width={20} />
            <p className='fw-semibold m-0 ms-1 text-dark'>
              {post.city.name}, {post.country.name}
            </p>
          </PostLocation>
          <h4 className='mt-2 text-wrap fs-5 me-2'>{post.title}</h4>
          <PostIcons className='d-flex justify-content-start align-items-center'>
            <img src={money} alt='' width={40} />
            <h4 className='m-0 mt-1 ms-2'>{showPrice()}</h4>
          </PostIcons>
          {post.vehicle_details !== null && (
            <div className='mt-1 mt-md-3 d-flex align-items-center gap-2 gap-md-4'>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faMapPin} className='text-primary' />
                <p className='ms-1 m-0'>
                  {
                    transmissionTypes.find(
                      (el) => el.id == post.vehicle_details.transmission_type
                    ).name
                  }
                </p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faCalendar} className='text-primary' />
                <p className='ms-2 m-0'>
                  {
                    vehicleYears.find(
                      (el) => el.id == post.vehicle_details.vehicle_year
                    ).name
                  }
                </p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faGasPump} className='text-primary' />
                <p className='ms-1 m-0'>{post.vehicle_details.engine.name}</p>
              </PostIcons>
            </div>
          )}
          {post.real_estate !== null && (
            <div className='mt-1 mt-md-3 d-flex align-items-center gap-2 gap-md-4'>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <img src={area} alt='' width={25} />
                <p className='ms-1 m-0'>
                  {parseFloat(post.real_estate.square_meters).toFixed(2)} m
                  <sup>2</sup>
                </p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faBed} className='text-primary' />
                <p className='ms-2 m-0'>{post.real_estate.nr_bedrooms}</p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faBath} className='text-primary' />
                <p className='ms-1 m-0'>{post.real_estate.nr_bathroom}</p>
              </PostIcons>
            </div>
          )}
          {post.work_details !== null && (
            <div className='mt-1 mt-md-2 d-flex align-items-center gap-2 gap-md-4 pb-2'>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faBriefcase} className='text-primary' />
                <p className='ms-2 m-0'>
                  <small>
                    {
                      jobPositions.find(
                        (el) => el.id === post.work_details.job_position
                      ).name
                    }
                  </small>
                </p>
              </PostIcons>
              <PostIcons className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faClock} className='text-primary' />
                <p className='ms-1 m-0'>
                  <small>
                    {
                      jobWorkingHours.find(
                        (el) =>
                          el.id === post.work_details.job_working_hours_type_id
                      ).name
                    }
                  </small>
                </p>
              </PostIcons>
            </div>
          )}
        </div>
        {post.post_status_id === 5 && (
          <PostButtons className='d-none d-md-flex justify-content-center align-items-center flex-column gap-2 my-auto pe-4'>
            <button className='btn btn-danger w-100 btn-sm' disabled>
              Arkivuar
            </button>
          </PostButtons>
        )}
        {post.post_status_id !== 5 && (
          <PostButtons className='d-none d-md-flex justify-content-center align-items-center flex-column gap-2 my-auto pe-4'>
            {!showPremium && (
              <>
                <button
                  className='btn btn-primary w-100 btn-sm'
                  onClick={() => navigate(`/njoftime/${post.slug}/modifiko`)}
                >
                  Modifiko
                </button>
                {post.is_premium !== 1 && (
                  <button
                    className='btn btn-warning w-100 btn-sm'
                    onClick={() => setShowPremium(!showPremium)}
                  >
                    Kalo Premium
                  </button>
                )}
                <button
                  className='btn btn-danger w-100 btn-sm'
                  onClick={() => handleDelete()}
                >
                  Arkivo
                </button>
                {post.refresh_nr !== 0 && (
                  <button
                    className='btn btn-dark w-100 btn-sm'
                    onClick={() => refreshSelectedPost()}
                  >
                    Rifresko({post.refresh_nr})
                  </button>
                )}
              </>
            )}
            {showPremium && (
              <div>
                <label
                  htmlFor='days'
                  className='form-label fw-semibold text-dark mb-1'
                >
                  Sa Ditë?
                </label>
                <CustomSelect
                  options={premiumDays}
                  placeholder={'Zgjidh ditët'}
                  value={days}
                  className={'form-select'}
                  onChange={(e) => setDays(e.target.value)}
                />
                <button
                  className='btn btn-primary w-100 mt-2 btn-sm'
                  onClick={() => submitPremium()}
                >
                  Kalo
                </button>
                <button
                  className='btn btn-danger w-100 mt-2 btn-sm'
                  onClick={() => setShowPremium(!showPremium)}
                >
                  Mbyll
                </button>
              </div>
            )}
          </PostButtons>
        )}
      </div>
      {post.post_status_id === 5 && (
        <PostButtons className='d-flex d-md-none justify-content-center align-items-end gap-2 my-2 px-2'>
          <button className='btn btn-danger w-100 btn-sm' disabled>
            Arkivuar
          </button>
        </PostButtons>
      )}
      {post.post_status_id !== 5 && (
        <PostButtons className='d-flex d-md-none justify-content-center align-items-end gap-2 my-2 px-2'>
          {!showPremium && (
            <>
              <button
                className='btn btn-sm btn-primary w-100'
                onClick={() => navigate(`/njoftime/${post.slug}/modifiko`)}
              >
                Modifiko
              </button>
              {post.is_premium !== 1 && (
                <button
                  className='btn btn-warning w-100 btn-sm'
                  onClick={() => setShowPremium(!showPremium)}
                >
                  Kalo Premium
                </button>
              )}
              <button
                className='btn btn-sm btn-danger w-100'
                onClick={() => handleDelete()}
              >
                Arkivo
              </button>
              {post.refresh_nr !== 0 && (
                <button
                  className='btn btn-dark w-100 btn-sm'
                  onClick={() => refreshSelectedPost()}
                >
                  Rifresko({post.refresh_nr})
                </button>
              )}
            </>
          )}
          {showPremium && (
            <>
              <div className='w-100'>
                <label
                  htmlFor='days'
                  className='form-label fw-semibold text-dark mb-0'
                >
                  Sa Ditë?
                </label>
                <CustomSelect
                  options={premiumDays}
                  placeholder={'Zgjidh ditët'}
                  value={days}
                  className={'form-select mb-0 w-100'}
                  onChange={(e) => setDays(e.target.value)}
                />
              </div>
              <button
                className='btn btn-primary mt-2 btn-sm'
                onClick={() => submitPremium()}
              >
                Kalo
              </button>
              <button
                className='btn btn-danger mt-2 btn-sm'
                onClick={() => setShowPremium(!showPremium)}
              >
                Mbyll
              </button>
            </>
          )}
        </PostButtons>
      )}
    </PostCardStyles>
  )
}

export default PostCardComponents
