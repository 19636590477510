import React from 'react'
import { MessagesContainer, SingleMessage } from '../UserProfile.styles'
import { Link } from 'react-router-dom'

function MessagesTab({ user }) {
  return (
    <>
      <h3 className='mt-5'>Mesazhet Tuaja</h3>
      <div className="row">
        <div className="col-12 col-lg-9 col-xl-8 ">
          <MessagesContainer>
            {user.messages.length === 0 ?
              <div className='alert alert-primary'>
                Nuk keni asnje mesazh!
              </div>
              : user.messages.map((message, index) => (
                <SingleMessage className='rounded-3 bg-white mt-3 px-4 py-4'>
                  <h3>{message.full_name}</h3>
                  <p className='fw-light py-2 '>
                    {message.message}
                  </p>
                  <div className='d-flex justify-content-start align-items-center gap-3'>
                    {message.phone && <h6 className='fw-light m-0'>
                      <a className='text-decoration-none text-dark' href={`tel:${message.phone}`}>
                        Telefono
                      </a>
                    </h6>}
                    {message.email && <h6 className='fw-light m-0'>
                      <a className='text-decoration-none text-dark' href={`mailto:${message.email}`}>
                        Dergo Email
                      </a>
                    </h6>}
                    <h6 className='fw-light m-0'>
                      <Link className='text-decoration-none text-dark' to={`/njoftime/${message.post.slug}`}>
                        Shiko Postimin
                      </Link>
                    </h6>
                  </div>
                </SingleMessage>
              ))}
          </MessagesContainer>
        </div>
      </div>
    </>
  )
}

export default MessagesTab