import styled from "styled-components";


export const MarketingItemStyles = styled.div`
  border-radius: 16px;
  box-shadow: 0 5px 5px 3px rgba(82, 104, 47, 0.1);
  padding: 10px;
  background-color: #fff;
  color: #697e91;
    height: 100%;
    min-height: 400px;

  & strong {
    font-weight: 600;
    color: #425275;
  }
`

export const MarketingItemInner = styled.div`
  align-items: center;
  padding: 20px;
  padding-top: 70px;
  background-color: #F3FFD7;
  border-radius: 12px;
  position: relative;
  height: 100%;
`

export const MarketingFeatures = styled.ul`
    display: flex;
    flex-direction: column;
    padding-left: 0;

    & + * {
        margin-top: 1.25rem;
    }
`

export const MarketingFeaturesItem = styled.li`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & + * {
        margin-top: 0.75rem;
    }
`

export const MarketingFeaturesItemIcon = styled.span`
     background-color: #52682F;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;


    & svg {
        width: 14px;
        height: 14px;
    }
`

export const MarketingTitle = styled.p`
  font-weight: 600;
  font-size: 1.1rem;
  color: #425675;
  line-height: 1.25rem;

  & + * {
    margin-top: 0.75rem;
  }
`

export const MarketingPricing = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #AAC480;
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #425475;
`

export const MarketingPrice = styled.span`
    color: #ffffff;
    font-weight: bold;
    font-size: 1em;
    margin-left: 0.25em;
`
export const ActionButton = styled.a`
  background-color: #52682F;
  cursor: pointer;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 1.125rem;
  text-align: center;
  border: 0;
  outline: 0;
  width: 100%;
  padding: 0.625em 0.75em;
  text-decoration: none;

  &:hover, &:focus {
    background-color: #AAC480;
  }
`

export const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: end;
    margin-top: auto;
    flex-direction: column;
`

export const MarketingInfo = styled.p`
    line-height: 1.2em;
    & * {
        margin-top: 1rem;
    }
`
export const MarketingPreview = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;

    p {
        margin: 0;
        font-weight: 700;
    }
`


export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  position: relative;
  text-align: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export const Image = styled.img`
  max-width: 500px;
  height: auto;
  border-radius: 8px;

  @media (max-width: 768px) {
    max-width: 300px;
  }
`;