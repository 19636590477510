import {
  faCalendar,
  faCar,
  faCarSide,
  faGasPump,
  faGaugeHigh,
  faLocationDot,
  faMapPin,
  faPalette
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  kilometersDone,
  transmissionTypes,
  vehicleYears
} from '../../../Utilities/constants'

function VehicleInfo({ post }) {
  return (
    <div className='card border-0 rounded-2 p-2 mb-2'>
      <div className='card-body p-1'>
        <div className='row'>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faMapPin} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {
                  transmissionTypes.find(
                    (typ) => typ.id === post.vehicle_details.transmission_type
                  ).name
                }
              </p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faGasPump} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {post.vehicle_details.engine.name}
              </p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faPalette} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>{post.vehicle_details.color.name}</p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faCalendar} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {
                  vehicleYears.find(
                    (y) => y.id === post.vehicle_details.vehicle_year
                  ).name
                }
              </p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faCar} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {post.vehicle_details.auto_brand !== null
                  ? post.vehicle_details.auto_brand.name
                  : post.vehicle_details.moto_brand.name}
              </p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faCarSide} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {post.vehicle_details.auto_model !== null
                  ? post.vehicle_details.auto_model.name
                  : post.vehicle_details.moto_model.name}
              </p>
            </div>
          </div>
          <div className='col-6 col-md-5 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faGaugeHigh} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {
                  kilometersDone.find(
                    (km) => km.id === post.vehicle_details.km_done
                  ).name
                }
              </p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faLocationDot} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>{post.city.name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicleInfo
