import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBriefcase,
  faCar,
  faComputer,
  faHouseChimney,
  faMapLocation,
  faMapMarker,
  faSitemap
} from '@fortawesome/free-solid-svg-icons'

function Categories() {
  const navigate = useNavigate()

  const searchPosts = (categoryId) => {
    let data = {
      category: categoryId,
      city: '',
      search: ''
    }

    navigate('/njoftime', {
      state: data
    })
  }

  return (
    <div className='row mt-4'>
      <div
        className='col-6 col-md-4 col-xl-2 my-2 my-md-2 my-xl-0'
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/njoftime/kategoria/prona')}
      >
        <div className='card border-0 bg-primary p-1'>
          <div className='card-body'>
            <div className='d-flex align-items-center justify-content-center gap-3'>
              <FontAwesomeIcon icon={faHouseChimney} size='2x' color='white' />
              <p className='mb-0 text-white h6'>Prona</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='col-6 col-md-4 col-xl-2 my-2 my-md-2 my-xl-0'
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/njoftime/kategoria/automjete')}
      >
        <div className='card border-0 bg-primary p-1'>
          <div className='card-body'>
            <div className='d-flex align-items-center justify-content-center gap-3'>
              <FontAwesomeIcon icon={faCar} size='2x' color='white' />
              <p className='mb-0 text-white h6'>Automjete</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='col-6 col-md-4 col-xl-2 my-2 my-md-2 my-xl-0'
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/njoftime/kategoria/pune')}
      >
        <div className='card border-0 bg-primary p-1'>
          <div className='card-body'>
            <div className='d-flex align-items-center justify-content-center gap-3'>
              <FontAwesomeIcon icon={faBriefcase} size='2x' color='white' />
              <p className='mb-0 text-white h6'>Pune</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='col-6 col-md-4 col-xl-2 my-2 my-md-2 my-xl-0'
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/njoftime/kategoria/elektronika')}
      >
        <div className='card border-0 bg-primary p-1'>
          <div className='card-body'>
            <div className='d-flex align-items-center justify-content-center gap-3'>
              <FontAwesomeIcon icon={faComputer} size='2x' color='white' />
              <p className='mb-0 text-white h6'>Elektronike</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='col-6 col-md-4 col-xl-2 my-2 my-md-2 my-xl-0'
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/njoftime/kategoria/turizem')}
      >
        <div className='card border-0 bg-primary p-1'>
          <div className='card-body'>
            <div className='d-flex align-items-center justify-content-center gap-3'>
              <FontAwesomeIcon icon={faMapLocation} size='2x' color='white' />
              <p className='mb-0 text-white h6'>Turizem</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='col-6 col-md-4 col-xl-2 my-2 my-md-2 my-xl-0'
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/njoftime/kategoria/te-ndryshme')}
      >
        <div className='card border-0 bg-primary p-1'>
          <div className='card-body'>
            <div className='d-flex align-items-center justify-content-center gap-3'>
              <FontAwesomeIcon icon={faSitemap} size='2x' color='white' />
              <p className='mb-0 text-white h6'>Te Ndryshme</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Categories
