import parseJson from "../helpers/parseJSON";
import stringifyJson from "../helpers/stringifyJSON";

export default class StorageService {
  getToken() {
    return localStorage.getItem("jwtToken");
  }

  setToken(userToken) {
    localStorage.setItem("jwtToken", userToken);
  }

  removeToken() {
    localStorage.removeItem("jwtToken");
  }

  getUser() {
    const user = localStorage.getItem("user");
    if (user !== null) {
      return parseJson(user);
    }
    return null;
  }

  setUser(user) {
    localStorage.setItem("user", stringifyJson(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem("jwtRefreshToken", refreshToken);
  }

  getRefreshToken() {
    return localStorage.getItem("jwtRefreshToken");
  }

  removeRefreshToken() {
    localStorage.removeItem("jwtRefreshToken");
  }
}
