import { Link, useLocation, useNavigate } from 'react-router-dom'
import { LogoImg, NavbarIcon } from './Navbar.styles'
import { Navigation } from './Navbar.styles'
import logo from '../assets/images/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faCircleUser,
  faMagnifyingGlass,
  faPlus,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons'
import StorageService from '../services/StorageService'
import { useEffect, useState } from 'react'
import Spinner from './common/Spinner'
import { BACKEND_STORAGE_URL } from '../config/urls'
import { useSelector } from 'react-redux'
import { showHideSearchBarMobile } from '../feature/home/homeActions'

let Storage = new StorageService()

function Navbar() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(
    localStorage.getItem('user') ? localStorage.getItem('user') : null
  )
  const location = useLocation()
  const { showSearchMobile } = useSelector((store) => store.home.menu_bar)

  const logoutUser = () => {
    setLoading(true)
    Storage.removeToken()
    Storage.removeUser()
    Storage.removeRefreshToken()
    setUser(null)

    navigate('/')
    setLoading(false)
  }

  useEffect(() => {
    setUser(localStorage.getItem('user'))
  }, [localStorage.getItem('user')])

  // Update the state when localStorage changes
  useEffect(() => {
    const handleStorageChange = () => {
      setUser(localStorage.getItem('user'))
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [localStorage.getItem('user')])

  const toggleSearch = () => {
    showHideSearchBarMobile(!showSearchMobile)
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <Navigation className='navbar navbar-expand-lg bg-white shadow-sm py-3'>
        <div className='container w-100 d-flex'>
          <Link to={'/'}>
            <LogoImg>
              <img src={logo} alt='Okazion Logo' />
            </LogoImg>
          </Link>
          <div className='d-flex align-items-center justify-content-end gap-3 gap-md-2 gap-xl-2'>
            {user === null && (
              <>
                <Link
                  to='/hyr'
                  className='text-decoration-none py-xl-2 px-xl-2 fw-medium'
                >
                  <div className='d-flex align-items-center justify-content-end gap-3'>
                    <FontAwesomeIcon icon={faArrowRightToBracket} size='xl' />
                    <p className='mb-0 d-none d-xl-block'>Hyr</p>
                  </div>
                </Link>
                <Link
                  to='/regjistrohu'
                  className='text-decoration-none py-xl-2 px-xl-2 fw-medium'
                >
                  <div className='d-flex align-items-center justify-content-end gap-3'>
                    <FontAwesomeIcon icon={faUserPlus} size='xl' />
                    <p className='mb-0 d-none d-xl-block'>Regjistrohu</p>
                  </div>
                </Link>
              </>
            )}
            {location.pathname === '/' && (
              <button
                className='d-block d-xl-none rounded-3 btn btn-primary fw-medium'
                onClick={() => toggleSearch()}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            )}

            <Link
              to='/krijo-njoftim'
              className='text-decoration-none text-white py-xl-2 px-xl-2 rounded-3 btn btn-primary fw-medium'
            >
              <div className='d-flex align-content-center justify-content-center'>
                <NavbarIcon>
                  <FontAwesomeIcon icon={faPlus} />
                </NavbarIcon>
                <span className='ms-2 d-none d-xl-block'>
                  <small>Krijo Njoftim</small>
                </span>
              </div>
            </Link>
            {user !== null && (
              <>
                <Link to={`/profili/${Storage.getUser().username}`}>
                  <small>
                    {Storage.getUser().profile_image !== null ? (
                      <img
                        src={
                          BACKEND_STORAGE_URL + Storage.getUser().profile_image
                        }
                        alt={Storage.getUser().username}
                        width={45}
                        style={{ borderRadius: '50%' }}
                      />
                    ) : (
                      <FontAwesomeIcon icon={faCircleUser} size='3x' />
                    )}
                  </small>
                </Link>
                <div
                  className='text-decoration-none text-primary fw-bold py-xl-2 px-xl-2'
                  style={{ cursor: 'pointer' }}
                  onClick={() => logoutUser()}
                >
                  <div className='d-flex align-items-center justify-content-end gap-2'>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} size='xl' />
                    <p className='mb-0 d-none d-xl-block'>Dil</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Navigation>
    </>
  )
}

export default Navbar
