import React from 'react'
import { InputTextGroupStyles } from './InputTextGroup.styles'

function InputFileGroup({ labelText, labelFor, id, placeholder, inputClass, inputGroupClass, labelClass, error, name, value, onChange }) {
  return (
    <InputTextGroupStyles className={`text-dark ${inputGroupClass}`}>
      {labelText && <label htmlFor={labelFor} className={`form-label ${labelClass}`}>{labelText}</label>}
      <input type={'file'} className={`form-control ${inputClass} ${error ? 'is-invalid' : ""}`} name={name} id={id} placeholder={placeholder} value={value} onChange={(e) => onChange(e)} />
      <div className="invalid-feedback">
        {error}
      </div>
    </InputTextGroupStyles>
  )
}

export default InputFileGroup