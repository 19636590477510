import React from 'react'
import ReactSelectCustom from '../../../components/forms/ReactSelectCustom'
import {
  kilometersDone,
  plateTypes,
  transmissionTypes,
  vehiclePostTypes,
  vehicleStatuses,
  vehicleTypes,
  vehicleYears
} from '../../../Utilities/constants'

function VehiclePostFormComponent({
  updateFormData,
  formData,
  errors,
  brandsLoading,
  brands,
  modelsAutoLoading,
  modelsMotoLoading,
  modelsAuto,
  modelsMoto,
  engines,
  colors,
  generalFormData
}) {
  return (
    <>
      <ReactSelectCustom
        placeholder={'Automjeti eshte per'}
        name='vehicle_post_type'
        options={vehiclePostTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={vehiclePostTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_post_type)}
        onChange={(val) =>
          updateFormData({
            vehicle_post_type: val ? val.value : ''
          })
        }
        error={errors && errors.vehicle_post_type?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.vehicle_post_type?.message}
      </div>
      {generalFormData.post_category_id == 9 && (
        <>
          {brandsLoading ? (
            <div className='d-flex justify-content-center my-2'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <ReactSelectCustom
                placeholder={'Prodhuesi'}
                name='auto_brand_id'
                options={brands.map((c) => ({ value: c.id, label: c.name }))}
                brCustom={'0.375rem'}
                className={'mb-2'}
                values={brands
                  .map((c) => ({ value: c.id, label: c.name }))
                  .find((el) => el.value == formData.auto_brand_id)}
                onChange={(val) =>
                  updateFormData({
                    auto_brand_id: val ? val.value : ''
                  })
                }
                error={errors && errors.auto_brand_id?.message}
              />
              <div className='invalid-feedback d-block'>
                {errors && errors.auto_brand_id?.message}
              </div>
            </>
          )}
        </>
      )}

      {generalFormData.post_category_id == 9 && (
        <>
          {modelsAutoLoading ? (
            <div className='d-flex justify-content-center my-2'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <ReactSelectCustom
                placeholder={'Modeli'}
                name='auto_model_id'
                options={modelsAuto.map((c) => ({
                  value: c.id,
                  label: c.name
                }))}
                brCustom={'0.375rem'}
                className={'mb-2'}
                values={modelsAuto
                  .map((c) => ({ value: c.id, label: c.name }))
                  .find((el) => el.value == formData.auto_model_id)}
                onChange={(val) =>
                  updateFormData({
                    auto_model_id: val ? val.value : ''
                  })
                }
                error={errors && errors.auto_model_id?.message}
              />
              <div className='invalid-feedback d-block'>
                {errors && errors.auto_model_id?.message}
              </div>
            </>
          )}
        </>
      )}

      {generalFormData.post_category_id == 10 && (
        <>
          {brandsLoading ? (
            <div className='d-flex justify-content-center my-2'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <ReactSelectCustom
                placeholder={'Prodhuesi'}
                name='moto_brand_id'
                options={brands.map((c) => ({ value: c.id, label: c.name }))}
                brCustom={'0.375rem'}
                className={'mb-2'}
                values={brands
                  .map((c) => ({ value: c.id, label: c.name }))
                  .find((el) => el.value == formData.moto_brand_id)}
                onChange={(val) =>
                  updateFormData({
                    moto_brand_id: val ? val.value : ''
                  })
                }
                error={errors && errors.moto_brand_id?.message}
              />
              <div className='invalid-feedback d-block'>
                {errors && errors.moto_brand_id?.message}
              </div>
            </>
          )}
        </>
      )}

      {generalFormData.post_category_id == 10 && (
        <>
          {modelsMotoLoading ? (
            <div className='d-flex justify-content-center my-2'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <ReactSelectCustom
                placeholder={'Modeli'}
                name='moto_model_id'
                options={modelsMoto.map((c) => ({
                  value: c.id,
                  label: c.name
                }))}
                brCustom={'0.375rem'}
                className={'mb-2'}
                values={modelsMoto
                  .map((c) => ({ value: c.id, label: c.name }))
                  .find((el) => el.value == formData.moto_model_id)}
                onChange={(val) =>
                  updateFormData({
                    moto_model_id: val ? val.value : ''
                  })
                }
                error={errors && errors.moto_model_id?.message}
              />
              <div className='invalid-feedback d-block'>
                {errors && errors.moto_model_id?.message}
              </div>
            </>
          )}
        </>
      )}

      <ReactSelectCustom
        placeholder={'Transmetuesi'}
        name='transmission_type'
        options={transmissionTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={transmissionTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.transmission_type)}
        onChange={(val) =>
          updateFormData({
            transmission_type: val ? val.value : ''
          })
        }
        error={errors && errors.transmission_type?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.transmission_type?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Targat'}
        name='plate_types'
        options={plateTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={plateTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.plate_types)}
        onChange={(val) =>
          updateFormData({
            plate_types: val ? val.value : ''
          })
        }
        error={errors && errors.plate_types?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.plate_types?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Gjendja e mjetit'}
        name='vehicle_status'
        options={vehicleStatuses.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={vehicleStatuses
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_status)}
        onChange={(val) =>
          updateFormData({
            vehicle_status: val ? val.value : ''
          })
        }
        error={errors && errors.vehicle_status?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.vehicle_status?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Tipologjia e Mjetit'}
        name='vehicle_type_id'
        options={vehicleTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={vehicleTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_type_id)}
        onChange={(val) =>
          updateFormData({
            vehicle_type_id: val ? val.value : ''
          })
        }
        error={errors && errors.vehicle_type_id?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.vehicle_type_id?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Karburanti'}
        name='engine_id'
        options={engines.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={engines
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.engine_id)}
        onChange={(val) =>
          updateFormData({
            engine_id: val ? val.value : ''
          })
        }
        error={errors && errors.engine_id?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.engine_id?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Viti i Prodhimit'}
        name='vehicle_year'
        options={vehicleYears.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={vehicleYears
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.vehicle_year)}
        onChange={(val) =>
          updateFormData({
            vehicle_year: val ? val.value : ''
          })
        }
        error={errors && errors.vehicle_year?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.vehicle_year?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Kilometra'}
        name='km_done'
        options={kilometersDone.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={kilometersDone
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.km_done)}
        onChange={(val) =>
          updateFormData({
            km_done: val ? val.value : ''
          })
        }
        error={errors && errors.km_done?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.km_done?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Ngjyra'}
        name='color_id'
        options={colors.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={colors
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.color_id)}
        onChange={(val) =>
          updateFormData({
            color_id: val ? val.value : ''
          })
        }
        error={errors && errors.color_id?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.color_id?.message}
      </div>
    </>
  )
}

export default VehiclePostFormComponent
