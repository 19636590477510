import React from 'react'
import { Helmet } from 'react-helmet'

function SeoBusinesses() {
  return (
    <Helmet>
      <title data-n-head='true'>
        Bizneset në Okazion.al | Promovoni Produktet dhe Shërbimet Tuaja
      </title>
      <meta
        name='description'
        content='Shfletoni bizneset që promovojnë produktet dhe shërbimet e tyre në Okazion.al. Gjeni oferta të personalizuara nga biznese të besueshme në kategori të ndryshme.'
      />
      <meta
        name='apple-mobile-web-app-title'
        content='Bizneset në Okazion.al | Promovoni Produktet dhe Shërbimet Tuaja'
      />
      <meta
        name='keywords'
        content='biznese, okazion.al, reklamim biznesesh, produkte, shërbime, oferta biznesesh, promovim online, platformë reklamimi, Okazion'
      />
      <meta name='robots' content='index, follow' />
      <meta
        name='googlebot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta
        name='bingbot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta name='twitter:card' content='https://okazion.al/images/logo.png' />
      <meta
        name='twitter:description'
        content='Zbuloni të gjitha bizneset që promovojnë produktet dhe shërbimet e tyre në Okazion.al. Bashkohuni me qindra biznese të tjera dhe bëhuni pjesë e komunitetit tonë.'
      />
      <meta
        name='twitter:title'
        content='Bizneset në Okazion.al | Promovoni Produktet dhe Shërbimet Tuaja'
      />
      <meta name='twitter:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:locale' content='sq_AL' />
      <meta property='og:type' content='article' />
      <meta
        property='og:title'
        content='Bizneset në Okazion.al | Promovoni Produktet dhe Shërbimet Tuaja'
      />
      <meta
        property='og:description'
        content='Njihuni me bizneset që janë pjesë e Okazion.al dhe shikoni se si promovojnë produktet dhe shërbimet e tyre.'
      />
      <meta property='og:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:url' content='https://okazion.al/bizneset' />
      <meta
        property='og:image:secure_url'
        content='https://okazion.al/images/logo.png'
      />
      <meta property='og:image:type' content='image/png' />
      <meta
        property='og:image:alt'
        content='Bizneset në Okazion.al Logo'
      ></meta>
    </Helmet>
  )
}

export default SeoBusinesses
