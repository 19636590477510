import React from 'react'
import { InputRadioGropuStyles } from './InputRadioGroup.styles'

function InputRadioGroup({ inputGroupRadio, name, id, value, inputRadioClass, labelRadioClass, labelRadio, onChange, checked, error }) {
    return (
        <InputRadioGropuStyles className={`form-check ${inputGroupRadio} ${error !== undefined ? 'is-invalid' : ''}`}>
            <input className={`form-check-input ${inputRadioClass}`} type="radio" name={name} id={id} value={value} checked={checked} onChange={(e) => onChange(e)} />
            <label className={`form-check-label ${labelRadioClass}`} htmlFor={name}>
                {labelRadio}
            </label>
        </InputRadioGropuStyles>
    )
}

export default InputRadioGroup