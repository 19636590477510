import React, { useState } from 'react'
import { BalanceCardButton, CardsSection } from '../UserProfile.styles'
import money from '../../../assets/icons/money.svg'
import PostCardComponents from './PostCardComponents'
import Pagination from '../../../components/common/Pagination'

function PostsTab({
  user,
  posts,
  page,
  last_page,
  navigate,
  setCurrentMenu,
  triggerGetAuthInfo
}) {
  const [showPremium, setShowPremium] = useState(null)
  return (
    <>
      <h3 className='mt-5'>Njoftimet e Mia</h3>
      <span className='bg-dark h6 px-2 py-1 text-white rounded'>
        Njoftime te mbetuar: {user.remaining_posts}
      </span>
      <div className='row'>
        <div className='col-12 col-lg-8'>
          <CardsSection>
            {posts.data.length === 0 ? (
              <div className='alert alert-muted'>Nuk Keni Asnje Njoftim</div>
            ) : (
              posts.data.map((post, index) => (
                <PostCardComponents
                  post={post}
                  key={index}
                  triggerGetAuthInfo={triggerGetAuthInfo}
                  showPremium={showPremium}
                  setShowPremium={setShowPremium}
                />
              ))
            )}

            <div className='my-2'>
              {last_page !== 1 && (
                <Pagination
                  collectData={triggerGetAuthInfo}
                  page={page}
                  links={posts}
                  last_page={last_page}
                  navigate={navigate}
                />
              )}
            </div>
          </CardsSection>
        </div>
        <div className='col-12 col-lg-4 mt-3'>
          <div className='card'>
            <div className='card-body p-4'>
              <h5>Bilanci i Llogarise</h5>
              <div className='d-flex justify-content-start align-items-center'>
                <img src={money} alt='' />
                <h1 className='m-0 mt-1 ms-2'>
                  {user.credit !== null ? user.credit.amount : '0'} &euro;
                </h1>
              </div>
              <BalanceCardButton className='d-flex gap-2 mt-4 mb-2'>
                <button
                  className='btn btn-outline-primary px-3'
                  onClick={() => setCurrentMenu('llogaria')}
                >
                  Shpenzimet
                </button>
                <button className='btn btn-primary px-3'>
                  Rimbush Llogarine
                </button>
              </BalanceCardButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostsTab
