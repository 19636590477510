import { jwtDecode } from 'jwt-decode';
import { generalAlertError, generalAlertSuccessToast } from '../../helpers/alert';
import axe from '../../services/axiosConfig/axiosClient';
import caller from './socialAuthCaller'
import StorageService from '../../services/StorageService';

const Storage = new StorageService()

export const attemptLoginSocial = async (jwtToken, navigate) => {
  caller.loginPending();
  try {
    const response = await axe.get("/auth/user/social", {
      headers: {
        'Authorization': jwtToken
      }
    });

    const { data, status } = response;

    if (status === 404) {
      generalAlertError("Error!");
      return caller.loginRejected("Invalid Credentials");
    }

    if (status !== 200) {
      generalAlertError("Internal Error");
      return caller.loginRejected("Internal Error");
    }

    let user = jwtDecode(data.data.access_token);

    Storage.setToken(data.data.access_token);
    Storage.setUser(user);
    Storage.setRefreshToken(data.data.refresh_token);
    navigate("/");
    caller.loginSuccess();
    generalAlertSuccessToast("Miresevini ne Okazion.al!");
  } catch (err) {
    return caller.loginRejected("Internal Error");
  }
};