import React, { useEffect } from 'react'
import { BadgeCategory } from '../UserProfile/UserProfile.styles'
import money from '../../assets/icons/money.svg'
import locationImg from '../../assets/icons/location.svg'
import {
  JobPostContainer,
  MainPostImg,
  PostInfoCard
} from './SinglePages.styles'
import Button from '../../components/common/Button'
import InputTextGroup from '../../components/forms/InputTextGroup'
import InputTextArea from '../../components/forms/InputTextArea'
import PostCard from '../../components/common/PostCard'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Spinner from '../../components/common/Spinner'
import moment from 'moment'
import { currencies, noPriceTitle } from '../../Utilities/constants'
import { BACKEND_STORAGE_URL } from '../../config/urls'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { storeNewContact } from '../../feature/contact/contactActions'
import SmallSpinner from '../../components/common/SmallSpinner'
import PostGallery from './PostGallery'
import WorkInfo from './components/WorkInfo'
import RealEstateInfo from './components/RealEstateInfo'
import VehicleInfo from './components/VehicleInfo'
import SeoSinglePost from '../Seo/SeoSinglePost'
import { useLazyGetSinglePostQuery } from '../../feature/posts/postSliceApi'

const phoneRegExp = /^\+?\d+$/

const schema = yup.object({
  full_name: yup.string().required('Ju lutem vendosni emrin dhe mbiemrin!'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Formati i numrit nuk eshte i sakte!')
    .required('Vendosni numrin e telefonit!'),
  email: yup
    .string()
    .required('Vendosni emailin')
    .email('Vendosni nje adrese emaili te sakte!'),
  message: yup.string().required('Shkruani mesazhin tuaj!')
})

function SingleJobPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const [triggerGetSinglePost, { data, isLoading }] =
    useLazyGetSinglePostQuery()

  const post = data?.data?.post || null
  const banners = data?.data?.banners || []
  const related_posts = data?.data?.related_posts || []

  const { loading: loadContactSubmit } = useSelector(
    (store) => store.contacts.contacts
  )
  const { slug } = useParams()
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      full_name: '',
      phone: '',
      email: '',
      message: ''
    }
  })

  const formData = watch()

  const updateFormData = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
  }

  const submitContactForm = () => {
    const data = {
      ...formData,
      customer_id: post.customer_id,
      post_id: post.id
    }

    storeNewContact(data, reset)
  }

  useEffect(() => {
    if (slug) {
      triggerGetSinglePost({ slug })
    }
  }, [slug])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  if (isLoading || data === null || data === undefined) {
    return <Spinner />
  }

  const showPrice = () => {
    if (post.vehicle_details !== null) {
      if (post.vehicle_details.price_on_request === 1) {
        return noPriceTitle.find(
          (el) => el.id == post.vehicle_details.price_on_request_title
        ).name
      } else {
        return (
          post.vehicle_details.price.toLocaleString() +
          ' ' +
          currencies.find(
            (cur) => cur.id == post.vehicle_details.price_currency
          ).name
        )
      }
    }

    if (post.real_estate !== null) {
      if (post.real_estate.price_on_request === 1) {
        return noPriceTitle.find(
          (el) => el.id == post.real_estate.price_on_request_title
        ).name
      } else {
        return (
          post.real_estate.price.toLocaleString() +
          ' ' +
          currencies.find((cur) => cur.id == post.real_estate.price_currency)
            .name
        )
      }
    }
    if (post.work_details !== null) {
      if (post.work_details.price_on_request === 1) {
        return noPriceTitle.find(
          (el) => el.id == post.work_details.price_on_request_title
        ).name
      } else {
        return (
          post.work_details.salary.toLocaleString() +
          ' ' +
          currencies.find((cur) => cur.id == post.work_details.salary_currency)
            .name
        )
      }
    }
    if (post.general_details !== null) {
      if (post.general_details.price_on_request === 1) {
        return noPriceTitle.find(
          (el) => el.id == post.general_details.price_on_request_title
        ).name
      } else {
        return (
          post.general_details.price.toLocaleString() +
          ' ' +
          currencies.find(
            (cur) => cur.id == post.general_details.price_currency
          ).name
        )
      }
    }
  }

  return (
    <>
      <SeoSinglePost post={post} />
      <div className='container'>
        <div className='py-4'>
          <p className='fw-medium m-0'>
            <Link to={'/'} className='text-decoration-none text-dark'>
              Fillimi
            </Link>{' '}
            /{' '}
            <Link to={'/'} className='text-decoration-none text-dark'>
              {post.category.parent.name}
            </Link>{' '}
            /{' '}
            <Link to={'/'} className='text-decoration-none text-dark'>
              {post.category.name}
            </Link>{' '}
            / <span className='text-primary text-capitalize'>{post.title}</span>
          </p>
        </div>
      </div>
      <hr className='my-1' />
      <JobPostContainer className='container'>
        <div className='row mt-5'>
          <div className='col-12'>
            <div className='d-flex gap-2'>
              <BadgeCategory className='bg-white rounded-2 py-1 px-2  fw-semibold text-center'>
                {post.category.parent.name}
              </BadgeCategory>
              <BadgeCategory className='bg-white rounded-2 py-1 px-2  fw-semibold text-center'>
                {post.category.name}
              </BadgeCategory>
            </div>
            <h2 className='fw-bold mt-3 text-capitalize'>{post.title}</h2>
            <small className='fw-lighter'>
              Publikuar me: {moment(post.created_at).format('LLL')}
            </small>
          </div>
        </div>

        <div className='row my-4'>
          <div className='col-12 col-lg-7'>
            <MainPostImg className='rounded-3'>
              <PostGallery gallery={post.gallery} cover={post.cover_image} />
            </MainPostImg>

            <PostInfoCard className='card mt-2 border-0 rounded-2'>
              <div className='card-body p-4'>
                <div className='d-flex justify-content-start align-items-center gap-4'>
                  <div className='d-flex justify-content-start align-items-center'>
                    <img src={money} alt='' />
                    <h4 className='m-0 mt-1 ms-2'>{showPrice()}</h4>
                  </div>
                  <div className='mt-1 mt-md-2 mt-xl-0 d-flex justify-content-start align-items-center'>
                    <img src={locationImg} alt='' width={30} />
                    <p className='fw-semibold m-0 ms-1 text-dark'>
                      {post.city.name}, {post.country.name}
                    </p>
                  </div>
                </div>

                <div className='mt-4'>
                  <h5 className='fw-medum'>Pershkrimi:</h5>
                  <p
                    className='fw-light mt-2'
                    dangerouslySetInnerHTML={{
                      __html: post.description.replace(/\n/g, '<br>')
                    }}
                  />
                </div>
              </div>
            </PostInfoCard>
          </div>
          <div className='col-12 col-lg-5'>
            {post.vehicle_details !== null && <VehicleInfo post={post} />}
            {post.real_estate !== null && <RealEstateInfo post={post} />}
            {post.work_details !== null && <WorkInfo post={post} />}
            <div className='card border-0 rounded-2 p-3'>
              <div className='card-body'>
                <div className='d-flex align-items-center justify-content-start gap-5'>
                  {post.customer.profile_image === null ? (
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      className='text-primary'
                      size='3x'
                    />
                  ) : (
                    <img
                      src={BACKEND_STORAGE_URL + post.customer.profile_image}
                      alt={
                        post.customer.business_name === null
                          ? post.customer.name + ' ' + post.customer.surname
                          : post.customer.business_name
                      }
                      className='rounded-circle'
                      width={50}
                      height={50}
                    />
                  )}

                  <h6 className='fw-bold m-0'>
                    {post.customer.business_name === null
                      ? post.customer.name + ' ' + post.customer.surname
                      : post.customer.business_name}
                  </h6>
                  {post.customer.business_name !== null && (
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={
                        post.customer.business_name !== null
                          ? () =>
                              navigate(
                                `/business-page/${post.customer.username}`
                              )
                          : () => {}
                      }
                    >
                      Profili
                    </button>
                  )}
                </div>
                <hr />
                <div className='d-flex justify-content-between align-items-center gap-2'>
                  <a
                    href={`tel:${post.phone}`}
                    className='mx-auto btn rounded-2 px-4 py-2 border w-100'
                  >
                    {post.phone}
                  </a>
                  <a
                    href={`https://wa.me/${post.phone}`}
                    className='mx-auto btn rounded-2 px-4 py-2 btn-primary w-100'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Shkruaj Whatsapp
                  </a>
                </div>
                {loadContactSubmit ? (
                  <SmallSpinner />
                ) : (
                  <form
                    className='mt-4'
                    onSubmit={handleSubmit(submitContactForm)}
                  >
                    <InputTextGroup
                      type={'text'}
                      placeholder='Emri i plote'
                      name={'full_name'}
                      inputClass={'py-2 rounded-2 mt-2'}
                      value={formData.full_name}
                      onChange={(e) =>
                        updateFormData({
                          full_name: e.target.value
                        })
                      }
                      error={errors.full_name?.message}
                    />
                    <InputTextGroup
                      type={'text'}
                      placeholder='Numer Kontakti'
                      name={'phone'}
                      inputClass={'py-2 rounded-2 mt-2'}
                      value={formData.phone}
                      onChange={(e) =>
                        updateFormData({
                          phone: e.target.value
                        })
                      }
                      error={errors.phone?.message}
                    />
                    <InputTextGroup
                      type={'text'}
                      placeholder='Email (opsionale)'
                      name={'email'}
                      inputClass={'py-2 rounded-2 mt-2'}
                      value={formData.email}
                      onChange={(e) =>
                        updateFormData({
                          email: e.target.value
                        })
                      }
                      error={errors.email?.message}
                    />
                    <InputTextArea
                      placeholder={'Mesazhi Juaj (opsionale)'}
                      textAreaDivClass={'mt-2'}
                      name={'message'}
                      value={formData.message}
                      onChange={(e) =>
                        updateFormData({
                          message: e.target.value
                        })
                      }
                      error={errors.message?.message}
                    />
                    <Button
                      buttonText={'Dergo Mesazh'}
                      type={'submit'}
                      buttonClass={'w-100 px-4 py-2 btn-primary mt-2'}
                    />
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>

        {banners
          .filter((b) => b.position === 8)
          .map((banner, index) => (
            <div key={index} className='w-75 d-table mx-auto'>
              <a href={banner.url} target='_blank' rel='noreferrer'>
                <img
                  src={BACKEND_STORAGE_URL + banner.image_path}
                  alt=''
                  width={'100%'}
                />
              </a>
            </div>
          ))}
        <h3 className='text-center mt-5'>Njoftime te ngjashme</h3>
        <div className='row mt-3 mb-3'>
          <div className='col-12 col-lg-7'>
            {related_posts.length === 0 ? (
              <div className='alert alert-primary mt-3'>
                Nuk ka njoftime te ngjashme!
              </div>
            ) : (
              <div className='d-flex align-items-stretch justify-content-start flex-column'>
                {related_posts.map((post, index) => (
                  <PostCard post={post} key={index} />
                ))}
              </div>
            )}
          </div>
          <div className='col-12 col-lg-5'>
            {banners
              .filter((b) => b.position === 7)
              .map((banner, index) => (
                <div key={index} className='mt-3'>
                  <a href={banner.url} target='_blank' rel='noreferrer'>
                    <img
                      src={BACKEND_STORAGE_URL + banner.image_path}
                      alt=''
                      width={'100%'}
                    />
                  </a>
                </div>
              ))}
          </div>
        </div>
        {related_posts.length > 2 && (
          <div className='d-flex align-items-center justify-content-center mb-5'>
            <Link to={'/'} className='btn btn-primary'>
              Shiko Me Shume
            </Link>
          </div>
        )}
      </JobPostContainer>
    </>
  )
}

export default SingleJobPage
