import styled from "styled-components";

export const LeftArrow = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid #97B962;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 25px);
  z-index: 9999;
  left: 10px;
  color: #97B962;
  cursor: pointer;
`;

export const RightArrow = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid #97B962;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 25px);
  z-index: 9999;
  right: 10px;
  color: #97B962;
  cursor: pointer;
`;


export const CustomClose = styled.div`
width: 50px;
height: 50px;
border: 2px solid #97B962;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
position: absolute;
top: 50px;
z-index: 9999;
right: 50px;
color: #97B962;
cursor: pointer;
`