import axios from "axios";
import { axeRequestInterceptor, axeResponseInterceptor } from "./axeHelpers";
import { BACKEND_API_URL } from "../../config/urls";

const axe = axios.create();

axe.defaults.validateStatus = (status) => status < 500;

axe.interceptors.request.use(axeRequestInterceptor);

axe.interceptors.response.use((response) => {
  return response;
}, axeResponseInterceptor);

axe.defaults.baseURL = BACKEND_API_URL;

export default axe;
