import { useState, useEffect } from "react";
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

export const Uploader = ({ note, allowedFileTypes, onFileUpload, maxfiles, onFileRemove }) => {
    const [uppy, setUppy] = useState();

    useEffect(() => {
        const uploadConfigurationFileUpload = {
            restrictions: {
                maxFileSize: 3000000000,
                maxNumberOfFiles: maxfiles,
                minNumberOfFiles: 1,
                allowedFileTypes: allowedFileTypes !== undefined ? allowedFileTypes : ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/avif']
            },
        };

        const uppy = new Uppy(uploadConfigurationFileUpload);

        uppy.on('files-added', (files) => {
            let filesArr = []
            for (let i = 0; i < files.length; i++) {
                filesArr.push(files[i].data)

            }
            onFileUpload(filesArr)
        });

        uppy.on('file-removed', (file) => {
            onFileRemove(file)
        })

        setUppy(uppy);
    }, []);

    if (uppy === undefined) {
        return <></>;
    }

    return (

        <Dashboard uppy={uppy} note={note} proudlyDisplayPoweredByUppy={false} height={200} hideUploadButton={true} />

    );
}