import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import SeoAbout from './Seo/SeoAbout'

function AboutUs() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  return (
    <>
      <SeoAbout />
      <div className='container my-5'>
        <h1 className='fw-medium text-center text-dark'>Rreth Okazion.Al</h1>

        <div className=' mx-auto d-flex flex-column mt-5'>
          <h5 className='mt-4'>Kush Jemi Ne</h5>
          <p>
            Okazion.al është një platformë e dedikuar për blerjen dhe shitjen e
            produkteve të ndryshme, që ofron një hapësirë të sigurt dhe të lehtë
            për individë dhe biznese që duan të shkëmbejnë artikuj. Ne besojmë
            se çdo produkt ka vlerë dhe ne jemi këtu për ta ndihmuar ju të gjeni
            blerjet më të mira ose të shisni artikuj që nuk i nevojiten më.
            <br />
            <b>Misioni ynë:</b> <br />
            Misioni ynë është të krijojmë një treg të hapur dhe të aksesueshëm
            për të gjithë, ku mundësitë e blerjes dhe shitjes janë të shumta. Ne
            synojmë të ofrojmë një përvojë përdoruesi të shkëlqyer dhe një
            shërbim cilësor, për të siguruar që çdo transaksion të jetë i
            thjeshtë dhe i sigurt.
            <br />
            <b>Vlerat tona:</b>
            <br />
            <ul>
              <li>
                Transparenca: Ne besojmë në një komunikim të hapur dhe të
                sinqertë me përdoruesit tanë.
              </li>
              <li>
                Siguria: Ne jemi të përkushtuar për të mbrojtur informacionin
                tuaj personal dhe për të siguruar që çdo transaksion të jetë i
                sigurt.
              </li>
              <li>
                Cilësia: Ne punojmë për të ofruar një platformë që plotëson
                standardet më të larta të cilësisë dhe performancës.
              </li>
            </ul>
            <br />
            <b>Ekipi ynë:</b>
            <br />
            Ekipi ynë përbëhet nga profesionistë të përkushtuar dhe të
            apasionuar pas teknologjisë dhe tregjeve online. Ne jemi të
            përkushtuar për të ndihmuar përdoruesit tanë të kenë një përvojë
            pozitive dhe për të mbështetur rritjen e komunitetit tonë.
          </p>

          <h5 className='mt-4'>Pse të na zgjidhni?</h5>
          <p>
            Duke u bashkuar me Okazion.al, ju jeni duke zgjedhur një platformë
            që vlerëson përdoruesit e saj dhe është e përkushtuar për të ofruar
            shërbime të shkëlqyera. Ne jemi këtu për t'ju ndihmuar në çdo hap të
            procesit të blerjes dhe shitjes. Faleminderit që jeni pjesë e
            komunitetit tonë! Ne shpresojmë të kontribuojmë në përvojën tuaj të
            blerjeve dhe shitjeve në internet.
          </p>
        </div>
      </div>
    </>
  )
}

export default AboutUs
