import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithAuth from "../baseQuery/baseQueryWithAuth";


export const formDataApi = createApi({
    reducerPath: 'formDataApi',
    baseQuery: baseQueryWithAuth,
    endpoints: (builder) => ({
        getInitialFormData: builder.query({
            query: () => ({
                url: '/post-form-data',
                authRequired: true
            })
        }),
        getAllBrands: builder.query({
            query: ({ categoryId }) => ({
                url: `/post-vehicle-data/${categoryId}`,
                authRequired: true
            })
        }),
        getMotoModels: builder.query({
            query: ({ brandId }) => ({
                url: `/get-moto-models/${brandId}`,
                authRequired: true
            })
        }),
        getAutoModels: builder.query({
            query: ({ brandId }) => ({
                url: `/get-auto-models/${brandId}`,
                authRequired: true
            })
        }),
        getPostsFormData: builder.query({
            query: () => ({
                url: '/all-posts/form-data',
                authRequired: false
            })
        }),
        getNavigationData: builder.query({
            query: () => ({
                url: '/navbar-formdata',
                authRequired: false
            })
        })
    })
})


export const { useLazyGetInitialFormDataQuery, useLazyGetAllBrandsQuery, useLazyGetMotoModelsQuery, useLazyGetAutoModelsQuery, useLazyGetPostsFormDataQuery, useLazyGetNavigationDataQuery } = formDataApi