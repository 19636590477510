import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu_bar: {
    showSearchMobile: false
  }
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    toggleSearchMobile: (state, { payload }) => {
      state.menu_bar.showSearchMobile = payload
    }
  },

});
