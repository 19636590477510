import React, { useEffect } from 'react'
import {
  AuthLogo,
  ForgotParagraph,
  RegisterBody,
  RegisterCard
} from './Register.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Spinner from '../../components/common/Spinner'
import { useVerifyEmailCallMutation } from '../../feature/auth/authSliceApi'
import StorageService from '../../services/StorageService'
import {
  generalAlertError,
  generalAlertSuccessToast
} from '../../helpers/alert'
import { jwtDecode } from 'jwt-decode'

const Storage = new StorageService()

function VerifyEmail() {
  const location = useLocation()
  const [verifyEmailCall, { isLoading }] = useVerifyEmailCallMutation()
  const navigate = useNavigate()

  const checkEmailVerification = async (token) => {
    const res = await verifyEmailCall({ data: { verification_token: token } })

    if (res.error) {
      generalAlertError('Email eshte i verifikuar!')
    } else {
      let user = jwtDecode(res.data.data.access_token)
      Storage.setToken(res.data.data.access_token)
      Storage.setRefreshToken(res.data.data.refresh_token)
      Storage.setUser(user)
      generalAlertSuccessToast('Email u verifikua me sukses!')
      setTimeout(() => {
        navigate(
          `/profili/${JSON.parse(localStorage.getItem('user')).username}`
        )
      }, 2000)
    }
  }

  useEffect(() => {
    if (Storage.getUser()) {
      return
    }

    let token = location.search.split('=')[1]
    if (token) {
      checkEmailVerification(token)
    }
  }, [location])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='px-md-5 py-1'>
      <div className='container row mx-auto py-5'>
        <div className='col-12 col-lg-8 offset-lg-2 my-5'>
          <RegisterCard className='card py-md-3 px-0 px-xl-5'>
            <RegisterBody className='card-body d-flex justify-content-center align-items-center px-0 px-md-5 flex-column'>
              <AuthLogo className='mb-2'>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size='5x'
                  className='text-primary'
                />
              </AuthLogo>
              <h3 className='mt-2 w-75 text-center'>
                Emaili juaj u verifikua me sukses
              </h3>
              <ForgotParagraph className='w-75'>
                <p className='text-center'>
                  Ju lutem prisni! Ne do ju ridrejtojme tek profili juaj!
                </p>
              </ForgotParagraph>
              {localStorage.getItem('user') ? (
                <Link
                  className={'mx-auto py-2 btn rounded-2 w-75 btn-primary'}
                  to={`/profili/${
                    JSON.parse(localStorage.getItem('user')).username
                  }`}
                >
                  Profili
                </Link>
              ) : (
                <Link
                  className={'mx-auto py-2 btn rounded-2 w-75 btn-primary'}
                  to={`/hyr`}
                >
                  Hyr
                </Link>
              )}
            </RegisterBody>
          </RegisterCard>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
