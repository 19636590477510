import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

function TermsAndConditions() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  return (
    <div className='container my-5'>
      <h2 className='text-center mt-5'>Kushtet dhe Termat e Shërbimit</h2>
      <div className='container'>
        <p>
          Mirë se vini në Okazion.al! Duke përdorur këtë platformë, ju pranoni
          këto kushte dhe terma. Ju lutemi lexoni me kujdes përpara se të
          përdorni shërbimet tona.
        </p>
        <ol>
          <li>
            <div>Pranimi i Kushteve</div>
            <p>
              Duke aksesuar ose përdorur Okazion.al, ju pranoni se jeni të
              lidhur nga këto kushte dhe terma. Nëse nuk jeni dakord me këto
              kushte, ju lutemi mos e përdorni platformën tonë.
            </p>
          </li>
          <li>
            <div>Shërbimet</div>
            <p>
              Okazion.al ofron një platformë për blerjen dhe shitjen e
              produkteve të ndryshme. Ne nuk jemi përgjegjës për transaksionet
              mes përdoruesve dhe nuk garantojmë cilësinë, saktësinë ose
              disponueshmërinë e produkteve.
            </p>
          </li>
          <li>
            <div>Regjistrimi i Llogarisë</div>
            <p>
              Për të përdorur disa nga shërbimet tona, mund t'ju kërkohet të
              krijoni një llogari. Ju jeni përgjegjës për mbajtjen e
              konfidencialitetit të informacionit të llogarisë suaj dhe për të
              parandaluar aksesin e paautorizuar në llogarinë tuaj. Ju pranoni
              të njoftoni menjëherë Okazion.al për çdo përdorim të paautorizuar
              të llogarisë suaj.
            </p>
          </li>
          <li>
            <div>Përdorimi i Platformës</div>
            <p>
              Ju angazhoheni të përdorni Okazion.al në përputhje me të gjitha
              ligjet dhe rregulloret në fuqi. Ju nuk do të angazhoheni në
              aktivitete që dëmtojnë ose ndërhyjnë në funksionimin e platformës.
            </p>
          </li>
          <li>
            <div>Politika e Privatësisë</div>
            <p>
              Informacioni personal që mbledhim do të trajtohet në përputhje me
              Politikën tonë të Privatësisë. Ju lutemi referohuni në këtë
              politikë për të kuptuar se si mbledhim dhe përdorim informacionin
              tuaj.
            </p>
          </li>
          <li>
            <div>Çmimet dhe Pagesat</div>
            <p>
              Çmimet për produktet e listuara në Okazion.al janë subjekt i
              ndryshimeve. Ne rezervojmë të drejtën për të ndryshuar çmimet pa
              njoftim të mëparshëm. Të gjitha transaksionet do të përpunohen në
              përputhje me politikat e pagesës që kemi vendosur.
            </p>
          </li>
          <li>
            <div>Pëgjegjësia</div>
            <p>
              Okazion.al nuk do të jetë përgjegjës për ndonjë dëmtim direkt,
              indirekt, rastësor, të veçantë, pasojë ose ndonjë dëm tjetër që
              rezulton nga përdorimi ose pamundësia e përdorimit të platformës
              sonë.
            </p>
          </li>
          <li>
            <div>Ndryshimet në Kushtet dhe Termat</div>
            <p>
              Ne rezervojmë të drejtën për të ndryshuar këto kushte dhe terma
              herë pas here. Do t'ju njoftojmë për çdo ndryshim duke postuar
              versionin e ri në këtë faqe. Përdorimi i vazhdueshëm i platformës
              pas publikimit të ndryshimeve do të konsiderohet si pranimi i
              tyre.
            </p>
          </li>
          <li>
            <div>Ligji i Zbatueshëm</div>
            <p>
              Këto kushte dhe terma do të rregullohen dhe interpretohen sipas
              ligjeve të [vendi juaj]. Çdo mosmarrëveshje që lind nga këto
              kushte do të zgjidhet në përputhje me ligjet e këtij vendi.
            </p>
          </li>
          <li>
            <div>Kontakti</div>
            <p>
              Për çdo pyetje në lidhje me këto kushte dhe terma, ju lutemi na
              kontaktoni në:
              <br />
              Email: info@okazion.al
              <br />
              Telefon: +355 69 785 0900
              <br />
              Adresa: Rruga Muhamet Gjollesha, Tirane 1001
              <br />
              NIPT: <strong>M42417031I</strong>
            </p>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default TermsAndConditions
