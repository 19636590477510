import React from 'react'
import { InputTextAreaStyles } from './InputTextAreaStyles.styles'

function InputTextArea({
  textAreaClass,
  textAreaDivClass,
  placeholder,
  onChange,
  value,
  error,
  name
}) {
  return (
    <InputTextAreaStyles
      className={`${textAreaDivClass} ${error ? 'is-invalid' : ''}`}
    >
      <textarea
        className={`form-control text-dark ${textAreaClass} ${
          error ? 'border-danger' : ''
        }`}
        name={name}
        id='exampleFormControlTextarea1'
        rows='3'
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        value={value}
      ></textarea>
      {error && <div className='invalid-feedback d-block'>{error}</div>}
    </InputTextAreaStyles>
  )
}

export default InputTextArea
