import styled from "styled-components";

export const JobPostContainer = styled.div``;

export const MainPostImg = styled.div`
  max-width: 100%;
  img {
    width: 100%;
  }
`;

export const PostInfoCard = styled.div`
  /* width: 650px; */
`;
