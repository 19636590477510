import React, { useEffect, useState } from 'react'
import Select from 'react-select'

function ReactSelectCustom({
  options,
  error,
  onChange,
  values,
  name,
  className,
  placeholder,
  mtCustom,
  brCustom,
  formatGroupLabel
}) {
  const [inputText, setInputText] = useState('')

  let defaultBorder = '#dee2e6'

  const handleInputChange = (inputValue) => {
    setInputText(inputValue)
  }

  return (
    <Select
      onInputChange={handleInputChange}
      options={options}
      isSearchable
      placeholder={placeholder}
      className={className}
      name={name}
      value={values}
      onChange={(val) => onChange(val)}
      loadingMessage={() => 'Po karikohen te dhenat...'}
      isClearable={true}
      formatGroupLabel={formatGroupLabel}
      noOptionsMessage={() => 'Nuk u gjeten info!'}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          // padding: '6px 10px',
          // fontWeight: 'bold',
          borderColor: error ? '#dc3545' : defaultBorder,
          borderRadius: brCustom ? '0.375rem' : `${brCustom}`,
          marginTop: mtCustom ? '0.5rem' : `${mtCustom}`,
          boxShadow: 'none',
          '&:focus-within': {
            borderColor: error ? '#dc3545' : '#a9b497',
            boxShadow: '0 0 0 0.25rem rgba(82, 104, 47, 0.25)'
          }
        })
      }}
    />
  )
}

export default ReactSelectCustom
