import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import SeoFaqs from './Seo/SeoFaqs'

function Faq() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  return (
    <>
      <SeoFaqs />
      <div className='container my-5'>
        <h2 className='text-center mt-5'>FAQ's</h2>
        <div className='accordion mt-5' id='accordionExample'>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingOne'>
              <button
                className='accordion-button'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseOne'
                aria-expanded='true'
                aria-controls='collapseOne'
              >
                Çfarë është Okazion.al?
              </button>
            </h2>
            <div
              id='collapseOne'
              className='accordion-collapse collapse show'
              aria-labelledby='headingOne'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Okazion.al është një platformë online e dedikuar për blerjen dhe
                shitjen e produkteve të ndryshme. Ajo ofron një hapësirë të
                sigurt për individë dhe biznese që dëshirojnë të shkëmbejnë
                artikuj të rinj ose të përdorur.
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingTwo'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseTwo'
                aria-expanded='false'
                aria-controls='collapseTwo'
              >
                Si mund të krijoj një llogari në Okazion.al?
              </button>
            </h2>
            <div
              id='collapseTwo'
              className='accordion-collapse collapse'
              aria-labelledby='headingTwo'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Për të krijuar një llogari, thjesht klikoni në butonin
                "Regjistrohu" në faqen kryesore dhe plotësoni informacionin e
                kërkuar, si emri, adresa e emailit dhe fjalëkalimi. Pas
                regjistrimit, do të merrni një email konfirmimi për të
                aktivizuar llogarinë tuaj.
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingThree'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseThree'
                aria-expanded='false'
                aria-controls='collapseThree'
              >
                A është e sigurt të blej ose shes në Okazion.al?
              </button>
            </h2>
            <div
              id='collapseThree'
              className='accordion-collapse collapse'
              aria-labelledby='headingThree'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Po, ne marrim masa të forta për të siguruar që transaksionet
                tuaja të jenë të sigurta. Përdoruesit tanë duhet të respektojnë
                rregullat e komunitetit dhe të përdorin praktikat më të mira për
                të mbrojtur veten gjatë transaksioneve.
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingFour'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseFour'
                aria-expanded='false'
                aria-controls='collapseFour'
              >
                Si mund të blej një produkt?
              </button>
            </h2>
            <div
              id='collapseFour'
              className='accordion-collapse collapse'
              aria-labelledby='headingFour'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Për të blerë një produkt, thjesht shikoni listat e produkteve,
                zgjidhni atë që ju intereson, dhe klikoni në butonin "Bli".
                Ndjekni hapat për të përfunduar pagesën dhe dërgimin.
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingFive'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseFive'
                aria-expanded='false'
                aria-controls='collapseFive'
              >
                Si mund të shes një produkt?
              </button>
            </h2>
            <div
              id='collapseFive'
              className='accordion-collapse collapse'
              aria-labelledby='headingFive'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Për të shitur një produkt, regjistrohuni në llogarinë tuaj dhe
                klikoni në butonin "Shkruaj një Produkt". Plotësoni
                informacionin e nevojshëm, përfshirë titullin, përshkrimin dhe
                çmimin. Shtoni foto të produkteve dhe publikoni shpalljen tuaj.
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingSix'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseSix'
                aria-expanded='false'
                aria-controls='collapseSix'
              >
                Si mund të kontaktoj një shitës ose blerës?
              </button>
            </h2>
            <div
              id='collapseSix'
              className='accordion-collapse collapse'
              aria-labelledby='headingSix'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Në çdo shpallje, do të gjeni mundësinë për të kontaktuar
                shitësin ose blerësin përmes mesazheve private në platformë.
                Sigurohuni që të bëni pyetje të qarta dhe të thella për
                produktin.
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingSeven'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseSeven'
                aria-expanded='false'
                aria-controls='collapseSeven'
              >
                A ka ndonjë tarifë për përdorimin e Okazion.al?
              </button>
            </h2>
            <div
              id='collapseSeven'
              className='accordion-collapse collapse'
              aria-labelledby='headingSeven'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Okazion.al mund të ketë tarifa të caktuara për shërbime të
                veçanta, si promovimi i shpalljeve. Informacioni në lidhje me
                tarifat do të jepet gjatë procesit të krijimit të shpalljes.
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingEight'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseEight'
                aria-expanded='false'
                aria-controls='collapseEight'
              >
                Çfarë duhet të bëj nëse kam probleme me një transaksion?
              </button>
            </h2>
            <div
              id='collapseEight'
              className='accordion-collapse collapse'
              aria-labelledby='headingEight'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Nëse keni probleme me një transaksion, ju lutemi kontaktoni
                ekipin tonë të mbështetjes për klientët në suport@okazion.al me
                detaje mbi problemin tuaj. Ne do të përpiqemi të zgjidhim
                çështjen sa më shpejt të jetë e mundur.
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingNine'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseNine'
                aria-expanded='false'
                aria-controls='collapseNine'
              >
                Si mund të ndryshoj informacionin tim të llogarisë?
              </button>
            </h2>
            <div
              id='collapseNine'
              className='accordion-collapse collapse'
              aria-labelledby='headingNine'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Për të ndryshuar informacionin tuaj të llogarisë, lidhuni në
                llogarinë tuaj dhe shikoni seksionin "Profili". Aty mund të
                përditësoni të dhënat tuaja personale, fjalëkalimin dhe
                preferencat e tjera.
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingTen'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseTen'
                aria-expanded='false'
                aria-controls='collapseTen'
              >
                Si mund të fshij llogarinë time?
              </button>
            </h2>
            <div
              id='collapseTen'
              className='accordion-collapse collapse'
              aria-labelledby='headingTen'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                Nëse dëshironi të fshini llogarinë tuaj, ju lutemi kontaktoni
                ekipin tonë të mbështetjes për klientët dhe ata do t'ju
                ndihmojnë me procesin.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faq
