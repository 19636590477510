import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import CustomSelectWithGroups from '../forms/CustomSelectWithGroups'
import { useSelector } from 'react-redux'
import CustomSelect from '../forms/CustomSelect'
import { useNavigate } from 'react-router'
import { useLazyGetNavigationDataQuery } from '../../feature/formData/formDataSliceApi'

function HomeSearch() {
  const [triggerNavbarData, { isLoading: loadData, data }] =
    useLazyGetNavigationDataQuery()
  const navigate = useNavigate()
  const { showSearchMobile } = useSelector((store) => store.home.menu_bar)
  const [searchParams, setSearchParams] = useState({
    search: '',
    category: '',
    city: ''
  })

  const cities = data?.data?.cities || []
  const categories = data?.data?.categories || []

  const searchPosts = () => {
    let data = searchParams

    setSearchParams({
      category: '',
      city: '',
      search: ''
    })

    navigate('/njoftime', {
      state: data
    })
  }

  useEffect(() => {
    triggerNavbarData()
  }, [])

  return (
    <>
      <div className='d-none d-xl-block card shadow-sm border-0 mt-4'>
        <div className='card-body p-4'>
          <form>
            <div className='d-flex align-items-end justify-content-center gap-2 flex-column flex-xl-row'>
              <div className='w-100'>
                <label
                  htmlFor='search'
                  className='form-label fw-semibold text-dark mb-1'
                >
                  Çfarë po kërkoni?
                </label>
                <input
                  className='form-control'
                  type='search'
                  placeholder='Fjale Kyce...'
                  aria-label='Search'
                  onChange={(e) =>
                    setSearchParams({ ...searchParams, search: e.target.value })
                  }
                  value={searchParams.search}
                />
              </div>
              <div className='w-100'>
                <label
                  htmlFor='category'
                  className='form-label fw-semibold text-dark mb-1'
                >
                  Në cilën kategori?
                </label>
                <CustomSelectWithGroups
                  options={loadData ? [] : categories}
                  value={searchParams.category}
                  placeholder={'Të Gjitha Kategoritë'}
                  className={'form-select'}
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      category: e.target.value
                    })
                  }
                />
              </div>
              <div className='w-100'>
                <label
                  htmlFor='city'
                  className='form-label fw-semibold text-dark mb-1'
                >
                  Në cilin Qytet?
                </label>
                <CustomSelect
                  options={loadData ? [] : cities}
                  placeholder={'Gjithë Shqipëria'}
                  value={searchParams.city}
                  className={'form-select'}
                  onChange={(e) =>
                    setSearchParams({ ...searchParams, city: e.target.value })
                  }
                />
              </div>
              <div>
                <button
                  className='btn btn-dark'
                  type='button'
                  onClick={() => searchPosts()}
                >
                  <div className='d-flex align-items-center justify-content-between gap-2'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} size='lg' />
                    <p className='mb-0'>Kerko</p>
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`${
          showSearchMobile ? 'd-block d-xl-none' : 'd-none d-xl-none'
        } card shadow-sm border-0 mt-4`}
      >
        <div className='card-body p-4'>
          <form>
            <div className='d-flex align-items-end justify-content-center gap-2 flex-column flex-xl-row'>
              <div className='w-100'>
                <label
                  htmlFor='search'
                  className='form-label fw-semibold text-dark mb-1'
                >
                  Çfarë po kërkoni?
                </label>
                <input
                  className='form-control'
                  type='search'
                  placeholder='Fjale Kyce...'
                  aria-label='Search'
                  onChange={(e) =>
                    setSearchParams({ ...searchParams, search: e.target.value })
                  }
                  value={searchParams.search}
                />
              </div>
              <div className='w-100'>
                <label
                  htmlFor='category'
                  className='form-label fw-semibold text-dark mb-1'
                >
                  Në cilën kategori?
                </label>
                <CustomSelectWithGroups
                  options={loadData ? [] : categories}
                  value={searchParams.category}
                  placeholder={'Të Gjitha Kategoritë'}
                  className={'form-select'}
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      category: e.target.value
                    })
                  }
                />
              </div>
              <div className='w-100'>
                <label
                  htmlFor='city'
                  className='form-label fw-semibold text-dark mb-1'
                >
                  Në cilin Qytet?
                </label>
                <CustomSelect
                  options={loadData ? [] : cities}
                  placeholder={'Gjithë Shqipëria'}
                  value={searchParams.city}
                  className={'form-select'}
                  onChange={(e) =>
                    setSearchParams({ ...searchParams, city: e.target.value })
                  }
                />
              </div>
              <div>
                <button
                  className='btn btn-dark'
                  type='button'
                  onClick={() => searchPosts()}
                >
                  <div className='d-flex align-items-center justify-content-between gap-2'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} size='lg' />
                    <p className='mb-0'>Kerko</p>
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default HomeSearch
