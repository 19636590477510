
import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithAuth from '../baseQuery/baseQueryWithAuth'

export const homeApi = createApi({
    reducerPath: 'homeApi',
    baseQuery: baseQueryWithAuth,
    endpoints: (builder) => ({
        getHomeData: builder.query({
            query: ({ page = null }) => {
                if (page !== null) {
                    return {
                        url: `/home?page=${page}`,
                        authRequired: false
                    }
                }

                return {
                    url: '/home',
                    authRequired: false
                }
            },
        })
    })
})



export const { useLazyGetHomeDataQuery } = homeApi