import React from 'react'
import { Helmet } from 'react-helmet'

function SeoContact() {
  return (
    <Helmet>
      <title data-n-head='true'>
        Na Kontaktoni | Okazion.al | Platformë për Blerje dhe Shitje Online
      </title>
      <meta
        name='description'
        content='Na kontaktoni përmes emailit info@okazion.al ose telefonit +355675862122 për ndihmë dhe informacion mbi platformën tonë.'
      />
      <meta property='og:url' content='https://okazion.al/contact-us' />
      <meta
        name='keywords'
        content='na kontaktoni, kontakt, Okazion, platformë online, blerje, shitje, Shqipëri, ndihmë, suport, informacion'
      />
      <meta property='og:locale' content='sq_AL' />
      <meta property='og:type' content='article' />
      <meta property='og:image' content='https://okazion.al/images/logo.png' />
      <meta
        property='og:title'
        content='Na Kontaktoni | Okazion.al | Platformë për Blerje dhe Shitje Online'
      />
      <meta
        property='og:description'
        content='Kontaktoni ekipin tonë për çdo pyetje apo mbështetje në përdorimin e platformës Okazion.al.'
      ></meta>
    </Helmet>
  )
}

export default SeoContact
