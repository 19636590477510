import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import bannerSlider from '../assets/marketing/website-slider-baner.jpg'
import bannerSide from '../assets/marketing/website-side-baner.jpeg'
import singlePostBanner from '../assets/marketing/single-post-baner.jpg'
import sideSingleBanner from '../assets/marketing/single-post-side-baner.jpg'
import postsListBannerSide from '../assets/marketing/post-list-baner.jpg'
import SeoMarketing from './Seo/SeoMarketing'
import { useLocation } from 'react-router'
import MarketingItem from '../components/common/MarketingItem'

function Marketing() {
  const location = useLocation()

  const marketing = [
    {
      name: 'Slider Banner Kryefaqe',
      desc: 'Banner në Sliderin e kryefaqes së Okazion.al',
      price: '200',
      image: bannerSlider,
      features: [
        {
          text: 'Kohezgjatja 30 ditë'
        },
        {
          text: 'Design i Personalizuar'
        }
      ]
    },
    {
      name: 'Banner Anësor Në Listime',
      desc: 'Banner në faqen e resultateve dhe faqen e listimeve të kategorive të ndryshme',
      price: '120',
      image: postsListBannerSide,
      features: [
        {
          text: 'Kohezgjatja 30 ditë'
        },
        {
          text: 'Design i Personalizuar'
        }
      ]
    },
    {
      name: 'Banner Anësor Në Listim',
      desc: 'Banner në faqen e një listimi poshtë informacioneve të njoftimit',
      price: '150',
      image: sideSingleBanner,
      features: [
        {
          text: 'Kohezgjatja 30 ditë'
        },
        {
          text: 'Design i Personalizuar'
        }
      ]
    },
    {
      name: 'Banner Qëndror Në Listim',
      desc: 'Banner në faqen e një listimi posshte informacioneve të njoftimit',
      price: '190',
      image: singlePostBanner,
      features: [
        {
          text: 'Kohezgjatja 30 ditë'
        },
        {
          text: 'Design i Personalizuar'
        }
      ]
    },
    {
      name: 'Banner Anësor Kryefaqe',
      desc: 'Banner në kryefaqen e Okazion.al',
      price: '150',
      image: bannerSide,
      features: [
        {
          text: 'Kohezgjatja 30 ditë'
        },
        {
          text: 'Design i Personalizuar'
        }
      ]
    },
    {
      name: 'Paketa Të Personalizuara',
      desc: 'Për paketa të personalizuara sipas kërkesave të bisnesit.',
      price: 'Kontakto',
      image: null,
      features: [
        {
          text: 'Marketing Insagram'
        },
        {
          text: 'Marketing Facebook'
        },
        {
          text: 'Marketing Website'
        }
      ]
    }
  ]

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  return (
    <>
      <SeoMarketing />
      <div className='container my-5'>
        <div className='d-flex align-items-center justify-content-center flex-column'>
          <h3 className='mt-5 text-center'>Hapsira Reklamuese ne Okazion.al</h3>
          <p className='text-center'>
            Duke personalizuar paketat tuaja të marketingut, do të jeni në
            gjendje të rrisni më tej dukshmërinë e profilit tuaj ose të
            shërbimeve që ofroni.
          </p>
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <a
              href='tel:+355697850900'
              className='btn btn-light border-dark-subtle'
            >
              <FontAwesomeIcon icon={faPhone} className='text-primary me-2' />
              Na Telefono
            </a>
            <a
              href='mailto:marketing@okazion.al'
              className='btn btn-light border-dark-subtle'
            >
              <FontAwesomeIcon icon={faPhone} className='text-primary me-2' />
              Dergo Email
            </a>
          </div>
        </div>
        <div className='row mt-4'>
          {marketing.map((item, index) => (
            <div className='col-12 col-md-6 col-xl-3 mt-4' key={index}>
              <MarketingItem item={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Marketing
