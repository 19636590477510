import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Spinner from '../../components/common/Spinner'
import { attemptLoginSocial } from '../../feature/socialAuth/socialAuthActions'
import SmallSpinner from '../../components/common/SmallSpinner'

function SocialLoginRedirect() {
  const location = useLocation()
  const { loading } = useSelector(store => store.social.socialLogin)
  const navigate = useNavigate()


  useEffect(() => {
    if (location.search) {
      const token = location.search.split('?token=')[1]

      if (token) {
        attemptLoginSocial(token, navigate)
      }

    }
  }, [location])


  if (localStorage.getItem('user') !== null) {
    return <Navigate to={'/'} />
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <div className='container d-flex align-items-center justify-content-center'>
      <p>Ju lutem prisni...</p>
      <SmallSpinner />
    </div>
  )
}

export default SocialLoginRedirect