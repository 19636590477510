import React from 'react'
import {
  AcceptTerms,
  AuthLogo,
  LineText,
  NotRegistered,
  RegisterBody,
  RegisterCard,
  RegisterContainer,
  SelectGender
} from './Register.styles'
import logo from '../../assets/icons/icon-logo.svg'
import googleIcon from '../../assets/icons/google-icon.svg'
import InputTextGroup from '../../components/forms/InputTextGroup'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import InputRadioGroup from '../../components/forms/InputRadioGroup'
import Button from '../../components/common/Button'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Spinner from '../../components/common/Spinner'
import { getGoogleUrl } from '../../helpers/getGoogleUrl'
import { useRegisterUserMutation } from '../../feature/auth/authSliceApi'
import {
  generalAlertError,
  generalAlertSuccessToast
} from '../../helpers/alert'

const registerAuth = yup.object({
  name: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) =>
      gender !== 'B'
        ? schema
            .required('Ju lutem vendosni emrin!')
            .matches(/^[a-zA-Z ]+$/, 'Ju lutem perdorni vetem shkronja!')
        : schema
    ),
  surname: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) =>
      gender !== 'B'
        ? schema
            .required('Ju lutem vendosni mbiemrin!')
            .matches(/^[a-zA-Z ]+$/, 'Ju lutem perdorni vetem shkronja!')
        : schema
    ),
  business_name: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'B',
      then: () =>
        yup.string().required('Ju lutem vendosni emrin e bisnesit tuaj!')
    }),
  gender: yup.string().required('Zgjidhni gjinine!'),
  phone: yup
    .string()
    .required('Vendosni nje numer kontakti!')
    .matches(/^\d+$/)
    .typeError('Vendosni nje numer kontakti!'),
  email: yup
    .string()
    .required('Fusha eshte e detyrueshme. Vendosni emailin tuaj!')
    .email('Vendos nje email te sakte! Hiqni hapsira!'),
  password: yup
    .string()
    .required('Vendosni fjalekalimin!')
    .min(8, 'Minimumi i karaktereve duhet te jete 8!'),
  password_confirmation: yup
    .string()
    .required('Vendosni fjalekalimin')
    .oneOf([yup.ref('password')], 'Fjalekalimet nuk jane njelloj!')
})

function Register() {
  const navigate = useNavigate()
  const [registerUser, { isLoading }] = useRegisterUserMutation()
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit
  } = useForm({
    mode: 'all',
    resolver: yupResolver(registerAuth),
    defaultValues: {
      name: '',
      surname: '',
      business_name: '',
      gender: '',
      phone: '',
      email: '',
      password: '',
      password_confirmation: ''
    }
  })

  const formData = watch()

  const updateFormData = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
    // trigger(keys);
  }

  const submitRegister = async () => {
    const res = await registerUser({ data: formData })

    if (res.error) {
      generalAlertError('Kjo adrese emaili eshte e regjistruar!')
    } else {
      generalAlertSuccessToast(
        'U regjistruat me sukses! Ju lutem verifikoni emailin tuaj!'
      )
      navigate('/')
    }
  }

  if (localStorage.getItem('user')) {
    return (
      <Navigate
        to={`/profili/${JSON.parse(localStorage.getItem('user')).username}`}
      />
    )
  }

  return (
    <div className='px-md-5'>
      <div className='container row mx-auto'>
        <div className='col-12 col-lg-8 offset-lg-2 my-5'>
          <RegisterCard className='card py-md-3 px-0 px-xl-5'>
            <RegisterBody className='card-body d-flex justify-content-center align-items-center px-0 px-md-4 flex-column'>
              <AuthLogo>
                <img src={logo} alt='' />
              </AuthLogo>
              <h3 className='mt-2 w-75 text-center'>
                Krijoni Llogarine Tuaj ne Okazion.al
              </h3>
              {/* <a className='text-decoration-none border d-flex justify-content-center align-items-center mt-5 py-2 gap-2 w-75 mx-auto bg-transparent rounded-2' href={getGoogleUrl()}>
                                <img src={googleIcon} alt="" />
                                <p className="m-0 text-dark fw-semibold">Regjistrohu Me Google</p>
                            </a>
                            <LineText className='d-flex justify-content-center align-items-center mt-3 w-75 mx-auto position-relative'>
                                <p className='fw-bold fs-6 m-0 me-3 ms-3 '>ose</p>
                            </LineText> */}
              <form
                className='w-100 mx-auto mt-3'
                onSubmit={handleSubmit(submitRegister)}
              >
                {errors.gender?.message && (
                  <div className='w-75 mx-auto text-center invalid-feedback d-block'>
                    {errors.gender?.message}
                  </div>
                )}
                <p className='w-75 m-0 mx-auto text-center mb-2 fw-bold '>
                  Gjinia
                </p>
                <SelectGender
                  className={`w-75 mx-auto d-flex align-items-center justify-content-center gap-2 gap-lg-3 mb-3 border p-1 rounded ${
                    errors.gender?.message ? 'text-danger' : ''
                  }`}
                >
                  <InputRadioGroup
                    name='gender'
                    value='F'
                    labelRadio='Femer'
                    onChange={(e) =>
                      updateFormData({
                        gender: e.target.value
                      })
                    }
                  />
                  <InputRadioGroup
                    name='gender'
                    value='M'
                    labelRadio='Mashkull'
                    onChange={(e) =>
                      updateFormData({
                        gender: e.target.value
                      })
                    }
                  />
                  <InputRadioGroup
                    name='gender'
                    value='B'
                    labelRadio='Biznes/Kompani'
                    onChange={(e) =>
                      updateFormData({
                        gender: e.target.value
                      })
                    }
                  />
                </SelectGender>

                {formData.gender === '' || formData.gender !== 'B' ? (
                  <>
                    <InputTextGroup
                      placeholder={'Emri'}
                      error={errors.name?.message}
                      name='name'
                      value={formData.name}
                      type='text'
                      id='name'
                      inputGroupClass={'w-75 w-xs-100  mx-auto mb-3 '}
                      inputClass={'py-2'}
                      onChange={(e) =>
                        updateFormData({
                          name: e.target.value
                        })
                      }
                    />
                    <InputTextGroup
                      placeholder={'Mbiemri'}
                      name='surname'
                      value={formData.surname}
                      error={errors.surname?.message}
                      type='text'
                      id='surname'
                      inputGroupClass={'w-75 mx-auto mb-3 '}
                      inputClass={'py-2'}
                      onChange={(e) =>
                        updateFormData({
                          surname: e.target.value
                        })
                      }
                    />
                  </>
                ) : (
                  ''
                )}
                {formData.gender === 'B' && (
                  <InputTextGroup
                    placeholder={'Emri i Biznesit'}
                    name='business_name'
                    value={formData.business_name}
                    error={errors.business_name?.message}
                    type='text'
                    id='business_name'
                    inputGroupClass={'w-75 mx-auto mb-3 '}
                    inputClass={'py-2'}
                    onChange={(e) =>
                      updateFormData({
                        business_name: e.target.value
                      })
                    }
                  />
                )}
                <InputTextGroup
                  placeholder={'Email'}
                  name='email'
                  value={formData.email}
                  error={errors.email?.message}
                  type='email'
                  id='email'
                  inputGroupClass={'w-75 mx-auto mb-3 '}
                  inputClass={'py-2'}
                  onChange={(e) =>
                    updateFormData({
                      email: e.target.value
                    })
                  }
                />
                <InputTextGroup
                  placeholder={'Numer Telefoni'}
                  name='phone'
                  value={formData.phone}
                  error={errors.phone?.message}
                  type='text'
                  id='phone'
                  inputGroupClass={'w-75 mx-auto mb-3 '}
                  inputClass={'py-2'}
                  onChange={(e) =>
                    updateFormData({
                      phone: e.target.value
                    })
                  }
                />
                <InputTextGroup
                  placeholder={'Fjalekalimi'}
                  name='password'
                  value={formData.password}
                  error={errors.password?.message}
                  type='password'
                  id='password'
                  inputGroupClass={'w-75 mx-auto mb-3 '}
                  inputClass={'py-2'}
                  onChange={(e) =>
                    updateFormData({
                      password: e.target.value
                    })
                  }
                />
                <InputTextGroup
                  placeholder={'Riperserit Fjalekalimin'}
                  name='password_confirmation'
                  value={formData.password_confirmation}
                  error={errors.password_confirmation?.message}
                  type='password'
                  id='password_confirmation'
                  inputGroupClass={'w-75 mx-auto mb-3 '}
                  inputClass={'py-2'}
                  onChange={(e) =>
                    updateFormData({
                      password_confirmation: e.target.value
                    })
                  }
                />
                <AcceptTerms className='w-75 mx-auto'>
                  <p>
                    Duke klikuar{' '}
                    <span className='fw-bold text-dark'> “Regjistrohu” </span>
                    bini dakort me{' '}
                    <Link
                      to='/kushtet-dhe-termat'
                      className='fw-bold text-dark'
                    >
                      Termat dhe Kushtet
                    </Link>
                  </p>
                </AcceptTerms>
                {isLoading ? (
                  <button
                    type={'button'}
                    className={`mx-auto py-2 btn rounded-2 w-75 mx-auto d-table btn-primary d-flex justify-content-center align-items-center`}
                    disabled
                  >
                    <div
                      className='spinner-border text-light spinner-border-sm'
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                    <div className='ms-2'>Duke u regjistruar...</div>
                  </button>
                ) : (
                  <Button
                    type={'submit'}
                    buttonClass={'w-75 d-table mx-auto btn-primary'}
                    buttonText='Regjistrohu'
                  />
                )}
              </form>
              <NotRegistered className='w-75 mx-auto'>
                <p className=' mt-3 mb-5'>
                  Keni Nje llogari ne Okazion.al?{' '}
                  <Link to='/hyr' className='fw-light text-primary'>
                    Identifikohuni Ketu
                  </Link>
                </p>
              </NotRegistered>
            </RegisterBody>
          </RegisterCard>
        </div>
      </div>
    </div>
  )
}

export default Register
