import React from 'react'
import carImg from '../assets/images/user-cover.png'
import Pagination from '../components/common/Pagination'
import SeoBusinesses from './Seo/SeoBusinesses'

function Businesses() {
  return (
    <>
      <SeoBusinesses />
      <div className='container my-5'>
        <h2>Bizneset Aktive Ne Okazion.al</h2>
        <section>
          <div className='row my-4'>
            <div className='col-12 col-lg-6 col-xxl-4 mt-3'>
              <div className='card'>
                <div className='card-body d-flex justify-content-start align-items-center gap-2 gap-md-3 '>
                  <div>
                    <img src={carImg} alt='' width={130} height={130} />
                  </div>
                  <div className='p-2 p-xl-0'>
                    <div>
                      <h3 className='m-0 fw-medium text-dark'>Auto Mema</h3>
                      <p className='fw-light text-dark'>
                        Anetar qe nga 13 Maj, 2020
                      </p>
                    </div>
                    <div>
                      <p className='m-0 fw-medium text-dark'>
                        Kategoria: Makina
                      </p>
                      <p className='m-0 fw-normal text-dark'>
                        325 Postime Aktive ne Faqe
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 col-xxl-4 mt-3'>
              <div className='card'>
                <div className='card-body d-flex justify-content-start align-items-center gap-2 gap-md-3'>
                  <div>
                    <img src={carImg} alt='' width={130} height={130} />
                  </div>
                  <div className='p-2 p-xl-0'>
                    <div>
                      <h3 className='m-0 fw-medium text-dark'>Auto Mema</h3>
                      <p className='fw-light text-dark'>
                        Anetar qe nga 13 Maj, 2020
                      </p>
                    </div>
                    <div>
                      <p className='m-0 fw-medium text-dark'>
                        Kategoria: Makina
                      </p>
                      <p className='m-0 fw-normal text-dark'>
                        325 Postime Aktive ne Faqe
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-lg-6 col-xxl-4 mt-3'>
              <div className='card'>
                <div className='card-body d-flex justify-content-start align-items-center gap-2 gap-md-3 '>
                  <div>
                    <img src={carImg} alt='' width={130} height={130} />
                  </div>
                  <div className='p-2 p-xl-0'>
                    <div>
                      <h3 className='m-0 fw-medium text-dark'>Auto Mema</h3>
                      <p className='fw-light text-dark'>
                        Anetar qe nga 13 Maj, 2020
                      </p>
                    </div>
                    <div>
                      <p className='m-0 fw-medium text-dark'>
                        Kategoria: Makina
                      </p>
                      <p className='m-0 fw-normal text-dark'>
                        325 Postime Aktive ne Faqe
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <Pagination />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Businesses
