import React from 'react'
import ReactSelectCustom from '../../../components/forms/ReactSelectCustom'
import {
  jobPositions,
  jobPostTypes,
  jobWorkingHours
} from '../../../Utilities/constants'

function JobPostFormComponent({ updateFormData, formData, errors }) {
  return (
    <>
      <ReactSelectCustom
        placeholder={'Pozicioni i Punes'}
        name='job_position'
        options={jobPositions.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={jobPositions
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.job_position)}
        onChange={(val) =>
          updateFormData({
            job_position: val ? val.value : ''
          })
        }
        error={errors && errors.job_position?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.job_position?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Tipi i Punes'}
        name='job_post_type_id'
        options={jobPostTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={jobPostTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.job_post_type_id)}
        onChange={(val) =>
          updateFormData({
            job_post_type_id: val ? val.value : ''
          })
        }
        error={errors && errors.job_post_type_id?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.job_post_type_id?.message}
      </div>
      <ReactSelectCustom
        placeholder={'Orari i Punes'}
        name='job_working_hours_id'
        options={jobWorkingHours.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={jobWorkingHours
          .map((c) => ({ value: c.id, label: c.name }))
          .find((el) => el.value == formData.job_working_hours_id)}
        onChange={(val) =>
          updateFormData({
            job_working_hours_id: val ? val.value : ''
          })
        }
        error={errors && errors.job_working_hours_id?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.job_working_hours_id?.message}
      </div>
    </>
  )
}

export default JobPostFormComponent
