import * as yup from "yup";

const doubleRegex = /^\d+(\.\d+)?$/;
const phoneRegExp = /^\+?\d+$/;
const numberRegex = /^\d+$/;

export const editPostValidation = yup.object({
  title: yup.string().required("Vendosni titullin!"),
  description: yup.string().required("Vendosni pershkrimin!"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Formati i numrit nuk eshte i sakte!")
    .required("Vendosni numrin e telefonit!"),
  email: yup
    .string()
    .required("Vendosni titullin")
    .email("Vendosni nje adrese emaili te sakte!"),
  cover_image: yup
    .object({
      filename: yup.string().nullable(),
      content: yup.string().nullable(),
    })
    .nullable(),
  city_id: yup
    .string()
    .required("Ju lutem zgjidhni qytetin!")
    .matches(numberRegex, "Ju lutem zgjidhni qytetin!"),
  price_currency: yup
    .string()
    .when("price_on_request_title", ([price_on_request_title], schema) =>
      price_on_request_title === ""
        ? schema.required("Zgjidhni valuten")
        : schema
    ),
  price: yup
    .string()
    .when("price_on_request_title", ([price_on_request_title], schema) =>
      price_on_request_title === ""
        ? schema.required("Zgjidhni valuten")
        : schema
    ),
  price_on_request_title: yup.string().nullable(),
  vehicle_post: yup.object({
    vehicle_post_type: yup.string().nullable(),
    vehicle_type_id: yup.string().nullable().nullable(),
    auto_brand_id: yup.string().nullable(),
    auto_model_id: yup.string().nullable(),
    moto_brand_id: yup.string().nullable(),
    moto_model_id: yup.string().nullable(),
    vehicle_status: yup.string().nullable(),
    transmission_type: yup.string().nullable(),
    engine_id: yup.string().nullable(),
    vehicle_year: yup.string().nullable(),
    km_done: yup.string().nullable(),
    color_id: yup.string().nullable(),
    plate_types: yup.string().nullable(),
  }),
  job_post: yup.object({
    job_position: yup.string().nullable(),
    job_post_type_id: yup.string().nullable(),
    job_working_hours_id: yup.string().nullable(),
  }),
  real_estate_post: yup.object({
    square_meters: yup.string().nullable(),
    nr_bedrooms: yup.string().nullable(),
    nr_bathrooms: yup.string().nullable(),
    has_garage: yup.string().nullable(),
    nr_balconies: yup.string().nullable(),
    has_documentation: yup.string().nullable(),
    floor_nr: yup.string().nullable(),
    real_estate_type_id: yup.string().nullable(),
  }),
  gallery: yup.array().of(
    yup
      .object({
        filename: yup.string().nullable(),
        content: yup.string().nullable(),
      })
      .nullable()
  ),
});
