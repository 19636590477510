import React, { useEffect, useState } from 'react'
import { UserButtons } from './UserProfile.styles'
import Spinner from '../../components/common/Spinner'
import PostsTab from './components/PostsTab'
import UpdateProfileTab from './components/UpdateProfileTab'
import AccountBalanceTab from './components/AccountBalanceTab'
import MessagesTab from './components/MessagesTab'
import { useNavigate } from 'react-router-dom'
import MarketingTab from './components/MarketingTab'
import { useLazyGetAuthInfoQuery } from '../../feature/auth/authSliceApi'

function UserProfile() {
  const [currentMenu, setCurrentMenu] = useState('njoftime')
  const [triggerGetAuthInfo, { data, isLoading }] = useLazyGetAuthInfoQuery()

  const user = data?.data?.user || null
  const posts = data?.data?.posts || []
  const last_page = data?.data?.posts?.last_page || 0
  const page = data?.data?.posts?.current_page || 0

  const navigate = useNavigate()

  const switchPages = (val) => {
    setCurrentMenu(val)
  }

  useEffect(() => {
    triggerGetAuthInfo({ page: null })
  }, [])

  if (isLoading || !data) {
    return <Spinner />
  }

  return (
    <div className='px-2 px-md-5 my-5'>
      <UserButtons className='d-flex justify-align-content-start align-items-center flex-wrap gap-2'>
        <button
          className={`btn ${
            currentMenu === 'njoftime' ? 'btn-primary' : 'btn-outline-primary'
          } py-2 fw-semibold`}
          onClick={() => switchPages('njoftime')}
        >
          Njoftimet e Mia
        </button>
        <button
          className={`btn ${
            currentMenu === 'mesazhe' ? 'btn-primary' : 'btn-outline-primary'
          } py-2 fw-semibold`}
          onClick={() => switchPages('mesazhe')}
        >
          Mesazhet
        </button>
        <button
          className={`btn ${
            currentMenu === 'llogaria' ? 'btn-primary' : 'btn-outline-primary'
          } py-2 fw-semibold`}
          onClick={() => switchPages('llogaria')}
        >
          Llogaria
        </button>
        <button
          className={`btn ${
            currentMenu === 'marketing' ? 'btn-primary' : 'btn-outline-primary'
          } py-2 fw-semibold`}
          onClick={() => switchPages('marketing')}
        >
          Marketing
        </button>
        <button
          className={`btn ${
            currentMenu === 'profili' ? 'btn-primary' : 'btn-outline-primary'
          } py-2 fw-semibold`}
          onClick={() => switchPages('profili')}
        >
          Profili
        </button>
      </UserButtons>

      {currentMenu === 'njoftime' && (
        <PostsTab
          user={user}
          posts={posts}
          page={page}
          last_page={last_page}
          navigate={navigate}
          setCurrentMenu={setCurrentMenu}
          triggerGetAuthInfo={triggerGetAuthInfo}
        />
      )}

      {currentMenu === 'mesazhe' && <MessagesTab user={user} />}

      {currentMenu === 'marketing' && <MarketingTab user={user} />}

      {currentMenu === 'llogaria' && <AccountBalanceTab user={user} />}

      {currentMenu === 'profili' && (
        <UpdateProfileTab
          user={user}
          currTab={currentMenu}
          collectData={triggerGetAuthInfo}
        />
      )}
    </div>
  )
}

export default UserProfile
