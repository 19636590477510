import React from 'react'
import { Helmet } from 'react-helmet'

function SeoResult() {
  return (
    <Helmet>
      <title data-n-head='true'>
        Okazion.al | Bli dhe Shit Online | Platformë e Sigurt dhe e Besueshme
      </title>
      <meta
        name='description'
        content=' Okazion.al është një platformë e dedikuar për blerjen dhe shitjen e produkteve të ndryshme, që ofron një hapësirë të sigurt dhe të lehtë për individë dhe biznese që duan të shkëmbejnë artikuj. Ne besojmë se çdo produkt ka vlerë dhe ne jemi këtu për ta ndihmuar ju të gjeni blerjet më të mira ose të shisni artikuj që nuk i nevojiten më. '
      />
      <meta
        name='apple-mobile-web-app-title'
        content='Okazion.al | Bli dhe Shit Online | Platformë e Sigurt dhe e Besueshme'
      />
      <meta
        name='keywords'
        content='Okazion, bli, shit, treg online, platformë, produkte, shërbime, Shqipëri, siguri, cilësi, blerje, shitje, ofertë'
      />
      <meta name='robots' content='index, follow' />
      <meta
        name='googlebot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta
        name='bingbot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta name='twitter:card' content='https://okazion.al/images/logo.png' />
      <meta
        name='twitter:description'
        content='Okazion.al është një treg online për blerje dhe shitje me siguri dhe cilësi.'
      />
      <meta
        name='twitter:title'
        content='Okazion.al | Bli dhe Shit Online | Platformë e Sigurt dhe e Besueshme'
      />
      <meta name='twitter:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:locale' content='sq_AL' />
      <meta property='og:type' content='website' />
      <meta
        property='og:title'
        content='Okazion.al | Bli dhe Shit Online | Platformë e Sigurt dhe e Besueshme'
      />
      <meta
        property='og:description'
        content='Për një eksperiencë të lehtë dhe të sigurt për blerje dhe shitje online, zgjidh Okazion.al.'
      />
      <meta property='og:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:url' content='https://okazion.al/' />
      <meta
        property='og:image:secure_url'
        content='https://okazion.al/images/logo.png'
      />
      <meta property='og:image:type' content='image/png' />
      <meta property='og:image:alt' content='Okazion.al Logo'></meta>
    </Helmet>
  )
}

export default SeoResult
