import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_URL } from "../../config/urls";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const baseUrl = BACKEND_API_URL;

const isTokenExpired = (exp) => Date.now() >= exp * 1000;

const baseQueryWithAuth = async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({ baseUrl });

    const isAuthRequired = args?.authRequired;

    if (isAuthRequired) {
        let token = localStorage.getItem("jwtToken");

        if (!token) {
            console.error("No token found");
            localStorage.clear();
            window.location.href = '/'
            throw new Error("Authentication required but no token found");
        }

        try {
            const decoded = jwtDecode(token);

            if (isTokenExpired(decoded.exp)) {
                console.warn("Access token expired, attempting to refresh");

                const refreshToken = localStorage.getItem("jwtRefreshToken");
                if (!refreshToken) {
                    console.error("No refresh token found");
                    localStorage.clear();
                    window.location.href = '/'
                    throw new Error("Refresh token not found");
                }

                const decodedRefreshToken = jwtDecode(refreshToken);
                if (isTokenExpired(decodedRefreshToken.exp)) {
                    console.error("Refresh token expired");
                    localStorage.clear();
                    window.location.href = '/'
                    throw new Error("Refresh token expired");
                }

                const response = await axios.post(
                    `${BACKEND_API_URL}/refresh/token`,
                    {},
                    {
                        headers: {
                            Refresh: localStorage.getItem('jwtRefreshToken'),
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.status !== 200) {
                    window.location.href = '/'
                }

                const { access_token, refresh_token, user } = response.data
                localStorage.setItem("jwtToken", access_token);
                localStorage.setItem("jwtRefreshToken", refresh_token);
                localStorage.setItem("user", JSON.stringify(user));

                token = access_token;
            }
            args.headers = {
                ...args.headers,
                Authorization: `${token}`,
            };
        } catch (error) {
            console.error("Error validating or refreshing token:", error.message);
            localStorage.clear();
            window.location.href = '/'
            throw error;
        }
    }

    return baseQuery(args, api, extraOptions);
};

export default baseQueryWithAuth;
