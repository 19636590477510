import React from 'react'
import logo from '../assets/images/logo.png'
import facebook from '../assets/images/facebook-white.png'
import insta from '../assets/images/insta-white.png'
import youtube from '../assets/images/youtube-white.png'
import tiktok from '../assets/images/tiktok-white.png'

import { FooterSection } from './Footer.styles'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <FooterSection className='bg-dark pt-3 pt-lg-5'>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 col-xl-4'>
            <div className='d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start'>
              <img src={logo} alt='' />
              <p className='text-center text-lg-start text-white custom-text mt-2 mt-lg-0'>
                Okazion.al është një platformë online për publikimin e
                njoftimeve të shitblerjes së pasurive të patundshme,
                automjeteve, pajisjeve dhe vendeve të punës, duke ofruar një
                hapësirë ideale për ata që kërkojnë ose ofrojnë mundësi tregtie
                dhe punësimi.
              </p>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-2 d-flex flex-column justify-content-center align-items-center justify-content-md-start align-items-xl-start mt-5 mt-lg-3'>
            <h5 className='text-white fw-medium mb-3'>Rreth Kompanise</h5>
            <Link to='/rreth-nesh' className='text-decoration-none text-white'>
              <h6>Kush Jemi</h6>
            </Link>
            <a href='' className='text-decoration-none text-white'>
              <h6>Bizneset</h6>
            </a>
            <Link
              to='/politikat-e-privatesise'
              className='text-decoration-none text-white'
            >
              <h6>Politikat e Privatesise</h6>
            </Link>
            <Link
              to='/kushtet-dhe-termat'
              className='text-decoration-none text-white'
            >
              <h6>Termat dhe Kushtet</h6>
            </Link>
            <Link to='/faq' className='text-decoration-none text-white'>
              <h6>FAQ</h6>
            </Link>
          </div>
          <div className='col-12 col-md-6 col-xl-2 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start mt-5 mt-lg-3'>
            <h5 className='text-white fw-medium mb-3'>Sherbimi Klientit</h5>
            <Link to='/marketing' className='text-decoration-none text-white'>
              <h6>Marketing</h6>
            </Link>
            <Link to='/kontakt' className='text-decoration-none text-white'>
              <h6>Kontakt</h6>
            </Link>
          </div>
          <div className='col-12 col-md-6 col-xl-2 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start mt-5 mt-lg-3'>
            <h5 className='text-white fw-medium mb-3'>Kontakt</h5>
            <h6 className='text-white'>
              <a
                className='text-white text-decoration-none'
                href='tel:+355697850900'
              >
                +355 69 785 0900
              </a>
            </h6>
            <h6 className='text-white'>
              <a
                className='text-white text-decoration-none'
                href='mailto:info@okazion.al'
              >
                info@okazion.al
              </a>
            </h6>
            <h6 className='text-white'>
              <a
                className='text-white text-decoration-none'
                href='mailto:marketing@okazion.al'
              >
                marketing@okazion.al
              </a>
            </h6>
          </div>
          <div className='col-12 col-md-6 col-xl-2 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start mt-5 mt-lg-3'>
            <h5 className='text-white fw-medium mb-3'>Na Ndiqni</h5>
            <div className='d-flex justify-content-center align-items-center gap-3 mt-3'>
              <a
                href='https://www.facebook.com/okazion.all'
                target='_blank'
                className='text-decoration-none text-white'
              >
                <img src={facebook} alt='Facebook Okazion' />
              </a>
              <a
                target='_blank'
                href='https://www.instagram.com/okazion/'
                className='text-decoration-none text-white'
              >
                <img src={insta} alt='Instagram Okazion' />
              </a>
              <a
                href='https://www.youtube.com/@okazion_al'
                className='text-decoration-none text-white'
                target='_blank'
              >
                <img src={youtube} alt='Youtube Okazion' />
              </a>
              <a
                href='https://www.tiktok.com/@okazion.al'
                className='text-decoration-none text-white'
                target='_blank'
              >
                <img src={tiktok} alt='TikTok Okazion' />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='text-center p-2 text-white'>
        ©{new Date().getFullYear()} Te gjitha te drejtat e rezervuara
        <a className='text-decoration-none text-primary fw-bold' href='/'>
          {' '}
          Okazion Shpk
        </a>
      </div>
    </FooterSection>
  )
}

export default Footer
