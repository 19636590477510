import React, { useEffect, useState } from 'react'
import filter from '../assets/icons/filter.svg'
import InputTextGroup from '../components/forms/InputTextGroup'
import Button from '../components/common/Button'
import ReactSelectCustom from '../components/forms/ReactSelectCustom'
import Pagination from '../components/common/Pagination.jsx'
import SmallSpinner from '../components/common/SmallSpinner.jsx'
import PostCard from '../components/common/PostCard.jsx'
import Spinner from '../components/common/Spinner.jsx'
import { Link, useLocation } from 'react-router-dom'
import { BACKEND_STORAGE_URL } from '../config/urls.js'
import PinPostCard from '../components/common/PinPostCard.jsx'
import SeoResult from './Seo/SeoResult.jsx'
import { useLazyGetAllPostsQuery } from '../feature/posts/postSliceApi.js'
import { useLazyGetPostsFormDataQuery } from '../feature/formData/formDataSliceApi.js'

function Search() {
  const [triggerGetAllPosts, { data, error: RTKError, isLoading }] =
    useLazyGetAllPostsQuery()

  const posts = data?.data?.posts || null
  const posts_count = data?.data?.posts_count || 0
  const page = data?.data?.posts?.current_page || 0
  const last_page = data?.data?.posts?.last_page || 0
  const banners = data?.data?.banners || []
  const pinPosts = data?.data?.pinPosts || []

  const location = useLocation()
  const [searchParams, setSearchParams] = useState({
    search: '',
    category: '',
    city: '',
    price_from: '',
    price_to: ''
  })

  const [triggerPostFormData, { data: formDataData, isLoading: formLoading }] =
    useLazyGetPostsFormDataQuery()

  const cities = formDataData?.data.cities || []
  const categories = formDataData?.data.categories || []

  const handleChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value
    })
  }

  const handleCityChange = (val) => {
    if (val) {
      setSearchParams({
        ...searchParams,
        city: val.value
      })
    } else {
      setSearchParams({
        ...searchParams,
        city: ''
      })
    }
  }

  const searchPosts = () => {
    triggerGetAllPosts({
      page: null,
      searchParams
    })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  useEffect(() => {
    if (location.state !== null) {
      triggerPostFormData()

      triggerGetAllPosts({
        page: null,
        searchParams: {
          search: location.state.search,
          city: location.state.city,
          category: location.state.category
        }
      })

      setSearchParams({
        ...searchParams,
        search: location.state.search,
        city: location.state.city,
        category: location.state.category
      })
    } else {
      triggerPostFormData()

      triggerGetAllPosts({ page: null, searchParams: {} })
    }
  }, [location])

  if (formLoading) {
    return <Spinner />
  }

  return (
    <>
      <SeoResult />
      <div className='container'>
        <div className='py-4'>
          <p className='fw-medium m-0'>
            <Link className='text-decoration-none' to={'/'}>
              Fillimi
            </Link>{' '}
            / <span className='text-primary'>Resultatet e Kerkimit</span>
          </p>
        </div>
      </div>
      <hr className='my-1 mb-4' />
      <div className='container mb-3'>
        <div className='row '>
          <div className='col-12 col-lg-4 col-xl-3'>
            <div className='card border-0'>
              <div className='card-body p-2'>
                <div className='border-bottom d-flex justify-content-between align-items-center p-2'>
                  <p className='m-0 fw-bold text-dark'>Filter</p>
                  <img src={filter} alt='' />
                </div>
                <div>
                  <InputTextGroup
                    placeholder={'Kerko Per...'}
                    name={'search'}
                    type={'text'}
                    inputGroupClass={'mt-2'}
                    value={searchParams.search}
                    onChange={(e) => handleChange(e)}
                  />
                  <ReactSelectCustom
                    options={categories.map((c) => ({
                      value: c.id,
                      label: c.name
                    }))}
                    brCustom={'5px'}
                    mtCustom={'5px'}
                    placeholder={'Kategoria'}
                    values={
                      searchParams.category !== ''
                        ? categories
                            .map((cat) => ({ value: cat.id, label: cat.name }))
                            .find((el) => el.value === searchParams.category)
                        : ''
                    }
                    onChange={(value) =>
                      setSearchParams({
                        ...searchParams,
                        category: value ? value.value : ''
                      })
                    }
                  />
                  <ReactSelectCustom
                    options={cities.map((c) => ({
                      value: c.id,
                      label: c.name
                    }))}
                    brCustom={'5px'}
                    mtCustom={'5px'}
                    placeholder={'Qyteti'}
                    values={
                      searchParams.city !== ''
                        ? cities
                            .map((cat) => ({ value: cat.id, label: cat.name }))
                            .find((el) => el.value == searchParams.city)
                        : ''
                    }
                    onChange={(value) => handleCityChange(value)}
                  />

                  <InputTextGroup
                    placeholder={'Cmimi nga...'}
                    name={'price_from'}
                    type={'number'}
                    inputGroupClass={'mt-2'}
                    value={searchParams.price_from}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputTextGroup
                    placeholder={'Cmimi deri...'}
                    name={'price_to'}
                    type={'number'}
                    inputGroupClass={'mt-2'}
                    value={searchParams.price_to}
                    onChange={(e) => handleChange(e)}
                  />
                  <Button
                    buttonText={'Kerko'}
                    buttonClass={'mt-2 w-100 btn-primary'}
                    type={'button'}
                    onClick={() => searchPosts()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-7 col-xl-6'>
            <h5 className='mt-3 mt-lg-0'>{posts_count} njoftime te gjetura</h5>
            {pinPosts.length !== 0 && (
              <div className='row my-3'>
                {pinPosts.map((post, index) => (
                  <div className='col-12' key={index}>
                    <PinPostCard post={post} />
                  </div>
                ))}
              </div>
            )}
            {isLoading ? (
              <SmallSpinner />
            ) : (
              <>
                {posts &&
                  posts.data.map((post, index) => (
                    <PostCard post={post} key={index} />
                  ))}
                {posts && posts.last_page !== 1 && (
                  <Pagination
                    collectData={triggerGetAllPosts}
                    page={page}
                    last_page={last_page}
                    links={posts}
                  />
                )}
              </>
            )}
          </div>
          <div className='d-none d-xl-block col-xl-3'>
            <div>
              {banners.map((banner, index) => (
                <div key={index}>
                  <a href={banner.url} target='_blank' rel='noreferrer'>
                    <img
                      src={BACKEND_STORAGE_URL + banner.image_path}
                      alt=''
                      className='img-fluid'
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Search
