import React from 'react'
import Uppy from '@uppy/core'
import { DragDrop } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'

const FileUploader = ({ fields, append, remove, setValue }) => {
  const uppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: 10,
      allowedFileTypes: ['image/*'] // Restrict to images
    },
    autoProceed: false
  })

  const handleFileChange = (file) => {
    if (fields.length >= 10) {
      // Limit files to 10
      console.warn('Maximum of 10 files can be uploaded.')
      return
    }

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const fileType = file.type
        const base64Content = reader.result

        // Add the new file to react-hook-form
        const index = fields.length // Append new item index
        append({
          filename: file.name,
          content: base64Content.replace(`data:${fileType};base64,`, ''),
          fullContent: base64Content
        })

        setValue(`gallery.${index}.filename`, file.name)
        setValue(
          `gallery.${index}.content`,
          base64Content.replace(`data:${fileType};base64,`, '')
        )
        setValue(`gallery.${index}.fullContent`, base64Content)
      }
      reader.readAsDataURL(file.data)
    }
  }

  const handleRemoveFile = (index, fileId) => {
    remove(index) // Remove file from react-hook-form
    uppy.removeFile(fileId) // Remove file from Uppy
  }

  React.useEffect(() => {
    // Listen to Uppy file addition
    uppy.on('file-added', (file) => handleFileChange(file))

    return () => {
      uppy.close()
    }
  }, [uppy, fields])

  return (
    <div
      style={{
        padding: '20px',
        maxWidth: '100%',
        margin: 'auto',
        borderStyle: 'dashed'
      }}
      className='border-primary bg-primary-subtle rounded-4'
    >
      <DragDrop
        uppy={uppy}
        locale={{
          strings: {
            dropHereOr: 'Ngarko Fotot %{browse}',
            browse: 'Ketu'
          }
        }}
      />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          marginTop: '20px',
          maxHeight: '150px',
          overflowY: 'auto'
        }}
      >
        {fields.map((field, index) => (
          <div key={field.id} style={{ position: 'relative', width: '100px' }}>
            <img
              src={field.fullContent}
              alt={field.filename}
              style={{
                width: '100%',
                height: '100px',
                objectFit: 'cover',
                borderRadius: '5px'
              }}
            />
            <button
              onClick={() => handleRemoveFile(index, field.id)}
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                backgroundColor: 'rgba(0,0,0,0.6)',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                fontSize: '12px'
              }}
            >
              ×
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FileUploader
