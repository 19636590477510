import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  AuthLogo,
  ForgotParagraph,
  NotRegistered,
  RegisterBody,
  RegisterCard
} from './Register.styles'
import forgot from '../../assets/icons/forgot-password.svg'
import InputTextGroup from '../../components/forms/InputTextGroup'
import Button from '../../components/common/Button'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Spinner from '../../components/common/Spinner'
import { useForgotPasswordMutation } from '../../feature/auth/authSliceApi'
import {
  generalAlertError,
  generalAlertSuccessToast
} from '../../helpers/alert'

const schema = yup.object({
  email: yup.string().required('Ju lutem vendosni emailin ose username tuaj!')
})

function ForgotPassword() {
  const navigate = useNavigate()
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation()
  const {
    watch,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  })

  const formData = watch()

  const updateFormData = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
  }

  const handleForgotPassword = async () => {
    const res = await forgotPassword({ data: formData })

    if (res.error && res.error.status === 404) {
      generalAlertError('Ky email nuk ekziston ne sistem!')
    } else if (res.error) {
      generalAlertError('Dicka shkoi gabim!')
    } else {
      generalAlertSuccessToast('Kontrolloni emailin per kodin e resetimit!')
      navigate('/reset-password')
    }
  }

  return (
    <div className=' px-md-5'>
      <div className='container row mx-auto'>
        <div className='col-12 col-lg-8 offset-lg-2 my-5'>
          <RegisterCard className='card py-md-3 px-0 px-xl-5'>
            <RegisterBody className='card-body d-flex justify-content-center align-items-center px-0 px-md-5 flex-column'>
              <AuthLogo>
                <img src={forgot} alt='' />
              </AuthLogo>
              <h3 className='mt-2 w-75 text-center'>
                Keni Harruar Fjalekalimin?
              </h3>
              <ForgotParagraph className='w-75'>
                <p className='text-center'>
                  Fusni adresën tuaj të emailit dhe ne do t'ju dërgojmë një kod
                  6 shifror ne email me udhëzimet për të rivendosur fjalëkalimin
                  tuaj.
                </p>
              </ForgotParagraph>
              <form
                className='w-100 mx-auto'
                onSubmit={handleSubmit(handleForgotPassword)}
              >
                <InputTextGroup
                  placeholder={'Email'}
                  name='email'
                  type='email'
                  id='email'
                  inputGroupClass={'w-75 mx-auto mb-3 '}
                  inputClass={'py-2'}
                  error={errors.email?.message}
                  onChange={(e) =>
                    updateFormData({
                      email: e.target.value
                    })
                  }
                  value={formData.email}
                />
                {isLoading ? (
                  <button
                    type={'button'}
                    className={`mx-auto py-2 btn rounded-2 w-75 mx-auto d-table btn-primary d-flex justify-content-center align-items-center`}
                    disabled
                  >
                    <div
                      className='spinner-border text-light spinner-border-sm'
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                    <div className='ms-2'>Duke derguar emailin...</div>
                  </button>
                ) : (
                  <Button
                    type={'submit'}
                    buttonClass={'w-75 mx-auto d-table btn-primary'}
                    buttonText='Dergo Kodin'
                  />
                )}
              </form>
              <NotRegistered className='w-75 mx-auto'>
                <p className=' mt-3 mb-5'>
                  Rikthehu tek{' '}
                  <Link to='/hyr' className='fw-light text-primary'>
                    Identifikimi
                  </Link>
                </p>
              </NotRegistered>
            </RegisterBody>
          </RegisterCard>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
