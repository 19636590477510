import React from 'react'
import money from '../../../assets/icons/money.svg'
import { Link } from 'react-router-dom'
import moment from 'moment'

function AccountBalanceTab({ user }) {
  return (
    <>
      <h3 className='mt-5'>Llogaria Juaj</h3>
      <div className='row mt-3'>
        <div className='col-12 col-md-6 col-lg-5 col-xl-4'>
          <div className='card'>
            <div className='card-body p-4'>
              <h5>Bilanci i Llogarise</h5>
              <div className='d-flex justify-content-start align-items-center'>
                <img src={money} alt='' width={50} />
                <h2 className='m-0 mt-1 ms-2'>
                  {user.credit !== null ? user.credit.amount : '0'} &euro;
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='col-12 col-md-6 col-lg-5 col-xl-4 mt-3 mt-md-0 '>
          <div className='card'>
            <div className='card-body p-4'>
              <h5 className='mt-1'>Shto Fonde ne Llogari</h5>
              <form action='' className=''>
                <div className='d-flex justify-content-start align-items-center gap-2 mt-3'>
                  <input
                    type='text'
                    className='form-control w-50'
                    placeholder='300 Lekë'
                  />
                  <button className='btn btn-primary'>Shto Fonde</button>
                </div>
              </form>
            </div>
          </div>
        </div> */}
      </div>
      <h3 className='mt-5 mb-4'>Shpenzimet</h3>
      <div className='row'>
        <div className='col-12 col-md-10'>
          <table className='table table-hover table-responsive'>
            <thead>
              <tr>
                <th scope='col'>ID</th>
                <th scope='col'>Njoftimi</th>
                <th scope='col'>Shuma e Shpenzuar</th>
                <th scope='col'>Data</th>
              </tr>
            </thead>
            <tbody>
              {user.credit_history.length === 0 ? (
                <tr>
                  <td colSpan={4}>Nuk ka historik!</td>
                </tr>
              ) : (
                user.credit_history.map((item, index) => (
                  <tr key={index}>
                    <th scope='row'>{item.id}</th>
                    <td>
                      <Link to={'/'}>{item.post.title}</Link>
                    </td>
                    <td>{item.amount_spent} &euro;</td>
                    <td>{moment(item.created_at).format('LLL')}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default AccountBalanceTab
