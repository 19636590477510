import React, { useEffect, useState } from 'react'
import InputTextGroup from '../../components/forms/InputTextGroup'
import Button from '../../components/common/Button'
import InputTextArea from '../../components/forms/InputTextArea'
import InputRadioGroup from '../../components/forms/InputRadioGroup'
import { Uploader } from '../Home/components/Uppy/Uploader'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Spinner from '../../components/common/Spinner'
import { currencies, noPriceTitle } from '../../Utilities/constants'
import { useNavigate, useParams } from 'react-router-dom'
import { BACKEND_STORAGE_URL } from '../../config/urls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { editPostValidation } from './editPost.validations'
import {
  confirmAlert,
  generalAlertError,
  generalAlertErrorToast,
  generalAlertSuccess,
  generalAlertSuccessToast
} from '../../helpers/alert'
import VehiclePostEditComponent from './components/VehiclePostEditComponent'
import {
  useDeleteGalleryItemMutation,
  useLazyGetPostForEditQuery,
  useUpdatePostMutation
} from '../../feature/posts/postSliceApi'
import RealEstateFormEditComponent from './components/RealEstateFormEditComponent'
import JobPostFormEditComponent from './components/JobPostFormEditComponent'
import CustomSelect from '../../components/forms/CustomSelect'
import {
  useLazyGetAllBrandsQuery,
  useLazyGetAutoModelsQuery,
  useLazyGetInitialFormDataQuery,
  useLazyGetMotoModelsQuery
} from '../../feature/formData/formDataSliceApi'

function EditPost() {
  const [triggerGetSinglePost, { data, isLoading }] =
    useLazyGetPostForEditQuery()
  const [deleteGalleryItem] = useDeleteGalleryItemMutation()
  const [updatePost, { isLoading: loadingUpdate }] = useUpdatePostMutation()
  const [
    triggerFormInitialData,
    { data: initialData, isLoading: formDataLoading }
  ] = useLazyGetInitialFormDataQuery()

  const [triggerAllBrands, { data: brandsData, isLoading: brandsLoading }] =
    useLazyGetAllBrandsQuery()

  const [
    triggerAutoModels,
    { data: autoModels, isLoading: autoModelsLoading }
  ] = useLazyGetAutoModelsQuery()
  const [
    triggerMotoModels,
    { data: motoModels, isLoading: motoModelsLoading }
  ] = useLazyGetMotoModelsQuery()

  const colors = initialData?.data?.colors || []
  const engines = initialData?.data?.engines || []

  const { slug } = useParams()
  const [coverImage, setCoverImage] = useState('')
  const navigate = useNavigate()
  const {
    setValue,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: 'all',
    resolver: yupResolver(editPostValidation),
    defaultValues: {
      title: '',
      description: '',
      phone: '',
      email: '',
      city_id: '',
      price_currency: '',
      price: '',
      price_on_request_title: '',
      cover_image: {
        filename: '',
        content: ''
      },
      vehicle_post: {
        vehicle_post_type: '',
        vehicle_type_id: '',
        auto_brand_id: '',
        auto_model_id: '',
        moto_brand_id: '',
        moto_model_id: '',
        transmission_type: '',
        engine_id: '',
        vehicle_year: '',
        km_done: '',
        color_id: '',
        plate_types: '',
        vehicle_status: ''
      },
      job_post: {
        job_position: '',
        job_post_type_id: '',
        job_working_hours_id: ''
      },
      real_estate_post: {
        square_meters: '',
        nr_bedrooms: '',
        nr_bathrooms: '',
        has_garage: '',
        nr_balconies: '',
        has_documentation: '',
        floor_nr: '',
        real_estate_type_id: ''
      },
      gallery: []
    }
  })

  const post = data?.data || null

  const formData = watch()

  const updateFormData = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
  }

  const uploadGallery = (files) => {
    let gallery = []

    Object.values(files).forEach((file) => {
      let baseURL = ''
      let reader = new FileReader()
      reader.readAsDataURL(file)
      const fileType = file.type
      reader.onload = () => {
        baseURL = reader.result
        gallery.push({
          filename: file.name,
          content: baseURL.replace('data:' + fileType + ';base64,', '')
        })
      }
    })

    setTimeout(() => {
      setValue('gallery', gallery)
    }, 1000)
  }

  const removeGalleryImage = async (mediaId) => {
    const res = await deleteGalleryItem({ mediaId: mediaId })

    if (res.error && res.error.status === 404) {
      generalAlertError('Fotoja nuk u gjet!')
    } else if (res.error) {
      generalAlertError('Dicka Shkoi Gabim! Ju lutem provojeni serisht!')
    } else {
      generalAlertSuccessToast('Foto u fshi me sukses!')
      triggerGetSinglePost({ slug })
    }
  }

  const deleteImage = (mediaId) => {
    confirmAlert('Jeni i sigurte qe doni te fshini kete foto?', () =>
      removeGalleryImage(mediaId)
    )
  }

  const uploadCoverImage = (files) => {
    let file = files[0]
    let baseURL = ''
    let coverImage = {
      filename: '',
      content: ''
    }
    let reader = new FileReader()
    reader.readAsDataURL(file)
    const fileType = file.type
    reader.onload = () => {
      baseURL = reader.result
      coverImage = {
        filename: file.name,
        content: baseURL.replace('data:' + fileType + ';base64,', '')
      }
    }

    setTimeout(() => {
      setValue('cover_image', coverImage)
      setCoverImage(baseURL)
    }, 1000)
  }

  const removeFileFromUploader = (file) => {
    let gallery = []
    let images = watch().gallery
    images.map((el) => {
      if (el.filename != file.name) {
        gallery.push(el)
      }
    })

    setTimeout(() => {
      setValue('gallery', gallery)
    }, 1000)
  }

  const submitPostData = async () => {
    const res = await updatePost({ postId: post.id, data: formData })
    if (res.error && res.error.status === 404) {
      generalAlertErrorToast('Njoftimi Nuk U Gjet!')
    } else if (res.error && res.error.status === 403) {
      generalAlertErrorToast('Nuk Jeni I autorizuar per kete veprim!')
    } else if (res.error && res.error.status === 422) {
      generalAlertErrorToast('Disa Te Dhena Mungojne!')
    } else if (res.error) {
      generalAlertError('Dicka Shkoi Gabim! Ju lutem provojeni serisht!')
    } else {
      generalAlertSuccess('Njoftimi juaj u modifikua me suksess!')
      navigate('/')
    }
  }

  useEffect(() => {
    triggerFormInitialData()
    triggerGetSinglePost({ slug })
  }, [slug, triggerGetSinglePost])

  useEffect(() => {
    if (post !== null && post.vehicle_details !== null) {
      triggerAllBrands({ categoryId: post.post_category_id })
      if (post.vehicle_details.auto_brand_id !== null) {
        triggerAutoModels({ brandId: post.vehicle_details.auto_brand_id })
      } else {
        triggerMotoModels({ brandId: post.vehicle_details.moto_brand_id })
      }
    }
  }, [post, slug])

  useEffect(() => {
    if (post !== null) {
      setValue('title', post.title)
      setValue('description', post.description)
      setValue('phone', post.phone)
      setValue('email', post.email)
      setValue('city_id', post.city_id)

      if (post.vehicle_details !== null) {
        setValue(
          'price',
          post.vehicle_details.price
            ? post.vehicle_details.price?.toString()
            : ''
        )
        setValue(
          'price_currency',
          post.vehicle_details.price_currency
            ? post.vehicle_details.price_currency
            : ''
        )
        setValue(
          'price_on_request_title',
          post.vehicle_details.price_on_request_title
        )
        setValue(
          'vehicle_post.vehicle_post_type',
          post.vehicle_details.vehicle_post_type
        )
        setValue(
          'vehicle_post.vehicle_type_id',
          post.vehicle_details.vehicle_type_id
        )
        setValue(
          'vehicle_post.auto_brand_id',
          post.vehicle_details.auto_brand_id?.toString()
        )
        setValue(
          'vehicle_post.auto_model_id',
          post.vehicle_details.auto_model_id?.toString()
        )
        setValue(
          'vehicle_post.moto_brand_id',
          post.vehicle_details.moto_brand_id?.toString()
        )
        setValue(
          'vehicle_post.moto_model_id',
          post.vehicle_details.moto_model_id?.toString()
        )
        setValue(
          'vehicle_post.transmission_type',
          post.vehicle_details.transmission_type
        )
        setValue('vehicle_post.engine_id', post.vehicle_details.engine_id)
        setValue('vehicle_post.vehicle_year', post.vehicle_details.vehicle_year)
        setValue('vehicle_post.km_done', post.vehicle_details.km_done)
        setValue('vehicle_post.color_id', post.vehicle_details.color_id)
        setValue('vehicle_post.plate_types', post.vehicle_details.plate_types)
        setValue(
          'vehicle_post.vehicle_status',
          post.vehicle_details.vehicle_status
        )
      }

      if (post.real_estate !== null) {
        setValue(
          'price',
          post.real_estate.price ? post.real_estate.price?.toString() : ''
        )
        setValue(
          'price_currency',
          post.real_estate.price_currency ? post.real_estate.price_currency : ''
        )
        setValue(
          'price_on_request_title',
          post.real_estate.price_on_request_title
        )
        setValue(
          'real_estate_post.square_meters',
          post.real_estate.square_meters?.toString()
        )
        setValue(
          'real_estate_post.nr_bedrooms',
          post.real_estate.nr_bedrooms?.toString()
        )
        setValue(
          'real_estate_post.nr_bathrooms',
          post.real_estate.nr_bathroom?.toString()
        )
        setValue(
          'real_estate_post.has_garage',
          post.real_estate.has_garage?.toString()
        )
        setValue(
          'real_estate_post.nr_balconies',
          post.real_estate.nr_balconies?.toString()
        )
        setValue(
          'real_estate_post.has_documentation',
          post.real_estate.has_documentation?.toString()
        )
        setValue(
          'real_estate_post.floor_nr',
          post.real_estate.floor_nr?.toString()
        )
        setValue(
          'real_estate_post.real_estate_type_id',
          post.real_estate.real_estate_type_id?.toString()
        )
      }

      if (post.work_details !== null) {
        setValue(
          'price',
          post.work_details.salary ? post.work_details.salary?.toString() : ''
        )
        setValue(
          'price_currency',
          post.work_details.salary_currency
            ? post.work_details.salary_currency
            : ''
        )
        setValue(
          'price_on_request_title',
          post.work_details.price_on_request_title
        )
        setValue(
          'job_post.job_position',
          post.work_details.job_position?.toString()
        )
        setValue(
          'job_post.job_post_type_id',
          post.work_details.job_post_type_id?.toString()
        )
        setValue(
          'job_post.job_working_hours_id',
          post.work_details.job_working_hours_type_id?.toString()
        )
      }

      if (post.general_details !== null) {
        setValue('price', post.general_details.price?.toString())
        setValue(
          'price_currency',
          post.general_details.price_currency
            ? post.general_details.price_currency
            : ''
        )
        setValue(
          'price_on_request_title',
          post.general_details.price_on_request_title
        )
      }
    }
  }, [post, slug])

  if (isLoading) {
    return <Spinner />
  }

  return post === null ? (
    <Spinner />
  ) : (
    <div className='my-5 mx-3'>
      <h3 className='mt-5 text-center'>Modifiko Postim</h3>
      <div className='row d-flex align-items-center justify-content-center my-4 '>
        <div className='col-12 col-md-9 col-xl-5'>
          <form onSubmit={handleSubmit(submitPostData)}>
            <InputTextGroup
              placeholder={'Titulli i Njoftimit'}
              name='title'
              type='text'
              value={formData.title}
              inputGroupClass={'mb-2'}
              inputClass={'py-2'}
              onChange={(e) =>
                updateFormData({
                  title: e.target.value
                })
              }
              error={errors.title?.message}
            />
            <InputTextGroup
              placeholder={'Ndrysho Coverin'}
              name='cover_image'
              labelText={'Ndrysho Coverin'}
              type='file'
              inputGroupClass={'mb-2'}
              inputClass={'py-2'}
              onChange={(e) => uploadCoverImage(e.target.files)}
              error={errors.cover_image?.message}
            />

            <img
              src={
                formData.cover_image.content === ''
                  ? BACKEND_STORAGE_URL + post.cover_image
                  : `${coverImage}`
              }
              alt=''
              className='img-fluid rounded-3 mt-2 mb-3'
            />

            {post.gallery.length !== 0 && (
              <div className='row mt-2 mb-3'>
                {post.gallery.map((image, index) => (
                  <div className='col-3 px-1' key={index}>
                    <div className='position-relative'>
                      <div
                        className='position-absolute end-0 top-0 mt-2 me-2'
                        style={{ cursor: 'pointer' }}
                        onClick={() => deleteImage(image.pivot.media_id)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} color='red' />
                      </div>
                      <img
                        src={BACKEND_STORAGE_URL + image.image_path}
                        alt=''
                        className='img-fluid rounded-3'
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className='mt-2 mb-3'>
              <Uploader
                maxfiles={8}
                note={'Ngarkoni Foto'}
                onFileUpload={(files) => uploadGallery(files)}
                onFileRemove={(file) => removeFileFromUploader(file)}
              />
            </div>

            {post && post.vehicle_details !== null ? (
              <VehiclePostEditComponent
                updateFormData={updateFormData}
                formData={formData}
                errors={errors}
                brandsLoading={brandsLoading}
                brands={brandsData ?? []}
                modelsAutoLoading={autoModelsLoading}
                modelsMotoLoading={motoModelsLoading}
                modelsAuto={autoModels ? autoModels.data : []}
                modelsMoto={motoModels ? motoModels.data : []}
                engines={engines}
                colors={colors}
                category_id={post.post_category_id}
              />
            ) : (
              ''
            )}
            {post && post.work_details !== null ? (
              <JobPostFormEditComponent
                updateFormData={updateFormData}
                formData={formData}
                errors={errors}
              />
            ) : (
              ''
            )}
            {post && post.real_estate !== null ? (
              <RealEstateFormEditComponent
                updateFormData={updateFormData}
                formData={formData}
                errors={errors}
                category_id={post.post_category_id}
              />
            ) : (
              ''
            )}
            <InputTextGroup
              placeholder={'Numer Kontakti'}
              name='phone'
              type='text'
              inputGroupClass={'mb-2'}
              inputClass={'py-2'}
              value={formData.phone}
              onChange={(e) =>
                updateFormData({
                  phone: e.target.value
                })
              }
              error={errors.phone?.message}
            />
            <InputTextGroup
              placeholder={'Email Kontakti'}
              name='email'
              type='text'
              inputGroupClass={'mb-2'}
              inputClass={'py-2'}
              value={formData.email}
              onChange={(e) =>
                updateFormData({
                  email: e.target.value
                })
              }
              error={errors && errors.email?.message}
            />
            <InputTextArea
              placeholder={'Pershkrimi i Postimit'}
              textAreaDivClass={'mb-2'}
              value={formData.description}
              onChange={(e) =>
                updateFormData({
                  description: e.target.value
                })
              }
              error={errors.description?.message}
            />
            <p className='mb-2'>
              Nese nuk doni te beni publik cmimin ju lutem zgjidhni nje nga
              opsionet me poshte
            </p>
            <div className='d-flex gap-3 mb-2'>
              <CustomSelect
                options={noPriceTitle}
                placeholder={'Deshironi te vendosni Cmim?'}
                value={formData.price_on_request_title}
                className={`form-select py-2 mb-2 ${
                  errors.price_on_request_title?.message ? 'is-invalid' : ''
                }`}
                onChange={(e) =>
                  updateFormData({
                    price_on_request_title: e.target.value
                  })
                }
              />
            </div>

            {formData.price_on_request_title === null ||
            formData.price_on_request_title === '' ? (
              <>
                <InputTextGroup
                  placeholder={'Pagesa/çmimi/Vlera'}
                  name='price'
                  type='text'
                  inputGroupClass={'mb-2'}
                  inputClass={'py-2'}
                  value={formData.price}
                  onChange={(e) =>
                    updateFormData({
                      price: e.target.value
                    })
                  }
                  error={errors.price?.message}
                />
                <div className='d-flex gap-3 mb-2'>
                  {currencies.map((item, index) => (
                    <InputRadioGroup
                      name={'price_currency'}
                      value={item.id}
                      labelRadio={item.name}
                      checked={formData.price_currency === item.id}
                      key={index}
                      onChange={(e) =>
                        updateFormData({
                          price_currency: e.target.value
                        })
                      }
                    />
                  ))}
                </div>
              </>
            ) : (
              ''
            )}

            {loadingUpdate ? (
              <button
                className='btn btn-primary w-100 mt-3'
                type='button'
                disabled
              >
                <span
                  className='spinner-border spinner-border-sm'
                  aria-hidden='true'
                ></span>
                <span role='status'>Loading...</span>
              </button>
            ) : (
              <Button
                type={'submit'}
                buttonText={'Modifiko'}
                buttonClass={'w-100 mt-3 btn-primary'}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditPost
