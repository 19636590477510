import React from 'react'
import { Helmet } from 'react-helmet'

function SeoAbout() {
  return (
    <Helmet>
      <title data-n-head='true'>
        Rreth Nesh | Okazion.al | Platformë për Blerje dhe Shitje Online
      </title>
      <meta
        name='description'
        content='Okazion.al ofron një treg online të sigurt dhe të lehtë për individë dhe biznese që duan të blejnë ose shesin produkte me cilësi dhe transparencë.'
      />
      <meta property='og:url' content='https://okazion.al/about-us' />
      <meta
        name='keywords'
        content='Okazion, rreth nesh, platformë online, treg, siguri, cilësi, besueshmëri, blerje, shitje, Shqipëri'
      />
      <meta property='og:locale' content='sq_AL' />
      <meta property='og:type' content='article' />
      <meta
        name='googlebot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta property='og:image' content='https://okazion.al/images/logo.png' />
      <meta
        property='og:title'
        content='Rreth Nesh | Okazion.al | Platformë për Blerje dhe Shitje Online'
      />
      <meta
        property='og:description'
        content='Mësoni më shumë rreth misionit dhe vlerave të Okazion.al. Një treg që ofron siguri dhe cilësi për të gjithë.'
      ></meta>
    </Helmet>
  )
}

export default SeoAbout
