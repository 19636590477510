import axios from "axios";

import StorageService from "../StorageService";
import { jwtDecode } from "jwt-decode";
import { BACKEND_API_URL } from "../../config/urls";

const Storage = new StorageService();

const isTokenExpired = (exp) => Date.now() >= exp * 1000;

export const downloadFile = (fileBlob) => {
  const file = window.URL.createObjectURL(fileBlob);
  window.open(file, "_blank");
};

export const axeRequestInterceptor = async (config) => {
  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json";
  }

  if (!config.headers.Accept) {
    config.headers.Accept = "application/json";
  }

  const jwtToken = localStorage.getItem('jwtToken');

  if (jwtToken != null) {
    try {
      const jwtTokenDecoded = jwtDecode(jwtToken);
      if (isTokenExpired(jwtTokenDecoded.exp)) {
        // Check if refresh token is expired
        const jwtRefreshToken = localStorage.getItem('jwtRefreshToken');

        if (jwtRefreshToken !== null) {
          const decodedJwtRefreshToken = jwtDecode(jwtRefreshToken);

          if (isTokenExpired(decodedJwtRefreshToken.exp)) {
            localStorage.clear();
            window.location.reload()
          } else {
            const response = await axios.post(
              `${BACKEND_API_URL}/refresh/token`,
              {},
              {
                headers: {
                  Refresh: localStorage.getItem('jwtRefreshToken'),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.status === 403) {
              localStorage.clear();
              window.location.reload()
            } else {
              const { data } = response;

              Storage.setToken(data.access_token);
              Storage.setRefreshToken(data.refresh_token);
              Storage.setUser(data.user);
            }
          }
        } else {
          localStorage.clear();
          window.location.reload()
        }
      }
    } catch (err) {
      // Do nothing
    }

    const finalJwtToken = localStorage.getItem('jwtToken');

    if (finalJwtToken !== null) {
      config.headers.Authorization = finalJwtToken;
    }
  }

  config.validateStatus = (status) => status !== 403 && status < 500;

  return config;
};

export const axeResponseInterceptor = async (error) => {
  if (error.response?.status === 403 && localStorage.getItem('jwtRefreshToken') !== null) {
    const response = await axios.post(
      `${BACKEND_API_URL}/refresh/token`,
      {},
      {
        headers: {
          Refresh: localStorage.getItem('jwtRefreshToken'),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 403) {
      Storage.removeRefreshToken();
      Storage.removeToken();
      Storage.removeUser();
      window.location.href =
        window.location.protocol + "//" + window.location.host + "/hyr";
      return Promise.reject(error);
    }

    if (response.status !== 200) {
      return Promise.reject(error);
    }

    const { data } = response;

    Storage.setToken(data.access_token);
    Storage.setRefreshToken(data.refresh_token);
    Storage.setUser(data.user);

    error.request.headers.Authorization = localStorage.getItem('jwtToken');

    return axios.request(error.request);
  }

  if (error.response?.status === 403) {
    Storage.removeRefreshToken();
    Storage.removeToken();
    Storage.removeUser();
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/hyr";
    return Promise.reject(error);
  }

  return Promise.reject(error);
};
