import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Register from './screens/Authentication/Register'
import Base from './layouts/Base'
import { GlobalStyle } from './styles/global'
import Login from './screens/Authentication/Login'
import ForgotPassword from './screens/Authentication/ForgotPassword'
import ResetPassword from './screens/Authentication/ResetPassword'
import UserProfile from './screens/UserProfile/UserProfile'
import AboutUs from './screens/AboutUs'
import ContactUs from './screens/ContactUs'
import Businesses from './screens/Businesses'
import Faq from './screens/Faq'
import BusinessPage from './screens/BusinessPage'
import Search from './screens/Search'
import { Provider } from 'react-redux'
import { store } from './feature/store'
import SingleJobPage from './screens/SinglePages/SingleJobPage'
import VerifyEmail from './screens/Authentication/VerifyEmail'
import Home from './screens/Home/Home'
import CreatePost from './screens/Posts/CreatePost'
import EditPost from './screens/Posts/EditPost'
import SocialLoginRedirect from './screens/SocialLogin/SocialLoginRedirect'
import Marketing from './screens/Marketing'
import NotFound from './screens/NotFound'
import MainCategoryResult from './screens/MainCategoryResult'
import PrivacyPolicy from './screens/PrivacyPolicy'
import TermsAndConditions from './screens/TermsAndConditions'
import ErrorFallback from './components/ErrorFallback'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Base />,
    children: [
      {
        path: '',
        element: <Home />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'regjistrohu',
        element: <Register />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'hyr',
        element: <Login />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'verify-email',
        element: <VerifyEmail />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'profili/:username',
        element: <UserProfile />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'rreth-nesh',
        element: <AboutUs />
      },
      {
        path: 'kontakt',
        element: <ContactUs />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'businesses',
        element: <Businesses />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'business-page/:username',
        element: <BusinessPage />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'njoftime',
        element: <Search />,
        errorElement: <ErrorFallback />
      },
      {
        path: '/njoftime/kategoria/:categorySlug',
        element: <MainCategoryResult />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'faq',
        element: <Faq />
      },
      {
        path: 'njoftime/:slug',
        element: <SingleJobPage />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'krijo-njoftim',
        element: <CreatePost />,
        errorElement: <ErrorFallback />
      },
      {
        path: 'njoftime/:slug/modifiko',
        element: <EditPost />,
        errorElement: <ErrorFallback />
      },
      {
        path: '/social/login',
        element: <SocialLoginRedirect />
      },
      {
        path: '/marketing',
        element: <Marketing />
      },
      {
        path: '/politikat-e-privatesise',
        element: <PrivacyPolicy />
      },
      {
        path: '/kushtet-dhe-termat',
        element: <TermsAndConditions />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
])

function App() {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <RouterProvider router={router} />
    </Provider>
  )
}

export default App
