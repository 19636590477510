import { faBriefcase, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  jobPositions,
  jobPostTypes,
  jobWorkingHours
} from '../../../Utilities/constants'

function WorkInfo({ post }) {
  return (
    <div className='card border-0 rounded-2 p-2 mb-2'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faBriefcase} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {
                  jobPositions.find(
                    (el) => el.id === post.work_details.job_position
                  ).name
                }
              </p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faClock} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {
                  jobWorkingHours.find(
                    (el) =>
                      el.id === post.work_details.job_working_hours_type_id
                  ).name
                }
              </p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faBriefcase} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {
                  jobPostTypes.find(
                    (el) => el.id === post.work_details.job_post_type_id
                  ).name
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkInfo
