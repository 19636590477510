import React from 'react'
import { Helmet } from 'react-helmet'

function SeoFaqs() {
  return (
    <Helmet>
      <title data-n-head='true'>
        Pyetje të Shpeshta | Okazion.al | Informacion dhe Ndihmë
      </title>
      <meta
        name='description'
        content='Merrni përgjigje për pyetjet më të zakonshme mbi Okazion.al: krijimi i llogarisë, siguria, blerja, shitja, tarifat dhe më shumë.'
      />
      <meta
        name='apple-mobile-web-app-title'
        content='Pyetje të Shpeshta | Okazion.al | Informacion dhe Ndihmë'
      />
      <meta
        name='keywords'
        content='FAQ, pyetje të shpeshta, ndihmë, Okazion, informacion, siguri, llogari, blerje, shitje, tarifa, transaksione'
      />
      <meta name='robots' content='index, follow' />
      <meta
        name='googlebot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta
        name='bingbot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta name='twitter:card' content='https://okazion.al/images/logo.png' />
      <meta
        name='twitter:description'
        content='Gjeni përgjigje për pyetjet mbi përdorimin e Okazion.al: krijimi i llogarisë, siguria e transaksioneve, tarifat dhe më shumë.'
      />
      <meta
        name='twitter:title'
        content='Pyetje të Shpeshta | Okazion.al | Informacion dhe Ndihmë'
      />
      <meta name='twitter:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:locale' content='sq_AL' />
      <meta property='og:type' content='article' />
      <meta
        property='og:title'
        content='Pyetje të Shpeshta | Okazion.al | Informacion dhe Ndihmë'
      />
      <meta
        property='og:description'
        content='Informohuni për gjithçka që duhet të dini për Okazion.al: si të krijoni një llogari, të bëni një blerje ose shitje, dhe më shumë.'
      />
      <meta property='og:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:url' content='https://okazion.al/faq' />
      <meta
        property='og:image:secure_url'
        content='https://okazion.al/images/logo.png'
      />
      <meta property='og:image:type' content='image/png' />
      <meta property='og:image:alt' content='FAQ Okazion.al Logo'></meta>
    </Helmet>
  )
}

export default SeoFaqs
