import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  socialLogin: {
    loading: true,
    error: null,
  },
};

export const socialAuthSlice = createSlice({
  name: "socialAuth",
  initialState,
  reducers: {
    loginPending: (state) => {
      state.socialLogin.loading = true;
      state.socialLogin.error = null;
    },
    loginRejected: (state, { payload }) => {
      state.socialLogin.loading = false;
      state.socialLogin.error = payload;
    },
    loginSuccess: (state) => {
      state.socialLogin.loading = false;
    },
    loginReset: (state) => {
      state.socialLogin = { ...initialState.socialLogin };
    },
  },
});
