import React, { useState } from 'react'
import {
  ActionButton,
  ActionWrapper,
  CloseButton,
  Image,
  MarketingFeatures,
  MarketingFeaturesItem,
  MarketingFeaturesItemIcon,
  MarketingInfo,
  MarketingItemInner,
  MarketingItemStyles,
  MarketingPreview,
  MarketingPrice,
  MarketingPricing,
  MarketingTitle,
  ModalContent,
  ModalOverlay
} from './MarketingItem.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

function MarketingItem({ item }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <>
      {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>
              <FontAwesomeIcon icon={faTimesCircle} size='lg' color='red' />
            </CloseButton>
            <Image src={item.image} alt='Placeholder' />
          </ModalContent>
        </ModalOverlay>
      )}
      <MarketingItemStyles>
        <MarketingItemInner>
          <div className='d-flex align-items-start justify-items-between flex-column h-100'>
            <MarketingPricing>
              <MarketingPrice>
                {item.price} {item.price !== 'Kontakto' && '€'}
              </MarketingPrice>
            </MarketingPricing>
            <MarketingTitle>{item.name}</MarketingTitle>
            <MarketingInfo>{item.desc}</MarketingInfo>
            <MarketingFeatures>
              {item.features.map((feature, index) => (
                <MarketingFeaturesItem key={index}>
                  <MarketingFeaturesItemIcon>
                    <svg
                      height='24'
                      width='24'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M0 0h24v24H0z' fill='none'></path>
                      <path
                        fill='currentColor'
                        d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z'
                      ></path>
                    </svg>
                  </MarketingFeaturesItemIcon>
                  <span>{feature.text}</span>
                </MarketingFeaturesItem>
              ))}
            </MarketingFeatures>
            <ActionWrapper>
              {item.image !== null && (
                <MarketingPreview>
                  <p>Shembull Banneri</p>
                  <FontAwesomeIcon
                    icon={faEye}
                    size='lg'
                    style={{ cursor: 'pointer' }}
                    onClick={() => openModal()}
                  />
                </MarketingPreview>
              )}
              <ActionButton href='tel:+355697850900'>Kontakto</ActionButton>
            </ActionWrapper>
          </div>
        </MarketingItemInner>
      </MarketingItemStyles>
    </>
  )
}

export default MarketingItem
