import React from 'react'
import { Helmet } from 'react-helmet'

function SeoMarketing() {
  return (
    <Helmet>
      <title data-n-head='true'>
        Marketing në Okazion.al | Promovoni Produktet dhe Biznesin Tuaj
      </title>
      <meta
        name='description'
        content='Zbuloni paketat tona të marketingut dhe mënyrat e reklamimit në Okazion.al. Promovoni produktet dhe biznesin tuaj për një audiencë të gjerë dhe të angazhuar.'
      />
      <meta
        name='apple-mobile-web-app-title'
        content='Marketing në Okazion.al | Promovoni Produktet dhe Biznesin Tuaj'
      />
      <meta
        name='keywords'
        content='marketing, reklama, Okazion.al, promovim, reklamim online, paketa marketingu, biznes, produkte, publikime premium, shërbime reklamuese'
      />
      <meta name='robots' content='index, follow' />
      <meta
        name='googlebot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta
        name='bingbot'
        content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      />
      <meta name='twitter:card' content='https://okazion.al/images/logo.png' />
      <meta
        name='twitter:description'
        content='Përdorni paketat tona të marketingut për të promovuar produktet dhe biznesin tuaj në Okazion.al. Reklamoni për audiencën që ka rëndësi për ju.'
      />
      <meta
        name='twitter:title'
        content='Marketing në Okazion.al | Promovoni Produktet dhe Biznesin Tuaj'
      />
      <meta name='twitter:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:locale' content='sq_AL' />
      <meta property='og:type' content='article' />
      <meta
        property='og:title'
        content='Marketing në Okazion.al | Promovoni Produktet dhe Biznesin Tuaj'
      />
      <meta
        property='og:description'
        content='Paketat më të mira për reklamim dhe promovim në Okazion.al. Bëjeni biznesin tuaj të dallohet mes konkurencës.'
      />
      <meta property='og:image' content='https://okazion.al/images/logo.png' />
      <meta property='og:url' content='https://okazion.al/marketing' />
      <meta
        property='og:image:secure_url'
        content='https://okazion.al/images/logo.png'
      />
      <meta property='og:image:type' content='image/png' />
      <meta
        property='og:image:alt'
        content='Marketing në Okazion.al Logo'
      ></meta>
    </Helmet>
  )
}

export default SeoMarketing
