import React, { useEffect } from 'react'
import ReactSelectCustom from '../../../components/forms/ReactSelectCustom'
import { flagResponse, realEstateTypes } from '../../../Utilities/constants'
import InputTextGroup from '../../../components/forms/InputTextGroup'

function RealEstateFormEditComponent({
  updateFormData,
  formData,
  errors,
  category_id
}) {
  return (
    <>
      <label className={`form-label `}>Tipi i Prones</label>
      <ReactSelectCustom
        placeholder={'Tipi i Prones'}
        name='real_estate_type_id'
        options={realEstateTypes.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={realEstateTypes
          .map((c) => ({ value: c.id, label: c.name }))
          .find(
            (el) => el.value == formData.real_estate_post.real_estate_type_id
          )}
        onChange={(val) =>
          updateFormData({
            'real_estate_post.real_estate_type_id': val ? val.value : ''
          })
        }
        error={errors && errors.real_estate_type_id?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.real_estate_type_id?.message}
      </div>
      <InputTextGroup
        placeholder={'Siperfaqe Totale'}
        labelText={'Siperfaqe Totale'}
        name='square_meters'
        type='text'
        inputGroupClass={'mb-2'}
        inputClass={'py-2'}
        value={formData.real_estate_post.square_meters}
        onChange={(e) =>
          updateFormData({
            'real_estate_post.square_meters': e.target.value
          })
        }
        error={errors && errors.square_meters?.message}
      />
      {category_id != '5' && (
        <>
          <InputTextGroup
            placeholder={'Dhoma Gjumi'}
            name='nr_bedrooms'
            labelText={'Dhoma Gjumi'}
            type='text'
            inputGroupClass={'mb-2'}
            inputClass={'py-2'}
            value={formData.real_estate_post.nr_bedrooms}
            onChange={(e) =>
              updateFormData({
                'real_estate_post.nr_bedrooms': e.target.value
              })
            }
            error={errors && errors.nr_bedrooms?.message}
          />
          <InputTextGroup
            placeholder={'Tualete'}
            labelText={'Tualete'}
            name='nr_bathrooms'
            type='text'
            inputGroupClass={'mb-2'}
            inputClass={'py-2'}
            value={formData.real_estate_post.nr_bathrooms}
            onChange={(e) =>
              updateFormData({
                'real_estate_post.nr_bathrooms': e.target.value.toString()
              })
            }
            error={errors && errors.nr_bathrooms?.message}
          />
          <InputTextGroup
            placeholder={'Ballkone'}
            name='nr_balconies'
            labelText={'Ballkone'}
            type='text'
            inputGroupClass={'mb-2'}
            inputClass={'py-2'}
            value={formData.real_estate_post.nr_balconies}
            onChange={(e) =>
              updateFormData({
                'real_estate_post.nr_balconies': e.target.value
              })
            }
            error={errors && errors.nr_balconies?.message}
          />
          <label className={`form-label `}>Garazhd</label>
          <ReactSelectCustom
            placeholder={'Garazhd'}
            name='has_garage'
            options={flagResponse.map((c) => ({ value: c.id, label: c.name }))}
            brCustom={'0.375rem'}
            className={'mb-2'}
            values={flagResponse
              .map((c) => ({ value: c.id, label: c.name }))
              .find((el) => el.value == formData.real_estate_post.has_garage)}
            onChange={(val) =>
              updateFormData({
                'real_estate_post.has_garage': val ? val.value : ''
              })
            }
            error={errors && errors.has_garage?.message}
          />
          <div className='invalid-feedback d-block'>
            {errors && errors.has_garage?.message}
          </div>
          <InputTextGroup
            placeholder={'Kati'}
            labelText={'Kati'}
            name='floor_nr'
            type='text'
            inputGroupClass={'mb-2'}
            inputClass={'py-2'}
            value={formData.real_estate_post.floor_nr}
            onChange={(e) =>
              updateFormData({
                'real_estate_post.floor_nr': e.target.value
              })
            }
            error={errors && errors.floor_nr?.message}
          />
        </>
      )}
      <label className={`form-label `}>Ka Dokumentacion</label>
      <ReactSelectCustom
        placeholder={'Ka Dokumentacion'}
        name='has_documentation'
        options={flagResponse.map((c) => ({ value: c.id, label: c.name }))}
        brCustom={'0.375rem'}
        className={'mb-2'}
        values={flagResponse
          .map((c) => ({ value: c.id, label: c.name }))
          .find(
            (el) => el.value == formData.real_estate_post.has_documentation
          )}
        onChange={(val) =>
          updateFormData({
            'real_estate_post.has_documentation': val ? val.value : ''
          })
        }
        error={errors && errors.has_documentation?.message}
      />
      <div className='invalid-feedback d-block'>
        {errors && errors.has_documentation?.message}
      </div>
    </>
  )
}

export default RealEstateFormEditComponent
