import React, { useEffect, useState } from 'react'
import InputTextGroup from '../../../components/forms/InputTextGroup'
import Button from '../../../components/common/Button'
import InputTextArea from '../../../components/forms/InputTextArea'
import ReactSelectCustom from '../../../components/forms/ReactSelectCustom'
import SmallSpinner from '../../../components/common/SmallSpinner'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { BACKEND_STORAGE_URL } from '../../../config/urls'
import {
  useLazyGetEditProfileFormDataQuery,
  useUpdateBusinessProfileMutation,
  useUpdatePasswordMutation,
  useUpdateProfileMutation
} from '../../../feature/auth/authSliceApi'
import StorageService from '../../../services/StorageService'
import {
  generalAlertErrorToast,
  generalAlertSuccessToast
} from '../../../helpers/alert'
import { jwtDecode } from 'jwt-decode'
import {
  changePasswordSchema,
  updateProfileBusinessSchema,
  updateProfileSchema
} from './userValidations.validations'

const Storage = new StorageService()

function UpdateProfileTab({ user, currTab, collectData }) {
  const [triggerGetEditProfileFormData, { data, isLoading }] =
    useLazyGetEditProfileFormDataQuery()
  const [updateProfile, { isLoading: updateProfileLoading }] =
    useUpdateProfileMutation()
  const [updatePassword, { isLoading: loadingPasswordChange }] =
    useUpdatePasswordMutation()
  const [updateBusinessProfile, { isLoading: updateBusinessProfileLoading }] =
    useUpdateBusinessProfileMutation()

  const cities = data?.data?.cities || []
  const [profileImage, setprofileImage] = useState(null)
  const {
    setValue,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: 'all',
    resolver: yupResolver(updateProfileSchema),
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      profile_image: {
        filename: '',
        content: ''
      },
      city_id: '',
      phone: '',
      business_name: ''
    }
  })

  const {
    setValue: setValuePassword,
    watch: watchPassword,
    formState: { errors: errorsPassword },
    handleSubmit: handleSubmitPassword
  } = useForm({
    mode: 'all',
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      current_password: '',
      password: '',
      confirm_password: ''
    }
  })

  const {
    setValue: setValueBusiness,
    watch: watchBusiness,
    formState: { errors: errorsBusiness },
    handleSubmit: handleSubmitBusiness
  } = useForm({
    mode: 'all',
    resolver: yupResolver(updateProfileBusinessSchema),
    defaultValues: {
      bio: '',
      address: '',
      website_url: '',
      tiktok_url: '',
      instagram_url: '',
      facebook_url: ''
    }
  })

  const formData = watch()
  const formDataPassword = watchPassword()
  const formDataBusiness = watchBusiness()

  const updateFormData = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
  }

  const updateFormDataPassword = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValuePassword(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
  }

  const updateFormDataBusiness = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValueBusiness(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
  }

  const uploadCoverImage = (files) => {
    let file = files[0]
    let baseURL = ''
    let coverImage = {
      filename: '',
      content: ''
    }
    let reader = new FileReader()
    reader.readAsDataURL(file)
    const fileType = file.type
    reader.onload = () => {
      baseURL = reader.result
      coverImage = {
        filename: file.name,
        content: baseURL.replace('data:' + fileType + ';base64,', '')
      }
    }

    setTimeout(() => {
      setValue('profile_image', coverImage)
      setprofileImage(baseURL)
    }, 1000)
  }

  const submitUpdateProfile = async () => {
    const res = await updateProfile({
      userId: Storage.getUser().id,
      data: formData
    })

    if (res.error && res.error.status === 422) {
      generalAlertErrorToast('Ky email eshte i zene!')
    } else if (res.error) {
      generalAlertErrorToast('Dicka shkoi gabim!')
    } else {
      let user = jwtDecode(res.data.data.access_token)
      Storage.setToken(res.data.data.access_token)
      Storage.setRefreshToken(res.data.data.refresh_token)
      Storage.setUser(user)
      collectData({ page: null })
      generalAlertSuccessToast('Ndryshimet u ruajten me sukses!')
    }
  }

  const submitPasswordChange = async () => {
    const res = await updatePassword({
      userId: Storage.getUser().id,
      data: formDataPassword
    })

    if (res.error && res.error.status === 422) {
      generalAlertErrorToast('Fjalekalimi Akual nuk eshte i sakte!')
    } else if (res.error) {
      generalAlertErrorToast('Dicka shkoi gabim!')
    } else {
      setValuePassword('current_password', '')
      setValuePassword('password', '')
      setValuePassword('password_confirmation', '')
      collectData({ page: null })
      generalAlertSuccessToast('Fjalekalimi u ndryshua me sukses!')
    }
  }

  const submitUpdateBusinessProfile = async () => {
    const res = await updateBusinessProfile({
      userId: Storage.getUser().id,
      data: formDataBusiness
    })

    if (res.error) {
      generalAlertErrorToast('Dicka shkoi gabim!')
    } else {
      collectData({ page: null })
      generalAlertSuccessToast('Te dhenat u perditesuan me sukses!')
    }
  }

  useEffect(() => {
    if (currTab === 'profili') {
      triggerGetEditProfileFormData()
    }
  }, [currTab])

  useEffect(() => {
    if (user !== null) {
      setValue('name', user.name === null ? '' : user.name)
      setValue('surname', user.surname === null ? '' : user.surname)
      setValue('email', user.email)
      setValue('city_id', user.city_id)
      setValue('phone', user.phone)
      setValue('business_name', user.business_name)

      if (user.business_name !== null) {
        setValueBusiness('bio', user.bio)
        setValueBusiness('address', user.address)
        setValueBusiness('facebook_url', user.facebook_url)
        setValueBusiness('instagram_url', user.instagram_url)
        setValueBusiness('tiktok_url', user.tiktok_url)
        setValueBusiness('website_url', user.website_url)
      }
    }
  }, [user])

  return isLoading || !data ? (
    <div style={{ height: '200px' }}>
      <SmallSpinner />
    </div>
  ) : (
    <div className='row'>
      <div className='col-12 col-lg-5'>
        <h3 className='mt-5'>Modifiko te Dhenat Personale</h3>
        <form
          className='mt-4 pe-lg-5'
          onSubmit={handleSubmit(submitUpdateProfile)}
        >
          <div className='d-flex justify-content-start align-items-center gap-3 mb-3'>
            <div>
              {user.profile_image !== null && profileImage === null ? (
                <img
                  src={BACKEND_STORAGE_URL + user.profile_image}
                  alt={`${
                    user.business_name === null
                      ? user.name + ' ' + user.surname
                      : user.business_name
                  }`}
                  className='img-fluid rounded-3 mt-2 mb-3'
                  width={100}
                  height={100}
                />
              ) : (
                ''
              )}

              {profileImage !== null ? (
                <img
                  src={profileImage}
                  alt={`${
                    user.business_name === null
                      ? user.name + ' ' + user.surname
                      : user.business_name
                  }`}
                  className='img-fluid rounded-3 mt-2 mb-3'
                  width={100}
                  height={100}
                />
              ) : (
                ''
              )}
            </div>
            <div>
              <h6>Foto e Profilit</h6>
              <InputTextGroup
                name='profile_image'
                type='file'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                onChange={(e) => uploadCoverImage(e.target.files)}
                error={errors.profile_image?.message}
              />
            </div>
          </div>
          {user.business_name === null ? (
            <>
              <InputTextGroup
                placeholder={'Emri'}
                name='name'
                type='text'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formData.name}
                onChange={(e) =>
                  updateFormData({
                    name: e.target.value
                  })
                }
                error={errors.name?.message}
              />
              <InputTextGroup
                placeholder={'Mbiemri'}
                name='surname'
                type='text'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formData.surname}
                onChange={(e) =>
                  updateFormData({
                    surname: e.target.value
                  })
                }
                error={errors.surname?.message}
              />
            </>
          ) : (
            <InputTextGroup
              placeholder={'Emri i Bisnesit'}
              name='business_name'
              type='text'
              inputGroupClass={'mb-2'}
              inputClass={'py-2'}
              value={formData.business_name}
              onChange={(e) =>
                updateFormData({
                  business_name: e.target.value
                })
              }
              error={errors.business_name?.message}
            />
          )}
          <InputTextGroup
            placeholder={'Telefon'}
            name='phone'
            type='text'
            inputGroupClass={'mb-2'}
            inputClass={'py-2'}
            value={formData.phone}
            onChange={(e) =>
              updateFormData({
                phone: e.target.value
              })
            }
            error={errors.phone?.message}
          />
          <InputTextGroup
            placeholder={'Email'}
            name='email'
            type='text'
            inputGroupClass={'mb-2'}
            inputClass={'py-2'}
            value={formData.email}
            onChange={(e) =>
              updateFormData({
                email: e.target.value
              })
            }
            error={errors.email?.message}
          />
          <ReactSelectCustom
            placeholder={'Qyteti'}
            name='city_id'
            options={cities.map((c) => ({ value: c.id, label: c.name }))}
            brCustom={'0.375rem'}
            className={'mb-2'}
            values={cities
              .map((c) => ({ value: c.id, label: c.name }))
              .find((el) => el.value == formData.city_id)}
            onChange={(val) =>
              updateFormData({
                city_id: val ? val.value : ''
              })
            }
          />
          <div className='invalid-feedback d-block'>
            {errors.city_id?.message}
          </div>
          {updateProfileLoading ? (
            <button
              type={'button'}
              className={`mx-auto py-2 btn rounded-2 w-100 mx-auto d-table btn-primary d-flex justify-content-center align-items-center`}
              disabled
            >
              <div
                className='spinner-border text-light spinner-border-sm'
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
              <div className='ms-2'>Duke ruajtur...</div>
            </button>
          ) : (
            <Button
              type={'submit'}
              buttonText={'Ruaj Ndryshimet'}
              buttonClass={'w-100 btn-primary'}
            />
          )}
        </form>
        {user.business_name !== null && (
          <>
            <h3 className='mt-5'>Ndrysho Fjalekalimin</h3>
            <form
              className='mt-4 pe-lg-5'
              onSubmit={handleSubmitPassword(submitPasswordChange)}
            >
              <InputTextGroup
                placeholder={'Fjalekalimi i Vjeter'}
                name='current_password'
                type='password'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataPassword.current_password}
                onChange={(e) =>
                  updateFormDataPassword({
                    current_password: e.target.value
                  })
                }
                error={errorsPassword.current_password?.message}
              />
              <InputTextGroup
                placeholder={'Fjalekalimi i Ri'}
                name='password'
                type='password'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataPassword.password}
                onChange={(e) =>
                  updateFormDataPassword({
                    password: e.target.value
                  })
                }
                error={errorsPassword.password?.message}
              />
              <InputTextGroup
                placeholder={'Riperserit Fjalekalimin e Ri'}
                name='password_confirmation'
                type='password'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataPassword.password_confirmation}
                onChange={(e) =>
                  updateFormDataPassword({
                    password_confirmation: e.target.value
                  })
                }
                error={errorsPassword.password_confirmation?.message}
              />
              {loadingPasswordChange ? (
                <button
                  type={'button'}
                  className={`mx-auto py-2 btn rounded-2 w-100 mx-auto d-table btn-primary d-flex justify-content-center align-items-center`}
                  disabled
                >
                  <div
                    className='spinner-border text-light spinner-border-sm'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                  <div className='ms-2'>Duke ruajtur...</div>
                </button>
              ) : (
                <Button
                  type={'submit'}
                  buttonText={'Ruaj Ndryshimet'}
                  buttonClass={'w-100 btn-primary'}
                />
              )}
            </form>
          </>
        )}
      </div>
      <div className='col-12 col-lg-7'>
        {user.business_name !== null && (
          <>
            <h3 className='mt-5 ps-lg-3'>Modifiko te Dhenat Personale</h3>
            <form
              className='mt-4 ps-lg-3'
              onSubmit={handleSubmitBusiness(submitUpdateBusinessProfile)}
            >
              <InputTextArea
                textAreaDivClass={'mb-2 mt-3'}
                textAreaClass={'rounded-3'}
                placeholder={'Rreth Kompanise/Biznesit'}
                name='bio'
                value={formDataBusiness.bio}
                onChange={(e) =>
                  updateFormDataBusiness({
                    bio: e.target.value
                  })
                }
                error={errorsBusiness.bio?.message}
              />
              <InputTextGroup
                placeholder={'Adressa'}
                name='address'
                type='text'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataBusiness.address}
                onChange={(e) =>
                  updateFormDataBusiness({
                    address: e.target.value
                  })
                }
                error={errorsBusiness.address?.message}
              />
              <InputTextGroup
                placeholder={'Linku i Website'}
                name='website_url'
                type='text'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataBusiness.website_url}
                onChange={(e) =>
                  updateFormDataBusiness({
                    website_url: e.target.value
                  })
                }
                error={errorsBusiness.website_url?.message}
              />
              <InputTextGroup
                placeholder={'Linku i Facebook'}
                name='facebook_url'
                type='text'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataBusiness.facebook_url}
                onChange={(e) =>
                  updateFormDataBusiness({
                    facebook_url: e.target.value
                  })
                }
                error={errorsBusiness.facebook_url?.message}
              />
              <InputTextGroup
                placeholder={'Linku i Instagram'}
                name='instagram_url'
                type='text'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataBusiness.instagram_url}
                onChange={(e) =>
                  updateFormDataBusiness({
                    instagram_url: e.target.value
                  })
                }
                error={errorsBusiness.instagram_url?.message}
              />
              <InputTextGroup
                placeholder={'Linku i TikTok'}
                name='tiktok_url'
                type='text'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataBusiness.tiktok_url}
                onChange={(e) =>
                  updateFormDataBusiness({
                    tiktok_url: e.target.value
                  })
                }
                error={errorsBusiness.tiktok_url?.message}
              />
              {updateBusinessProfileLoading ? (
                <button
                  type={'button'}
                  className={`mx-auto py-2 btn rounded-2 w-100 mx-auto d-table btn-primary d-flex justify-content-center align-items-center`}
                  disabled
                >
                  <div
                    className='spinner-border text-light spinner-border-sm'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                  <div className='ms-2'>Duke ruajtur...</div>
                </button>
              ) : (
                <Button
                  type={'submit'}
                  buttonText={'Ruaj Ndryshimet'}
                  buttonClass={'w-100 btn-primary'}
                />
              )}
            </form>
          </>
        )}
        {user.business_name === null && (
          <>
            <h3 className='mt-5 ps-lg-3'>Ndrysho Fjalekalimin</h3>
            <form
              onSubmit={handleSubmitPassword(submitPasswordChange)}
              className='mt-4 ps-lg-3'
            >
              <InputTextGroup
                placeholder={'Fjalekalimi i Vjeter'}
                name='current_password'
                type='password'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataPassword.current_password}
                onChange={(e) =>
                  updateFormDataPassword({
                    current_password: e.target.value
                  })
                }
                error={errorsPassword.current_password?.message}
              />
              <InputTextGroup
                placeholder={'Fjalekalimi i Ri'}
                name='password'
                type='password'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataPassword.password}
                onChange={(e) =>
                  updateFormDataPassword({
                    password: e.target.value
                  })
                }
                error={errorsPassword.password?.message}
              />
              <InputTextGroup
                placeholder={'Riperserit Fjalekalimin e Ri'}
                name='password_confirmation'
                type='password'
                inputGroupClass={'mb-2'}
                inputClass={'py-2'}
                value={formDataPassword.password_confirmation}
                onChange={(e) =>
                  updateFormDataPassword({
                    password_confirmation: e.target.value
                  })
                }
                error={errorsPassword.password_confirmation?.message}
              />
              {loadingPasswordChange ? (
                <button
                  type={'button'}
                  className={`mx-auto py-2 btn rounded-2 w-100 mx-auto d-table btn-primary d-flex justify-content-center align-items-center`}
                  disabled
                >
                  <div
                    className='spinner-border text-light spinner-border-sm'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                  <div className='ms-2'>Duke ruajtur...</div>
                </button>
              ) : (
                <Button
                  type={'submit'}
                  buttonText={'Ruaj Ndryshimet'}
                  buttonClass={'w-100 btn-primary'}
                />
              )}
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default UpdateProfileTab
