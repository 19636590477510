import { generalAlertError, generalAlertSuccessToast } from '../../helpers/alert'
import axe from '../../services/axiosConfig/axiosClient'
import caller from './contactCaller'

export const storeNewContact = async (data, reset) => {
  caller.createContactPending()


  try {
    const response = await axe.post('/store/contact', data)

    if (response.status !== 200) {
      caller.createContactRejected('Error')
      generalAlertError('Mesazhi nuk u dergua. Ju lutem provoni serisht!')
      return
    }

    caller.createContactSuccess()
    generalAlertSuccessToast("Mesazhi u dergua me sukses!")
    reset()

  } catch (error) {
    caller.createContactRejected('Error')
    generalAlertError('Dicka shkoi gabim!')
  }
}