import {
  faBath,
  faBed,
  faFileInvoice,
  faLocationDot,
  faSignHanging,
  faStairs
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { realEstateTypes } from '../../../Utilities/constants'
import exterior from '../../../assets/icons/area.svg'
import balconies from '../../../assets/icons/balconies.svg'

function RealEstateInfo({ post }) {
  return (
    <div className='card border-0 rounded-2 p-2 mb-2'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <img src={exterior} alt='' className='img-fluid' width={20} />
              <p className='ms-1 m-0 mb-0'>
                {post.real_estate.square_meters} m<sup>2</sup>
              </p>
            </div>
          </div>
          {post.real_estate.nr_bedrooms !== null && (
            <div className='col-6 col-md-4 my-2'>
              <div className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faBed} className='text-primary' />
                <p className='ms-1 m-0 mb-0'>{post.real_estate.nr_bedrooms}</p>
              </div>
            </div>
          )}
          {post.real_estate.nr_bathroom !== null && (
            <div className='col-6 col-md-4 my-2'>
              <div className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faBath} className='text-primary' />
                <p className='ms-1 m-0 mb-0'>{post.real_estate.nr_bathroom}</p>
              </div>
            </div>
          )}
          {post.real_estate.floor_nr !== null && (
            <div className='col-6 col-md-4 my-2'>
              <div className='d-flex justify-content-start align-items-center'>
                <FontAwesomeIcon icon={faStairs} className='text-primary' />
                <p className='ms-1 m-0 mb-0'>{post.real_estate.floor_nr}</p>
              </div>
            </div>
          )}
          {post.real_estate.nr_balconies !== null && (
            <div className='col-6 col-md-4 my-2'>
              <div className='d-flex justify-content-start align-items-center'>
                <img src={balconies} alt='' className='img-fluid' width={20} />
                <p className='ms-1 m-0 mb-0'>{post.real_estate.nr_balconies}</p>
              </div>
            </div>
          )}

          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faFileInvoice} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {post.real_estate.has_documentation === 1
                  ? 'Me Hipoteke'
                  : 'Ne Proces'}
              </p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faLocationDot} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>{post.city.name}</p>
            </div>
          </div>
          <div className='col-6 col-md-4 my-2'>
            <div className='d-flex justify-content-start align-items-center'>
              <FontAwesomeIcon icon={faSignHanging} className='text-primary' />
              <p className='ms-1 m-0 mb-0'>
                {
                  realEstateTypes.find(
                    (typ) => typ.id == post.real_estate.real_estate_type_id
                  ).name
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RealEstateInfo
