import { configureStore } from "@reduxjs/toolkit";
import { homeSlice } from "./home/homeSlice";
import { socialAuthSlice } from "./socialAuth/socialAuthSlice";
import { contactSlice } from "./contact/contactSlice";
import { homeApi } from "./home/homeSliceApi";
import { postApi } from "./posts/postSliceApi";
import { authApi } from "./auth/authSliceApi";
import { formDataApi } from "./formData/formDataSliceApi";

export const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    social: socialAuthSlice.reducer,
    contacts: contactSlice.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [formDataApi.reducerPath]: formDataApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(homeApi.middleware, postApi.middleware, authApi.middleware, formDataApi.middleware),
});
