import React, { useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true)

  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
      const [name, value] = cookie.split('=')
      acc[name] = value
      return acc
    }, {})

    if (cookies['userCookieConsent'] === 'true') {
      setShowBanner(false)
    } else if (cookies['userCookieConsent'] === 'false') {
      setShowBanner(true)
    }
  }, [])

  return (
    showBanner && (
      <CookieConsent
        location='bottom'
        buttonText='Prano'
        declineButtonText='Refuzo'
        enableDeclineButton
        cookieName='okazion_cookie'
        style={{ background: '#fafafa', color: '#52682f' }}
        buttonStyle={{
          background: '#52682f',
          color: '#fafafa',
          fontSize: '13px',
          borderRadius: '5px',
          padding: '8px 30px'
        }}
        declineButtonStyle={{
          background: '#f44336',
          color: '#ffffff',
          fontSize: '13px',
          borderRadius: '5px',
          padding: '8px 30px'
        }}
        expires={150} // Days before the cookie expires
        onAccept={() => {
          document.cookie =
            'okazion_cookie=true; path=/; max-age=' + 150 * 24 * 60 * 60
          console.log('Cookies accepted')
          setShowBanner(false)
        }}
        onDecline={() => {
          document.cookie =
            'okazion_cookie=false; path=/; max-age=' + 150 * 24 * 60 * 60
          console.log('Cookies declined')
          window.location.href = 'https://www.google.com'
        }}
      >
        Kjo faqe interneti përdor cookie për të përmirësuar përvojën e
        përdoruesit. Duke klikuar "Pranoni", ju pranoni përdorimin tonë të
        cookies.
      </CookieConsent>
    )
  )
}

export default CookieBanner
