import React from 'react'
import Layout from './Layout'
import { Outlet } from 'react-router'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function Base() {
  return (
    <Layout>
      <Navbar />
      <Outlet />
      <Footer />
    </Layout>
  )
}

export default Base
