import React from 'react'

function CustomSelect({ options, className, placeholder, value, onChange, name }) {

  return (
    <select className={className} onChange={(e) => onChange(e)} value={value} name={name}>
      <option value="" disabled>{placeholder}</option>
      {options.map(({ id, name }) => (
        <option key={name} value={id}>{name}</option>
      ))}
    </select>
  );
}

export default CustomSelect