import * as yup from 'yup'


const numberRegex = /^\d+$/
const phoneRegExp = /^\+?\d+$/

export const updateProfileSchema = yup.object({
    name: yup
        .string()
        .when('business_name', ([business_name], schema) =>
            business_name === null
                ? schema.required('Ju lutem vendosni emrin!')
                : schema
        ),
    surname: yup
        .string()
        .when('business_name', ([business_name], schema) =>
            business_name === null
                ? schema.required('Ju lutem vendosni mbiemrin!')
                : schema
        ),
    email: yup
        .string()
        .required('Ju lutem vendosni emailin!')
        .email('Ju lutem vendosni emailin!'),
    profile_image: yup
        .object({
            filename: yup.string().nullable(),
            content: yup.string().nullable()
        })
        .nullable(),
    city_id: yup
        .string()
        .required('Ju lutem zgjidhni qytetin!')
        .matches(numberRegex, 'Ju lutem zgjidhni qytetin!'),
    phone: yup
        .string()
        .matches(phoneRegExp, 'Formati i numrit nuk eshte i sakte!')
        .required('Vendosni numrin e telefonit!'),
    business_name: yup.string().nullable()
})

const urlRegExp = /^(ftp|http|https):\/\/[^ "]+$/

export const updateProfileBusinessSchema = yup.object({
    address: yup.string().required('Ju lutem vendosni adresen!'),
    bio: yup.string().required('Ju lutem vendosni nje pershkrim!'),
    website_url: yup
        .string()
        .required('Ju lutem vendosni url te velfshme!')
        .matches(urlRegExp, 'Ju lutem vendosni url te velfshme!'),
    facebook_url: yup
        .string()
        .required('Ju lutem vendosni url te velfshme!')
        .matches(urlRegExp, 'Ju lutem vendosni url te velfshme!'),
    instagram_url: yup
        .string()
        .required('Ju lutem vendosni url te velfshme!')
        .matches(urlRegExp, 'Ju lutem vendosni url te velfshme!'),
    tiktok_url: yup
        .string()
        .required('Ju lutem vendosni url te velfshme!')
        .matches(urlRegExp, 'Ju lutem vendosni url te velfshme!')
})

export const changePasswordSchema = yup.object({
    current_password: yup
        .string()
        .required('Ju lutem vendosni fjalekalimin tuaj aktual!'),
    password: yup.string().required('Ju lutem vendosni fjalekalimin tuaj te ri!'),
    password_confirmation: yup
        .string()
        .required('Ju lutem perserisni fjalekalimin tuaj te ri!')
        .oneOf([yup.ref('password')], 'Fjalekalimi nuk eshte i njejte')
})