import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithAuth from "../baseQuery/baseQueryWithAuth";

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQueryWithAuth,
    endpoints: (builder) => ({
        getAuthInfo: builder.query({
            query: ({ page = null }) => {
                if (page !== null) {
                    return {
                        url: `/get-profile-info?page=${page}`,
                        authRequired: true
                    }
                }

                return {
                    url: '/get-profile-info',
                    authRequired: true
                }
            }
        }),
        getEditProfileFormData: builder.query({
            query: () => ({
                url: '/get-profile-form-data',
                authRequired: true
            })
        }),
        attemptLogin: builder.mutation({
            query: ({ data }) => ({
                url: '/login',
                method: 'POST',
                body: data,
                authRequired: false
            })
        }),
        registerUser: builder.mutation({
            query: ({ data }) => ({
                url: '/register',
                method: 'POST',
                body: data,
                authRequired: false
            })
        }),
        forgotPassword: builder.mutation({
            query: ({ data }) => ({
                url: '/forgot-password',
                method: 'POST',
                body: data,
                authRequired: false
            })
        }),
        resetPassword: builder.mutation({
            query: ({ data }) => ({
                url: '/reset-password',
                method: 'POST',
                body: data,
                authRequired: false
            })
        }),
        updateProfile: builder.mutation({
            query: ({ userId, data }) => ({
                url: `/profile/${userId}/update`,
                method: "PUT",
                body: data,
                authRequired: true
            })
        }),
        updatePassword: builder.mutation({
            query: ({ userId, data }) => ({
                url: `/profile/password/${userId}/update`,
                method: "PUT",
                body: data,
                authRequired: true
            })
        }),
        updateBusinessProfile: builder.mutation({
            query: ({ userId, data }) => ({
                url: `/profile/business/${userId}/update`,
                method: "PUT",
                body: data,
                authRequired: true
            })
        }),
        getBusinessInfo: builder.query({
            query: ({ username, page = null }) => {
                if (page !== null) {
                    return {
                        url: `/get-business-info/${username}?page=${page}`,
                        authRequired: false
                    }
                }

                return {
                    url: `/get-business-info/${username}`,
                    authRequired: false
                }
            }
        }),
        verifyEmailCall: builder.mutation({
            query: ({ data }) => ({
                url: '/verify-email',
                method: "POST",
                body: data,
                authRequired: false
            })
        })
    })
})

export const { useLazyGetAuthInfoQuery, useLazyGetEditProfileFormDataQuery, useAttemptLoginMutation, useRegisterUserMutation, useForgotPasswordMutation, useResetPasswordMutation, useUpdateProfileMutation, useUpdatePasswordMutation, useUpdateBusinessProfileMutation, useLazyGetBusinessInfoQuery, useVerifyEmailCallMutation } = authApi