import React from 'react'
import errorBg from '../assets/errorbg.svg'
import { Link } from 'react-router-dom'

function ErrorFallback() {
  return (
    <div className='container-fluid'>
      <div className='my-5'>
        <h2 className='text-center h1 fw-bold'>Oops!</h2>
        <p className='text-center h5'>Keni humbur?</p>
        <img
          src={errorBg}
          alt='Error'
          className='img-fluid d-table mx-auto my-5'
          width={500}
        />
        <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
          <Link className='btn btn-outline-primary btn-lg' to={'/'}>
            Home
          </Link>
          <button
            className='btn btn-primary btn-lg'
            onClick={() => window.location.reload()}
          >
            Rifresko
          </button>
        </div>
      </div>
    </div>
  )
}

export default ErrorFallback
